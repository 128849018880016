
//react
import { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ILane from "../Api/ILane";

const animatedComponents = makeAnimated()

interface LaneTypeSelectorProps{
    allLanes: ILane[]
    selectedLaneTypes: string[]
    setFunc: any
}

export function LaneTypeSelector(props: LaneTypeSelectorProps) {

    const laneTypes: Set<string> = new Set()

    props.allLanes.forEach((lane) => {
        laneTypes.add(lane.lane_type)
    })
    const typeArr: string[] =  Array.from(laneTypes)
    const options = typeArr.map((type) => { return {value: type, label: type, selected: props.selectedLaneTypes.includes(type)}})

    const defaultVals = options.filter((option) => option.selected)

    function onTypeSelect(e: any){
        let selectedTypes: Set<string> = e.map((fuck: any) => fuck.value)
        selectedTypes = new Set(selectedTypes)
        props.setFunc(selectedTypes)
    }

    return <Select
        placeholder={"Lane Types to Exclude"}
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={defaultVals}
        isMulti
        options={options}
        onChange={onTypeSelect}
    />

}
