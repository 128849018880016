import { Autocomplete, GoogleMap, GoogleMapProps, LoadScript, useGoogleMap } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { VadeMapOptions } from "./VadeMapOptions";

//probably should just let pages set h and w
const containerStyle = { width: '600px', height: '600px' };

export default function VadeMap( props: GoogleMapProps ) {
    let [center, setCenter] = useState<google.maps.LatLng | null>( null );
    let autoCompl: google.maps.places.Autocomplete | null = null;

    function onLoad( autoComplete: google.maps.places.Autocomplete ) {
        autoCompl = autoComplete;
    }

    function PanComp() {
        let map: google.maps.Map = useGoogleMap()!;
        useEffect( () => { //only when center changes!
            if( !map    ) { return; }
            if( !center ) { return; }
            //console.log( "autocomplete - panning!" );
            map.panTo( { lat: center.lat(), lng: center.lng() } );
        }, [center] );
        return <></>;
    }

    function MyAutoComplete() {
        function onPlaceChanged() {
            let loc = autoCompl?.getPlace();
            setCenter( loc?.geometry?.location! );
        }

        return <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}>
            <input type="text"
                placeholder="Find Your Curb"
                style={{
                boxSizing   : `border-box`,
                border      : `1px solid transparent`,
                width       : `240px`,
                height      : `32px`,
                padding     : `0 12px`,
                borderRadius: `3px`,
                boxShadow   : `0 2px 6px rgba( 0, 0, 0, 0.3 )`,
                fontSize    : `14px`,
                outline     : `none`,
                textOverflow: `ellipses`,
                position    : `absolute`,
                top         : `14px`,
                left        : '190px'
                }} />
        </Autocomplete>;
    }

    let gMap = 
        <GoogleMap id="googleMap" options={VadeMapOptions}
                                  mapContainerStyle={containerStyle}
                                  zoom={11}
                                  {...props}>
            <MyAutoComplete />
            <PanComp />
            {props.children}
        </GoogleMap>
    return gMap;
}