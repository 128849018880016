//react
import React    from 'react';
import ReactDOM from 'react-dom/client';

//vade
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

//let's begin shall we?
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

//i hate not running in strict mode until I better understand what I'm doing!
root.render(
    <App />
);
