import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Api from "./Api/Api";
import { Cam } from "./Api/Cam";
import { ICam } from "./Api/ICam";
import ILane from "./Api/ILane";
import { findFirstTrace, getDist, getTraceToSessionMap, SessionRec, getStayRecs } from "./CvDebuggerUtil";
import { ITraceWFrameNum, loadTracesFromJson } from "./CamLaneSessionsPageUtil";
import { CvDebuggerPageDebugger } from "./CvDebuggerPageDebugger";
import { IObj } from "./IVisionOutput";
import { IStays } from "./Api/IStay";
import {Button, Form} from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function CvStayDebuggerPage() {    
    const tz          = "America/New_York";
    const querystring = new URLSearchParams( window.location.search );
    let   initDate    = new Date();
    const qsDate      = querystring.get( "date" );
    if( qsDate ) {
        initDate = DateTime.fromISO( qsDate ).toJSDate();
    }
    const [curDate,   setCurDate]   = useState<Date>( initDate );
    const [cam,       setCam]       = useState<ICam>( new Cam() );
    const [lane,      setLane]      = useState<ILane>();
    const [traces,    setTraces]    = useState<Array<ITraceWFrameNum>>( [] );
    const [ind,       setInd]       = useState<number>( 0 );
    const [ground,    setGround]    = useState<Map<string, IObj[]>>();
    const [vision,    setVision]    = useState<Map<string, IObj[]>>();
    const [grndRecs,  setGrndRecs]  = useState<SessionRec[]>();
    const [allStays, setAllStays]   = useState<any[]>([]);
    const [visRecs,   setVisRecs]   = useState<SessionRec[]>();
    const [ignoreVis, setIgnoreVis] = useState<string[]>( [] );
    const [ignoreGrn, setIgnoreGrn] = useState<string[]>( [] );
    const [rawGrnd,   setRawGrnd]   = useState<any[]>([])
    const [rawVis,    setRawVis]    = useState<any[]>([])
    const [includeOneFrameStay, setIncludeOneFrameStay] = useState(false)
    const [includeBikes, setIncludeBikes] = useState(false)
    const navigate = useNavigate();
    const threshold = 450;
    const params    = useParams();
    const laneId    = params.laneId;
    const domain    = params.domain;
    const camId     = params.camId!
    function processLanes( json: any ) {
        const lanes: Array<ILane> = json.lanes;
        const lane = lanes.find( l => l.uuid === laneId );
        if( lane ) {
            setLane( lane );
        } else {
            let firstLane = lanes[0]; //I don't think we should keep this
            navigate( `/cam/${camId}/accuracy/${firstLane.uuid}/${domain}?date=${qsDate}` );
            // setLane(lanes[0])
        }
    }

    useEffect( () => {
        console.log( "getting cam" );
        Api.getCam( params.camId! ).then( resp => resp.json() )
                                   .then( json => {
                                       let c: ICam = json.camera;
                                       setCam( json.camera );
                                    } );

        console.log( "getting lanes" );
        Api.getLanes( params.camId! ).then( resp => resp.json() )
                                     .then( processLanes );
        //load the full week of traces
        const startOfDay = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).startOf("day").toUTC();
        const endOfDay   = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).endOf("day").toUTC();
        Api.getTracesByTime( params.camId!, startOfDay, endOfDay ).then( resp => resp.json() )
                                                                  .then( resp => {
                                                                      console.log( "processing traces" );
                                                                      let newTraces = loadTracesFromJson( tz, resp );
                                                                      let ind = findFirstTrace( curDate, tz, newTraces );
                                                                      setTraces( newTraces );
                                                                      setInd( ind );
                                                                   } );
    }, [params.camId, curDate] );

    useEffect( () => {
        console.log( "load reids and gt" );
        if( !lane ) {
            return;
        }
        const startOfDay = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).startOf("day").toUTC();
        const endOfDay   = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).endOf("day").toUTC();
        Api.getSessionsInDateRange( params.camId!, startOfDay, endOfDay )
            .then( resp => resp.json() )
            .then( resp => { 
                if( traces.length === 0 ) {
                    return;
                }
                //load ground truth stuff
                const allRecs: IStays = resp;
                let allStaysInLane = allRecs.sessions.filter( el => el.lane_uuid === lane.uuid );
                setAllStays(allStaysInLane)
                let gtFiltered = allRecs.sessions.filter( el => el.domain === "ground" && el.lane_uuid === lane.uuid );
                let grndRecs = getStayRecs( gtFiltered, traces );
                if (!includeOneFrameStay){
                    grndRecs = grndRecs.filter( rec => rec.frames.length > 1 );
                }
                setRawGrnd( gtFiltered );
                const grndMap = getTraceToSessionMap( grndRecs, "red" );
                setGround( grndMap );
                setGrndRecs( grndRecs );
            } );
    }, [traces, lane, ignoreVis, ignoreGrn, domain, includeOneFrameStay] );

    useEffect( () => {
        // this will rerun the filter for CV stays
        //vision stuff
        if( !lane ) {
            return;
        }
        let visFiltered = allStays.filter( el => el.domain === domain && el.lane_uuid === lane.uuid);
        visFiltered = visFiltered.filter((el) => el.class_label.toLowerCase() != 'bicycle')
        visFiltered = visFiltered.filter((el) => el.class_label.toLowerCase() != 'motorbike')

        //removed this for now...maybe bring it back later
        //visFiltered = visFiltered.filter( el => pointInPolygonNested( centroid( el.bbox ), lane.image_trigger_boundary ) );
        let visRecs = getStayRecs( visFiltered, traces );
        if( !includeOneFrameStay ) {
            visRecs = visRecs.filter( ( rec ) => rec.frames.length > 1 );
        }
        let goodVIds = visRecs.map( ( rec ) => rec.vehicleId );
        visFiltered = goodVIds.map( ( id ) => visFiltered.find( ( el ) => el.vehicle_id.toString() === id )! );
        setRawVis( visFiltered );

        const visMap = getTraceToSessionMap( visRecs, "red" );
        setVision( visMap );
        setVisRecs( visRecs );
    }, [lane, allStays, domain, includeOneFrameStay, traces])

    useEffect( () => {
        if( traces.length === 0 ) {
            return;
        }
        const titleElement = document.getElementById( traces?.[ind]?.traceId );
        if( !titleElement ) {
            return;
        }
        titleElement.scrollIntoView( { behavior: 'smooth' } );
    } );

    if( !ground || !vision || !lane ) {
        return <></>;
    }
    if( traces.length === 0 ) { return <></>; }
    if( !grndRecs           ) { return <></>; }
    if( !visRecs            ) { return <></>; }

    function findBestMatch( s: SessionRec, grnd: SessionRec[] ) {
        let bestMatch: { session: null | SessionRec, dist: number } = { session: null, dist: Infinity };
        for( const g of grnd ) {
            const dist = getDist( s.frames[0].obj.centroid, g.frames[0].obj.centroid );
            if( bestMatch.dist > dist ) {
                bestMatch = { session: g, dist: dist };
            }
            let score = 0;
            const gSet = new Set( g.frames.map( el => el.traceId ) );
            score = s.frames.filter( f => gSet.has( f.traceId ) ).length;
        }
        return bestMatch;
    }

    function removeGrnVehicle( v: string ) {
        setIgnoreGrn( [ ...ignoreGrn, v ] );
    }
    function removeVisVehicle( v: string ) {
        setIgnoreVis( [ ...ignoreVis, v ] );
    }

    function getHeliosUrl(host: string){
        const yyyy = initDate.getFullYear();
        let mm = initDate.getMonth() + 1; // Months start at 0!
        let dd = initDate.getDate();
        const dayStr = `${mm}-${dd}-${yyyy}`
        return `${host}/task/starts?day=${dayStr}&camera_id=${params.camId!}&taskType=start`
    }

    function domainDropDown() {
        let domains = Array.from( new Set( allStays.map( ( stay ) => stay.domain ) ) )
        return <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Domain: {params.domain}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {domains.map( ( d ) => <Dropdown.Item href={"#&domain=" + d}>{d}</Dropdown.Item> )}
            </Dropdown.Menu>
        </Dropdown>
    }

    return <div style={{ display: "grid", gridTemplateColumns: "5fr 95fr" }}>
        <div style={{ fontSize: "9pt", display: "flex", flexDirection: "column", gap: "10px", gridTemplateRows: "auto auto auto" }}>
            <div>
                <Button size="sm" href={getHeliosUrl("http://localhost:3000")} target="_blank">
                    Tasks
                </Button>
            </div>
            <div>
                <Form.Switch
                    label="SFS"
                    size={10}
                    height={15} width={30}
                    checked={includeOneFrameStay}
                    onChange={x => setIncludeOneFrameStay( !includeOneFrameStay )}
                />
            </div>
            <div>
                <Form.Switch
                    label="BAM"
                    checked={includeBikes}
                    onChange={ x => setIncludeBikes( !includeBikes ) }
                />
            </div>
        </div>
        <CvDebuggerPageDebugger
            ground={ground}
            vision={vision}
            grndRecs={grndRecs}
            visRecs={visRecs}
            lane={lane}
            traces={traces}
            ind={ind}
            setInd={setInd}
            threshold={threshold}
            tz={tz}
            removeGrnVehicle={ v => removeGrnVehicle( v )}
            removeVisVehicle={ v => removeVisVehicle( v )}
            rawGrnd={rawGrnd}
            rawVis={rawVis}
            editGrndUrl={`/cam/${lane.camera_uuid}/sessions/${lane.uuid}/ground?date=${DateTime.fromJSDate(curDate).toFormat("yyyy-MM-dd")}`}
        />
    </div>

}