import { useEffect, useState } from "react";
import { Link }   from "react-router-dom";
import Api        from "./Api/Api";
import Deployment from "./Api/Deployment";

export default function DeploymentListPage() {
    const [depls, setDepls] = useState<Deployment[]>( [] );

    useEffect( () => {
        Api.getDeployments().then( resp => resp.json() )
                            .then( json => setDepls( json.deployments ) );
    }, [] );

    let depRows = depls.map( ( dep, i ) => <tr key={dep.uuid}>
        <td className="p-3 h5">{dep.name}</td>
        <td className="p-3 text-muted">{dep.cameras.length}</td>
        <td className="p-3 text-muted">{new Date( dep.time_created ).toLocaleDateString()}</td>
        <td>
            <Link to={`/dep/${dep.uuid}`}>
                <button className="btn btn-primary">View</button>
            </Link>
        </td>
        <td>
            <Link to={`/dep/${dep.uuid}/edit`}>
                <button className="btn btn-primary">Edit</button>
            </Link>
        </td>
        <td>
            <Link to={`/dep/${dep.uuid}/cam/`}>
                <button className="btn btn-primary">Cams</button>
            </Link>
        </td>
        <td>
            <Link to={`/dep/${dep.uuid}/zones/`}>
                <button className="btn btn-primary">Zones</button>
            </Link>
        </td>
    </tr> );
    
    if( depRows.length == 0 ) {
        return <></>;
    }

    return <div className="row mx-3">
        <table className="table table-striped w-auto">
            <thead>
                <tr>
                    <td className="text-center">Deployment Name</td>
                    <td className="text-center">Camera Count</td>
                    <td className="text-center">Date Created</td>
                    <td colSpan={3}>
                        <button className="btn btn-primary w-100" onClick={() => alert("not implemented; please complain on jira") }>Create</button>
                    </td>
                </tr>
            </thead>
            <tbody>
                {depRows}
            </tbody>
        </table>
    </div>;
}