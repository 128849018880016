
import React, { createRef, useEffect, useState } from "react";
import ILane from "../Api/ILane";
import { IPnt } from "../Api/IPnt";
import { INewStay, IStay } from "../Api/IStay";
import { ITraceWFrameNum } from "../CamLaneSessionsPageUtil";
import { centroid } from "../CvDebuggerUtil";
import {Question} from "./taskCheckerInterfaces";
import {bo, en} from "@fullcalendar/core/internal-common";

interface TraceGalleryProps {
    ind:          number;
    traces:       Array<ITraceWFrameNum>;
    tasks?:        Question[];
    lane?:         ILane;
    sessions?:     Array<IStay>;
    fontSize?:    number;
    style?:       React.CSSProperties;
    session?:     IStay | INewStay;
    onSessionClick?: ( session: IStay ) => boolean;
    onIndChange?:    ( ind: number    ) => void;
    onMouseDown?:    ( pnt: IPnt      ) => void;
    onMouseMove?:    ( pnt: IPnt, ctx: CanvasRenderingContext2D ) => void;
}

export default function TaskCheckerImageViewer( props: TraceGalleryProps ) {
    const [canvasRef]   = useState<React.RefObject<HTMLCanvasElement>>( createRef<HTMLCanvasElement>() );
    const [img, setImg] = useState<HTMLImageElement>();
    const ind           = props.ind;
    const traces        = props.traces;
    const tasks          = props.tasks
    const lane          = props.lane;
    const session       = props.session;
    const sessions      = props.sessions;
    const fontIncr      = !props.fontSize ? 0 : props.fontSize;

    const currTraceId = traces[ind].traceId


    useEffect( () => {
        processImage();
    }, [traces, ind] );

    async function processImage() {
        let bgImage = new Image();
        if( traces.length == 0 ) {
            return;
        }
        if( !traces[ind] ) {
            return;
        }
        bgImage.src = traces[ind].imgUrl;
        await new Promise<void>( ( resolve ) => {
            bgImage.onload  = ( _e ) => { return resolve(); };
            bgImage.onerror = ( _e ) => { return resolve(); };
        } );
        setImg( bgImage );
    }

    //get all sessions that are in this trace
    function getSessionsInTrace( trace: ITraceWFrameNum, sessions: Array<IStay> ): Array<IStay> {
        const resSessions = new Array<IStay>();
        const traceTime = trace.timeCaptured;
        for( const s of sessions ) {
            if( s.start_time.valueOf() <= traceTime.valueOf()
                && s.end_time.valueOf()   >= traceTime.valueOf() ) {
                resSessions.push( s );
            }
        }
        return resSessions;
    }

    function drawBox( ps:   number[][],
                      ctx:  CanvasRenderingContext2D ) {
        if( ps.length === 0 ) {
            return;
        }
        ctx.beginPath();
        ctx.moveTo( ps[0][0], ps[0][1] );
        for ( let i = 1; i < ps.length; i++ ) {
            ctx.lineTo( ps[i][0], ps[i][1] );
        }
        ctx.lineTo( ps[0][0], ps[0][1] );
        ctx.stroke();
    }

    function displayId( id: number ) {
        const s = id.toString();
        return s.toString().slice( s.length - 3, s.length );
    }

    function setTextStyle( ctx: CanvasRenderingContext2D,
                           fontName:    string = "Monaco",
                           fontSize:    number = 10,
                           fillColor:   string = "black",
                           strokeColor: string = "black",
                           strokeWidth: number = 1,
                           alpha:       number = 1.0 ) {
        ctx.font        = `${fontSize}pt ${fontName}`;
        ctx.fillStyle   = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth   = strokeWidth;
        ctx.globalAlpha = alpha;
    }

    function setDrawStyle( ctx: CanvasRenderingContext2D,
                           fillColor: string = "black",
                           strokeWidth: number = 1,
                           strokeColor: string = "black",
                           alpha: number = 1.0,
                           lineDash: number[] = []) {
        ctx.fillStyle   = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth   = strokeWidth;
        ctx.globalAlpha = alpha;
        ctx.setLineDash(lineDash)
    }

    function drawCentroids( histEntries: Array<IStay>, ctx: CanvasRenderingContext2D ) {
        histEntries.forEach( histEntry => {
            const cen = centroid( histEntry.bbox );
            if( !cen || cen.length === 0 ) {
                return;
            }
            const [x, y] = [cen[0], cen[1]];

            //draw the centroid
            setDrawStyle( ctx, "black", 5, "white" );
            ctx.beginPath();
            ctx.arc( x, y, 2, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();

            //now label the centroid
            setTextStyle( ctx, "Monaco", 8 + fontIncr, "white", "black", 4 );
            let vehicleType = histEntry?.class_label;
            if( !vehicleType ) {
                vehicleType = "";
            }
            const text = displayId( histEntry.vehicle_id ) + " " + vehicleType;
            ctx.strokeText( text, x + 8, y + 8 );
            ctx.fillText  ( text, x + 8, y + 8 );
        } );
    }

    function drawGroundPlanePolygons( histEntries: Array<IStay>, ctx: CanvasRenderingContext2D ) {
        histEntries.forEach( histEntry => {
            setDrawStyle( ctx, "white", 2, "white", 0.80 );
            if ( session?.vehicle_id == histEntry.vehicle_id ) {
                setDrawStyle( ctx, "yellow", 2, "yellow", 0.80 );
            }
            drawBox( histEntry.ground_plane, ctx );
        } );
    }

    function drawBoundingBoxes( histEntries: Array<IStay>, ctx: CanvasRenderingContext2D ) {
        histEntries.forEach( histEntry => {
            setDrawStyle( ctx, "white", 2, "white", 0.80 );
            if ( session?.vehicle_id == histEntry.vehicle_id ) {
                setDrawStyle( ctx, "yellow", 2, "yellow", 0.80 );
            }
            drawBox( histEntry.bbox, ctx );
        } );
    }

    function drawPoly(points: number[][], ctx: CanvasRenderingContext2D, strokeColor: string = "yellow", dashStyle: number[] = []){
        setDrawStyle( ctx, "white", 4, strokeColor, 0.50, dashStyle);
        ctx.beginPath();
        let ptsCleaned = points
        if (points.length === 2){
            let tl = points[0]
            let br = points[1]
            ptsCleaned = [ tl,  [tl[0], br[1]],  br, [br[0], tl[1]], tl ]
        }else{
            ptsCleaned = [...points, points[0]]
        }
        ctx.moveTo( ptsCleaned[0][0], ptsCleaned[0][1] );

        for( let i = 1; i < ptsCleaned.length; i++ ) {
            ctx.lineTo( ptsCleaned[i][0], ptsCleaned[i][1] );
        }
        ctx.stroke();
    }

    function drawLanePoly( lane: ILane, ctx: CanvasRenderingContext2D ) {
        setDrawStyle( ctx, "yellow", 4, "aqua", 0.50 );
        ctx.beginPath();
        let pts = lane.image_trigger_boundary;
        ctx.moveTo( pts[0][0], pts[0][1] );
        for( let i = 1; i < pts.length; i++ ) {
            ctx.lineTo( pts[i][0], pts[i][1] );
        }
        ctx.stroke();
    }

    function drawText(text: string, loc: number[], ctx: CanvasRenderingContext2D){
        setTextStyle( ctx, "Monaco", 16, "white", "black", 4 );
        ctx.strokeText( text, loc[0], loc[1] );
        ctx.fillText  ( text, loc[0], loc[1]);
    }

    function drawExtras( item: ITraceWFrameNum, ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement ) {
        return;
        setTextStyle( ctx, "Monaco", 16, "white", "black", 4 );
        let desc = "* "; //just an indicator that there is session data for the current image
        ctx.strokeText( desc, canvas.width - ctx.measureText(desc).width, canvas.height - 10 );
        ctx.fillText  ( desc, canvas.width - ctx.measureText(desc).width, canvas.height - 10 );
    }

    function drawTimecode( item: ITraceWFrameNum, ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement ) {
        let desc = item.markup.toString();
        setTextStyle( ctx, "Monaco", 20, "white", "black", 4 );
        ctx.strokeText( desc, 10, canvas.height - 10 );
        ctx.fillText  ( desc, 10, canvas.height - 10 );
    }

    const draw = ( background: HTMLImageElement | undefined ) => {
        if ( !background ) {
            return;
        }
        const canvas = canvasRef.current;
        if ( !canvas ) {
            return;
        }
        canvas.height = background.naturalHeight;
        canvas.width  = background.naturalWidth;
        const ctx = canvas.getContext( "2d", { alpha: false } );
        if ( !ctx ) {
            return;
        }

        //draw the image first
        ctx.drawImage( background, 0, 0 );

        //now let's draw a poly!
        if (lane){
            drawLanePoly ( lane, ctx );
            //drawLaneLine ( lane, ctx );
        }
        if (tasks){
            let startsOnTrace = tasks.filter((task) => task.start_trace === currTraceId && task.question_type === "start")
            let endsOnTrace = tasks.filter((task) => task.end_trace === currTraceId && task.question_type === "end")
            let allTasksOnTrace = [...startsOnTrace, ...endsOnTrace]
            allTasksOnTrace.map((task) => {
                const intersectingTasks = allTasksOnTrace.filter((otherTask) => doesTaskIntersectOtherTasks(task, allTasksOnTrace))
                let dash: number[] = []  // null dash
                if (intersectingTasks.length > 0) dash = [20, 5]
                if (task.question_type === "start"){
                    drawText(task.question_id.toString(), task.bbox[0], ctx)
                    if (task.final_answer === true){
                        drawPoly(task.bbox, ctx, "green", dash)
                    }else if (task.final_answer === false){
                        drawPoly(task.bbox, ctx, "red", dash)
                    }else{
                        drawPoly(task.bbox, ctx)
                    }
                }
                if (task.question_type === "end"){
                    drawText(task.question_id.toString(), task.ground_plane[0], ctx)
                    if (task.final_answer === true){
                        drawPoly(task.ground_plane, ctx, "green", dash)
                    }else if (task.final_answer === false){
                        drawPoly(task.ground_plane, ctx, "red", dash)
                    }else{
                        drawPoly(task.ground_plane, ctx, "yellow")
                    }
                }
            })
        }

        //draw boxes and centroids
        if (sessions){
            let histories = getSessionsInTrace( traces[ind], sessions );
            drawGroundPlanePolygons( histories, ctx );
            drawCentroids( histories, ctx );
        }
        //draw these last so they are on top of everything
        drawExtras   ( traces[ind], ctx, canvas );
        drawTimecode ( traces[ind], ctx, canvas );
        return;
    };

    function doesTaskIntersectOtherTasks(primaryTask: Question, otherTasks: Question[]){
        let doesIntersect = false
        const primaryCentX = primaryTask.bbox[1][0] - primaryTask.bbox[0][0]
        const primaryCentY = primaryTask.bbox[0][1] - primaryTask.bbox[1][1]
        // const primaryCentroid = [primaryCentX, primaryCentY]
        otherTasks.forEach((otherTask) => {
            if (primaryTask.question_id === otherTask.question_id) return
            const otherCentX = otherTask.bbox[1][0] - otherTask.bbox[0][0]
            const otherCentY = otherTask.bbox[0][1] - otherTask.bbox[1][1]
            // const otherCentroid = [otherCentX, otherCentY]
            const xDist = Math.abs(primaryCentX - otherCentX)
            const yDist = Math.abs(primaryCentY - otherCentY)
            const similarX = xDist < 20
            const similarY = yDist < 20
            if (similarX && similarY){
                doesIntersect = true
            }
        })
        return doesIntersect
    }

    function getPnt( x: number, y: number ): IPnt {
        const canvas = canvasRef.current!;
        const rect = canvas.getBoundingClientRect();
        const pnt  = {
            x: Math.round( ( x - rect.x ) * ( canvas.width / canvas.clientWidth ) ),
            y: Math.round( ( y - rect.y ) * ( canvas.width / canvas.clientWidth ) ),
        };
        return pnt;
    }

    function onMouseMove( e: React.MouseEvent ) {
        draw( img );
        const canvas = canvasRef.current;
        const ctx    = canvas!.getContext( "2d", { alpha: false } )!; //why would this fail?
        const pnt = getPnt( e.clientX, e.clientY );
        props.onMouseMove?.( pnt, ctx );
    }

    function mouseDownHandler( e: React.MouseEvent ) {
        const pnt = getPnt( e.clientX, e.clientY );
        props.onMouseDown?.( pnt ); //where have you been all my life "?.""
    }

    return <>
        <canvas ref={canvasRef}
                onMouseMove={onMouseMove}
                onMouseDown={mouseDownHandler}
                style={props.style}
        />
        { draw( img ) }
    </>;
}


