//react
import { BanIcon, BookmarkIcon, FireIcon, LocationMarkerIcon, MapIcon } from "@heroicons/react/solid";
import { InfoWindow, Marker, Polyline, useGoogleMap } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Link, matchPath, useLocation, useMatch, useParams } from "react-router-dom";

//vade
import Api      from "./Api/Api";
import { IDeployment, ICurbZone } from "./Api/Deployment";
import { IGisLine } from "./Api/IGisLine";
import ILane from "./Api/ILane";
import MapFmt from "./Util/MapFmt";
import VadeMap from "./VadeMap";

export default function CurbZoneListPage() {
    const params = useParams();
    const [depl,         setDepl]         = useState<IDeployment>();
    const [curbZones,    setCurbZones]    = useState<ICurbZone[]>( [] );
    const [lanes,        setLanes]        = useState<ILane[]>( [] );
    const [panTo,        setPanTo]        = useState<google.maps.LatLngLiteral | null>(null);
    const [editZone,     setEditZone]     = useState<ICurbZone>();
    const [showLaneInfo, setShowLaneInfo] = useState<ILane>();

    useEffect( () => {
        Api.getDeployment( params.depId! ).then( resp => resp.json() )
                                          .then( json => { 
                                              setDepl( json.deployment );
                                              setPanTo( MapFmt.jsonToPnt( json.deployment.info ) );
                                              setCurbZones( json.deployment.curb_zones );
                                              setLanes( json.deployment.lanes );
                                          } );
    }, [params.depId] );
    
    if( !depl ) {
        return <h3>Loading!</h3>;
    }

    //probably should just let pages set h and w
    const containerStyle = { width: '75vw', height: '92vh' };

    function labelMaker( s: string ): google.maps.MarkerLabel {
        return {
            color: "white",
            text:  s,
        };
    };

    let curbZoneLines: any = <></>;
    if( curbZones ) {
        curbZoneLines = curbZones.map( ( cz, i ) => <Polyline
                key={`curbZone-${i}`}
                path={ getGisLinePath( cz.gis_line ) }
                options={{ strokeWeight: 3, strokeColor: "aqua", clickable: true }}
                onClick={ () => setEditZone( cz ) } /> );
    }
    
    //update the curb zone in state that is passed in
    function updateCurbZone( cz: ICurbZone, i: number, latLng: google.maps.LatLng ) {
        let gisLine = cz.gis_line;
        gisLine.coordinates[i] = [latLng.lng(), latLng.lat()];
        return { ...cz, gis_line: gisLine };
    }

    //update the position of a curb zone in the web services/db
    function saveCurbZone( cz: ICurbZone ) {
        let values = { gis_line: cz.gis_line };
        Api.updateCurbZone( cz.curb_zone_id, values ).then( resp => resp.json() ).then( json => {
            console.log( json );
        } );
    }

    //updates the curb zone field of a lane
    function saveLaneCurbZone( l: ILane, cz: ICurbZone ) {
        const laneId = l.uuid;
        let body: any = {};
        body.curb_zone_id = cz.curb_zone_id;

        console.log( "laneId: " + laneId );
        Api.updateLane( laneId, body ).then( resp => resp.json() )
            .then( json => {
                const recvLane: ILane = json.lane;
                const worked = recvLane.curb_zone_id === cz.curb_zone_id;
                if( worked ) {
                    alert( "Saved!" );
                }
                else {
                    alert( "Unexpected Error!" );
                    return;
                }
                const newLanes = lanes.map( innerLane => {
                    if( innerLane.uuid === recvLane.uuid ) {
                        return { ...innerLane, ...recvLane };
                    }
                    return innerLane;
                } );
                setLanes( newLanes );
                setShowLaneInfo( newLanes.find( innerLane => innerLane.uuid === recvLane.uuid ) );
            } );
    }
    

    let editMarkers: any = <></>;
    if( editZone ) {
        const path = getGisLinePath( editZone.gis_line );
        editMarkers = path.map( (p,i) => {
            return <Marker key={`marker-${i}`}
                            position={p}
                            label={labelMaker( i.toString() )}
                            draggable={true}
                            onDrag={ ( e: google.maps.MapMouseEvent ) => {
                                if ( !e.latLng ) { return; }
                                let tmp = [...curbZones];
                                let cur = { ...tmp[i] }
                                cur = updateCurbZone( editZone, i, e.latLng );
                                tmp[i] = cur;
                                setCurbZones( tmp );
                        }} />
        } );
    }

    let laneLines: any = <></>;
    if( lanes ) {
        laneLines = lanes.map( (l, i) => {
            const color  = l.curb_zone_id === editZone?.curb_zone_id ? "orange" : "gray";
            const zIndex = l.curb_zone_id === editZone?.curb_zone_id ? 2 : 0;
            return <Polyline
                key={`polyline-${i}`}
                path={ getGisLinePath( l.gis_line ) }
                options={{ strokeWeight: 3, strokeColor: color, zIndex: zIndex, clickable: true }}
                onClick={ () => setShowLaneInfo( l )} /> } ) 
    }

    function coords( gisLine: any ) {
        let cs = gisLine.coordinates[0];
        let ret = { lat: 0.0, lng: 0.0 };
        if( !cs ) { return ret }        
        ret = { lat: cs[1], lng: cs[0] };
        return ret;
    }

    function trunc( str: string ) {
        return str.slice( 0, 6 );
    }

    //assumes only two points exist...
    function getPathCenter( path: google.maps.LatLngLiteral[] ) {
        let xc = ( path[0].lat + path[1].lat ) / 2.0;
        let yc = ( path[0].lng + path[1].lng ) / 2.0;
        return { lat: xc, lng: yc };
    }

    function getGisLinePath( gisLine: IGisLine ) {
        let path: google.maps.LatLngLiteral[] = gisLine.coordinates.map( p => {
            return { lat: p[1], lng: p[0] }
        } );
        return path;
    }

    let infoWind = <></>;
    if( showLaneInfo ) {
        const cam      = depl.cameras.find( c => c.uuid === showLaneInfo.camera_uuid );
        const curbZone = depl.curb_zones.find( cz => cz.curb_zone_id === showLaneInfo.curb_zone_id );
        infoWind = <InfoWindow position={ coords( showLaneInfo.gis_line ) }
                                onCloseClick={ () => setShowLaneInfo( undefined ) }>
            <div>
                <table className="table">
                    <tbody>
                        <tr>
                            <td colSpan={2}><h6>Lane Information</h6></td>
                        </tr>
                        <tr>
                            <td>Lane Id</td>
                            <td>{trunc( showLaneInfo.uuid )}</td>
                        </tr>
                        <tr>
                            <td>
                                Lane Type
                            </td>
                            <td>
                                {showLaneInfo.lane_type}
                            </td>
                        </tr>
                        <tr>
                            <td>Camera IMEI</td>
                            <td>{cam?.imei}</td>
                        </tr>
                        <tr>
                            <td>Curb Zone</td>
                            <td>{curbZone?.name}</td>
                        </tr>
                    </tbody>
                </table>
                { editZone ? <Button size="sm" style={{ cursor: "pointer" }} 
                       onClick={() => saveLaneCurbZone( showLaneInfo, editZone ) }>
                           Assign to Curb Zone: {editZone?.name}</Button> : <></>}
            </div>
        </InfoWindow>;
    }

    function CurbZoneSidebar( props: { depl: IDeployment } ) {
        return <>
            <div className="d-flex flex-column align-items-stretch flex-shrink-0" style={{ width: "25vw" }}>
                <a className="fs-5 p-2 link-dark text-decoration-none border-bottom">
                    <div style={{width: "100%"}} className="text-center">Curb Zone Editor</div>
                </a>
                <div className="scrollarea">
                    {props.depl.curb_zones.map( (cz, i) => {
                        const highlight     = cz.curb_zone_id === editZone?.curb_zone_id;
                        const styles        = highlight ? 'bg-primary text-white' : '';
                        const editIndicator = highlight ?
                            <Badge bg="danger"
                                   style={{ cursor: "pointer" }}
                                   onClick={ () => saveCurbZone( cz ) }>Save Changes</Badge> : <></>;
                        return <div
                            style={{ width: "100%" }}
                            key={i}
                            className={`rounded-0 p-2 border-bottom ${styles}`}
                            onClick={ ( e ) => { 
                                setEditZone( cz );
                                setPanTo( getPathCenter( getGisLinePath( cz.gis_line ) ) );
                            }}>
                            <LocationMarkerIcon width={20} /> 
                            {cz.name} &nbsp;
                            {editIndicator}
                        </div> })}
                </div>
            </div>
        </>;
    }    

    function PanToCurbZone() {
        const map = useGoogleMap();
        useEffect( () => {
            if ( !map ) {
                alert("no map found!");
                return;
            }
            if ( !panTo ) {
                return; //this is fine
            }
            map.panTo( panTo );
            setPanTo( null );
            return;
        } );
        return <></>;
    }

    return <main>
        <CurbZoneSidebar depl={depl} />
        <VadeMap
            mapContainerStyle={containerStyle}
            zoom={14}>
            {infoWind}
            {laneLines}
            {curbZoneLines}
            {editMarkers}
            <PanToCurbZone />
        </VadeMap>
    </main>;
}