//react
import { Marker } from "@react-google-maps/api";
import { Field, Form, Formik          } from "formik";
import { useEffect, useState    } from "react";
import { useNavigate, useParams } from "react-router-dom";

//vade
import Api             from "./Api/Api";
import { IDeployment } from "./Api/Deployment";
import GJsonPnt        from "./Util/MapFmt";
import VadeMap         from "./VadeMap";

//new york
const defaultCenter = { lat: 40.7128, lng: -73.9352 };

export default function DeploymentEditPage() {
    let   nav    = useNavigate();
    const params = useParams();
    const [depl, setDepl] = useState<IDeployment>();
    const [center, setCenter] = useState<any>(defaultCenter)

    useEffect( () => {
        Api.getDeployment( params.depId! ).then( resp => resp.json() )
                                          .then( json => {
                                              setDepl( json.deployment ); } );
    }, [params.depId] )

    function parseResp( json: any ) {
        nav( "/dep/" + json.deployment.uuid );
    }

    function onSubmit( values: any ) {
        //we can just submit the depl
        let payload: any = { ...depl };
        payload.name = values.name;
        payload.double_keying_freq = values.double_keying_freq
        Api.updateDeployment( payload ).then( resp => resp.json() )
                                       .then( json => parseResp( json ) );
    }

    //probably should just let pages set h and w
    const containerStyle = { width: '600px', height: '500px' };
    function mapMouseDownHandler( e: google.maps.MapMouseEvent ) {
        if( !depl     ) { return; }
        if( !e.latLng ) { return; }
        //both of those shouldn't happen
        let jsonPnt = GJsonPnt.pntToJson( e.latLng!.lng(), e.latLng!.lat() );
        setDepl( (prev) => {
            if( !prev ) {
                return;
            }
            //this seems really complicated!
            //also i'm not sure why it needs assurance that e.latLng is not null
            let infoCopy: any = {...prev.info}
            infoCopy.geometry = jsonPnt.geometry
            console.log("set depl geometry: ", infoCopy.geometry)
            const updatedData = { ...prev, info: infoCopy };
            console.log("here is the new depl object", updatedData)
            return updatedData;
        } );        
    }

    if( !depl ) {
        return <h2>Loading!</h2>;
    }

    let pins = <></>;
    if ( depl.info ) {
        const deploymentPoint = GJsonPnt.jsonToPnt( depl.info )
        let newPin = <Marker position={deploymentPoint}
                draggable={true}
                onDragEnd={( e: google.maps.MapMouseEvent ) => {
                    if ( !e.latLng ) {
                        return;
                    }
                    setDepl( (prev) => {
                        if( !prev ) {
                            return;
                        }
                        //this seems really complicated!
                        //also i'm not sure why it needs assurance that e.latLng is not null
                        let jsonPnt = GJsonPnt.pntToJson( e.latLng!.lng(), e.latLng!.lat() )
                        let infoCopy: any = {...prev.info}
                        infoCopy.geometry = jsonPnt.geometry
                        return { ...prev, ...{ info:  infoCopy} };
                    } );
                }}>
        </Marker>;
        pins = newPin
        if (center === defaultCenter){
            setCenter(deploymentPoint)
        }
    }

    return <Formik initialValues={depl} enableReinitialize={true} onSubmit={onSubmit}>
        <Form>
            <table className="table table-striped w-auto">
                <thead>
                    <tr>
                        <td colSpan={2} className="text-center">Edit Deployment</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td><Field type="text" name="name" className="form-control" /></td>
                    </tr>
                    <tr>
                        <td>Double Keying Frequency (0-100%)</td>
                        <td><Field type="number" name="double_keying_freq" className="form-control" /></td>
                    </tr>
                    <tr>
                        <td className="text-center" colSpan={2}>Click Map to Set Location</td>
                    </tr>
                    <tr>
                        <td className="align-top">Location</td>
                        <td>
                            <VadeMap 
                                onClick={mapMouseDownHandler}
                                mapContainerStyle={containerStyle}
                                center={center} 
                                zoom={11}>
                                    {pins}
                            </VadeMap>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input className="form-control" type='submit' value='Update Deployment' />
                        </td>
                    </tr>
                </tbody>
            </table>
        </Form>
    </Formik>;
}