
//react
import { useEffect, useState } from "react";
import { useParams           } from "react-router";

//formik
import { Field, Form, Formik } from "formik";

//vade
import Api        from "./Api/Api";
import { ICam }   from "./Api/ICam";
import Deployment from "./Api/Deployment";

export default function CamEditPage() {

    //state mgmt
    const [ cam, setCam ] = useState<ICam>();
    let notSelectedOpt    = Deployment.default();
    notSelectedOpt.name   = "Choose a Deployment";
    notSelectedOpt.uuid   = "";
    const [depls, setDepls] = useState<Deployment[]>( [notSelectedOpt] );
    const [deplsLoaded, setDeplsLoaded] = useState<boolean>();

    //routing
    const params = useParams();

    //hooks
    useEffect( () => {
        Api.getCam( params.camId! ).then( resp => resp.json() )
                                   .then( json => setCam( json.camera ) );
                
        Api.getDeployments().then( resp => resp.json() )
                            .then( json => { 
                                setDepls( [...depls, ...json.deployments] );
                                setDeplsLoaded( true );
                            } );
    }, [params.camId] );

    //everything else
    interface CamEditProps { camId : string; }
    function CamEditDetails( props: CamEditProps ) {
        function onSubmit( values: any ) {
            Api.updateCam( values );
        }
        function DeplList() {
            return <Field className="form-select" name='deployment_uuid' as="select">
                {depls.map( (d) => <option key={d.uuid} value={d.uuid}>{d.name}</option>)}
            </Field>;
        }
        return <div className="mx-3"><Formik
            initialValues={cam!}
            enableReinitialize={true}
            onSubmit={onSubmit}>
              <Form>
                <h3>Camera Edit</h3>
                <table className="table table-striped w-25">
                    <tbody>
                        <tr>
                            <td>IMEI</td>
                            <td><Field className="form-control" name='imei' type='text' /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><Field className="form-control" name='name' type='text' /></td>
                        </tr>
                        <tr>
                            <td>Is Active</td>
                            <td><Field className="form-check-input" name='is_active' type='checkbox' /></td>
                        </tr>
                        <tr>
                            <td>Deployment UUID</td>
                            <td>
                               <DeplList />
                            </td>
                        </tr>                       
                        <tr>
                            <td></td>
                            <td><input className="form-control btn btn-primary" type='submit' value='Update' /></td>
                        </tr>
                    </tbody>
                </table>
              </Form>
            </Formik>
            </div>;
    }
    if( !deplsLoaded ) {
        return <></>;
    }
    return <CamEditDetails camId={params.camId!} />;
}