import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "./Api/Api";
import { Cam } from "./Api/Cam";
import { ICam } from "./Api/ICam";
import ILane from "./Api/ILane";
import { findFirstTrace, getDist, getSessionRecs, getReidSessionRecs, getTraceToSessionMap, SessionRec, pointInPolygonNested } from "./CvDebuggerUtil";
import { ITraceWFrameNum, loadTracesFromJson } from "./CamLaneSessionsPageUtil";
import { CvDebuggerPageDebugger } from "./CvDebuggerPageDebugger";
import { IObj } from "./IVisionOutput";
import { IGetReidObjsResp } from "./Api/GetReidObjsResp";
import { IGroundTruthResp } from "./Data/IGroundTruth";

export default function CvDebuggerPage() {    
    const tz          = "America/New_York";
    const querystring = new URLSearchParams( window.location.search );
    let   initDate    = new Date();
    const qsDate      = querystring.get( "date" );
    if( qsDate ) {
        initDate = DateTime.fromISO( qsDate ).toJSDate();
    }
    const [curDate,   setCurDate]   = useState<Date>( initDate );
    const [cam,       setCam]       = useState<ICam>( new Cam() );
    const [lane,      setLane]      = useState<ILane>();
    const [traces,    setTraces]    = useState<Array<ITraceWFrameNum>>( [] );
    const [ind,       setInd]       = useState<number>( 0 );
    const [ground,    setGround]    = useState<Map<string, IObj[]>>();
    const [vision,    setVision]    = useState<Map<string, IObj[]>>();
    const [grndRecs,  setGrndRecs]  = useState<SessionRec[]>();
    const [visRecs,   setVisRecs]   = useState<SessionRec[]>();
    const [ignoreVis, setIgnoreVis] = useState<string[]>( [] );
    const [ignoreGrn, setIgnoreGrn] = useState<string[]>( [] );

    const threshold = 450;
    const params = useParams();
    const laneId = params.laneId;
    function processLanes( json: any ) {
        const lanes: Array<ILane> = json.lanes;
        const lane = lanes.find( l => l.uuid === laneId );
        if( lane ) {
            setLane( lane );
        }
    }

    useEffect( () => {
        console.log( "getting cam" );
        Api.getCam( params.camId! ).then( resp => resp.json() )
                                   .then( json => {
                                       let c: ICam = json.camera;
                                       setCam( json.camera );
                                    } );

        console.log( "getting lanes" );
        Api.getLanes( params.camId! ).then( resp => resp.json() )
                                     .then( processLanes );
        //load the full week of traces
        const startOfDay = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).startOf("day").toUTC();
        const endOfDay   = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).endOf("day").toUTC();
        Api.getTracesByTime( params.camId!, startOfDay, endOfDay ).then( resp => resp.json() )
                                                                  .then( resp => {
                                                                      console.log( "processing traces" );
                                                                      let newTraces = loadTracesFromJson( tz, resp );
                                                                      let ind = findFirstTrace( curDate, tz, newTraces );
                                                                      setTraces( newTraces );
                                                                      setInd( ind );
                                                                   } );
    }, [params.camId, curDate] );

    useEffect( () => {
        if( !lane ) {
            return;
        }
        const startOfDay = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).startOf("day").toUTC();
        const endOfDay   = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).endOf("day").toUTC();
        Api.getReidObjsByTime( params.camId!, startOfDay, endOfDay )
            .then( resp => resp.json() )
            .then( resp => {
                const objRecs : IGetReidObjsResp = resp;
                if( traces.length === 0 ) {
                    return;
                }
                let visRecs = getReidSessionRecs( objRecs, traces );
                visRecs = visRecs.filter( el => pointInPolygonNested( el.frames[0].obj.centroid, lane.image_trigger_boundary ) );
                // visRecs = visRecs.filter( el => !ignoreVis.includes( el.vehicleId ) );
                const map = getTraceToSessionMap( visRecs )
                setVision( map );
                setVisRecs( visRecs );
        } );
        Api.getGroundTruthByTime( params.camId!, startOfDay, endOfDay )
            .then( resp => resp.json() )
            .then( resp => { 
                const gt : IGroundTruthResp = resp;
                let gtFiltered = gt.ground_truth.filter( el => el.lane_uuid === lane.uuid );
                    // gtFiltered = gtFiltered.filter( el => !ignoreGrn.includes( el.vehicle_uuid ) );
                let grndRecs = getSessionRecs( gtFiltered, traces );
                //grndRecs = grndRecs.filter( el => pointInPolygonNested( el.frames[0].obj.centroid, lane.image_trigger_boundary ) );
                const map = getTraceToSessionMap( grndRecs );
                setGround( map );
                setGrndRecs( grndRecs );
            } );
    }, [traces, lane] );

    useEffect( () => {
        if( traces.length === 0 ) {
            return;
        }

        const titleElement = document.getElementById( traces?.[ind]?.traceId );
        if( !titleElement ) {
            return;
        }
        titleElement.scrollIntoView( { behavior: 'smooth' } );
    } );

    if( !ground || !vision || !lane ) {
        return <></>;
    }
    if( traces.length === 0 ) { return <></>; }
    if( !grndRecs           ) { return <></>; }
    if( !visRecs            ) { return <></>; }

    function findBestMatch( s: SessionRec, grnd: SessionRec[] ) {
        let bestMatch: { session: null | SessionRec, dist: number } = { session: null, dist: Infinity };
        for( const g of grnd ) {
            const dist = getDist( s.frames[0].obj.centroid, g.frames[0].obj.centroid );
            if( bestMatch.dist > dist ) {
                bestMatch = { session: g, dist: dist };
            }
            let score = 0;
            const gSet = new Set( g.frames.map( el => el.traceId ) );
            score = s.frames.filter( f => gSet.has( f.traceId ) ).length;
        }
        return bestMatch;
    }

    function removeGrnVehicle( v: string ) {
        setIgnoreGrn( [ ...ignoreGrn, v ] );
    }
    function removeVisVehicle( v: string ) {
        setIgnoreVis( [ ...ignoreVis, v ] );
    }

    return <CvDebuggerPageDebugger 
                ground={ground}
                vision={vision} 
                grndRecs={grndRecs}
                visRecs={visRecs}
                lane={lane}
                traces={traces}
                ind={ind}
                setInd={setInd}
                threshold={threshold}
                tz={tz} 
                removeGrnVehicle={ v => removeGrnVehicle( v )}
                removeVisVehicle={ v => removeVisVehicle( v )}
                rawVis={undefined}
                rawGrnd={undefined}
                editGrndUrl={`/cam/${lane.camera_uuid}/sessions/${lane.uuid}/ground?date=${DateTime.fromJSDate(curDate).toFormat("yyyy-MM-dd")}`}
    />

    // return <table className="table table-striped w-auto">
    //     <thead>
    //         <tr>
    //             <td>vehicle</td>
    //             <td>start time</td>
    //             <td>duration</td>
    //             <td>pre</td>
    //             <td>start</td>
    //             <td>end</td>
    //             <td>post</td>
    //         </tr>
    //     </thead>
    //     <tbody>
    //         {visRecs.map( rec => {
    //             let a = 0;
    //             let b = rec.frames.length - 1;
    //             let bestMatch = findBestMatch( rec, grndRecs! );
    //             let pre   = <></>;
    //             let start = <></>;
    //             let end   = <></>;
    //             let post  = <></>;
    //             let startTime = "";
    //             let style: React.CSSProperties = { maxHeight: "30vh" };
    //             if( rec.frames.length !== 0 ) {
    //                 let st = rec.frames[a];
    //                 let en = rec.frames[b];
    //                 startTime = st.time.toFormat("h:mma");
    //                 pre   = <TraceGalleryAccuracyVehicle traceId={rec.pre.trace_id} 
    //                                                      time={DateTime.fromISO( rec.pre.time_captured )} style={style} />
    //                 start = <TraceGalleryAccuracyVehicle traceId={st.traceId}        
    //                                                      time={st.time} sessionFrame={st} style={style} />;
    //                 end   = <TraceGalleryAccuracyVehicle traceId={en.traceId}        
    //                                                      time={en.time} sessionFrame={st} style={style} />;
    //                 post  = <TraceGalleryAccuracyVehicle traceId={rec.post.trace_id}
    //                                                      time={DateTime.fromISO( rec.post.time_captured )} style={style} />
    //             }
    //             return <><tr>
    //                 <td>{trunc( rec.vehicleId )}</td>
    //                 <td>
    //                     {startTime}<br />
    //                     {/* {bestMatch.session?.frames[0].time.toFormat("h:mma")} */}
    //                 </td>
    //                 <td>
    //                     {rec.frames.length}<br />
    //                     {/* {bestMatch.session?.frames.length} */}
    //                 </td>
    //                 <td>{pre}</td>
    //                 <td>{start}</td>
    //                 <td>{end}</td>
    //                 <td>{post}</td>
    //             </tr>
    //             <tr>
    //                 <td colSpan={3}></td>
    //                 <td colSpan={1}>
    //                     <Button className="m-1 w-25">👍🏻</Button><Button className="btn-danger m-1 w-25">👎🏻</Button> <br />
    //                 </td>
    //                 <td colSpan={4}>
    //                     <Button className="btn-danger m-1 w-25">Non-Vehicle Session</Button>    <br />
    //                     <Button className="btn-danger m-1 w-25">Cannot Verify Start</Button>    <br />
    //                     <Button className="btn-danger m-1 w-25">Cannot Verify End</Button>      <br />
    //                     <Button className="btn-danger m-1 w-25">Vehicle Still Present</Button>  <br />
    //                     <Button className="btn-danger m-1 w-25">Vehicle Is Different</Button>   <br />
    //                 </td>
    //             </tr></>; } )}
    //     </tbody>
    // </table>;
}