
//react
import {LegacyRef, useEffect, useState} from "react";
import {Badge, Button, Card, Container, Table} from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import {Collapse} from "react-bootstrap";
import {StayMatch} from "./interfaces/DebugInterfaces";
import {isSameDay} from "./AccuracyPageHelpers";
import {Row, Col} from "react-bootstrap"

// Winmjo
import { useRef } from 'react';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexChart, FlexChartLegend, FlexChartAxis, FlexChartSeries } from '@grapecity/wijmo.react.chart';
import { FlexChartAnimation } from '@grapecity/wijmo.react.chart.animation';
import { ComboBox } from '@grapecity/wijmo.react.input';
import {AccuracyDataMap, AggregatedAccuracyMetrics, AggregatedQuestionMetrics, cleanDateName} from "./AccuracyPageHelpers";
import {da, de, ro} from "date-fns/locale";
import {ce} from "@fullcalendar/core/internal-common";
import {AccuracyDebugWindow} from "./AccuracyDebugWindow";

const animatedComponents = makeAnimated()

export interface MainTableProps{
    tableData: DataRow[]
    aggregatedAccuracy: AggregatedAccuracyMetrics
    questionAccuracy: AggregatedQuestionMetrics
    rawAccuracy: AggregatedQuestionMetrics
    showDebug: boolean
    rawApiResp: any
}


export interface DataRow{
    id: number
    cameraId: string
    day: Date
    laneId: string
    sfs: AccuracyDataMap
    noSfs: AccuracyDataMap
}



export function AccuracyMainTable(props: MainTableProps) {
    const data = props.tableData

    const [tableSource, setTableSource] = useState(new CollectionView(data, {sortDescriptions: []}))
    // const [cv, setCV] = useState(new CollectionView(data, {}))
    const flexGrid = useRef();
    const flexChart = useRef() as LegacyRef<FlexChart>
    const [selectedStayDebugData, setSelectedStayDebugData] = useState<StayMatch[][] | null>(null);

    const eventAggMetrics = props.aggregatedAccuracy.no_sfs.event_acc_1_frame
    const stayAggMetrics = props.aggregatedAccuracy.no_sfs.sesh_acc_1_frame
    const questionAggMetrics = props.questionAccuracy.sfs.event_acc_0_frame
    const rawAggMetrics = props.rawAccuracy.sfs.event_acc_0_frame
    const showDebug = props.showDebug
    const rawDebugData = props.rawApiResp.debug


    function getColumnGroups() {
        // based on https://www.grapecity.com/wijmo/demos/Grid/Columns/ColumnGroups/react
        const allocTemplate = '<span class=${value > .2 ? "big-val" : "small-val"}>${value}:${col.format}</span>';
        const amountTemplate = '<span class=${value > 1 ? "big-val" : "small-val"}>${value}:${col.format}</span>';
        return [
            { binding: 'cameraId', header: 'Camera', width: 150 },
            { binding: 'laneId', header: 'Lane', width: 80, align: 'center' },
            { binding: 'day', header: 'Day', width: 100, align: 'center' },
            { header: "1 Frame Buffer (sessions)", align: 'center', columns: [
                    { binding: 'noSfs.sesh_acc_1_frame.correct', header: 'T+', width: 80  },
                    { binding: 'noSfs.sesh_acc_1_frame.cv_unlinked', header: 'F+', width: 80},
                    { binding: 'noSfs.sesh_acc_1_frame.gt_unlinked', header: 'F-', width: 80},
                ]},
            { header: "1 Frame Buffer (starts)", align: 'center', columns: [
                    { binding: 'noSfs.event_acc_1_frame.tpos_starts', header: 'T+', width: 80},
                    { binding: 'noSfs.event_acc_1_frame.fpos_starts', header: 'F+', width: 80},
                    { binding: 'noSfs.event_acc_1_frame.fneg_starts', header: 'F-', width: 80},
                ]},
            { header: "1 Frame Buffer (ends)", align: 'center', columns: [
                    { binding: 'noSfs.event_acc_1_frame.tpos_ends', header: 'T+', width: 80},
                    { binding: 'noSfs.event_acc_1_frame.fpos_ends', header: 'F+', width: 80},
                    { binding: 'noSfs.event_acc_1_frame.fneg_ends', header: 'F-', width: 80},
                ]},
        ];
    }


    function percentage(x: number){
        return Number(x * 100).toFixed(1) + "%"
    }

    function MetricsTable(){
        return <div>
            <Table striped bordered hover size="sm">
                <tr>
                    <th> (+- 2 frame)</th>
                    <th>T+</th>
                    <th>F+</th>
                    <th>F-</th>
                    <th>T-</th>
                    <th>Accuracy</th>
                    <th>Recall</th>
                    <th>Precision</th>
                </tr>
                <tr>
                    <td><strong>Session Based</strong></td>
                    <td>{stayAggMetrics.sum_true_positives}</td>
                    <td>{stayAggMetrics.sum_false_positives}</td>
                    <td>{stayAggMetrics.sum_false_negatives}</td>
                    <td></td>
                    <td>{percentage(stayAggMetrics.accuracy)}</td>
                    <td>{percentage(stayAggMetrics.recall)}</td>
                    <td>{percentage(stayAggMetrics.precision)}</td>
                </tr>
                <tr>
                    <td><strong>Starts</strong></td>
                    <td>{eventAggMetrics.starts.true_positives}</td>
                    <td>{eventAggMetrics.starts.false_positives}</td>
                    <td>{eventAggMetrics.starts.false_negatives}</td>
                    <td></td>
                    <td>{percentage(eventAggMetrics.starts.accuracy)}</td>
                    <td>{percentage(eventAggMetrics.starts.recall)}</td>
                    <td>{percentage(eventAggMetrics.starts.precision)}</td>
                </tr>
                <tr>
                    <td><strong>Ends</strong></td>
                    <td>{eventAggMetrics.ends.true_positives}</td>
                    <td>{eventAggMetrics.ends.false_positives}</td>
                    <td>{eventAggMetrics.ends.false_negatives}</td>
                    <td></td>
                    <td>{percentage(eventAggMetrics.ends.accuracy)}</td>
                    <td>{percentage(eventAggMetrics.ends.recall)}</td>
                    <td>{percentage(eventAggMetrics.ends.precision)}</td>
                </tr>
                <tr>
                    <td><strong>All Events</strong></td>
                    <td>{eventAggMetrics.all.true_positives}</td>
                    <td>{eventAggMetrics.all.false_positives}</td>
                    <td>{eventAggMetrics.all.false_negatives}</td>
                    <td></td>
                    <td>{percentage(eventAggMetrics.all.accuracy)}</td>
                    <td>{percentage(eventAggMetrics.all.recall)}</td>
                    <td>{percentage(eventAggMetrics.all.precision)}</td>
                </tr>
                <tr>
                    <td><strong>CV Raw Event Accuracy</strong></td>
                    <td>{rawAggMetrics?.all?.true_positives}</td>
                    <td>{rawAggMetrics?.all?.false_positives}</td>
                    <td>{rawAggMetrics?.all?.false_negatives}</td>
                    <td>{rawAggMetrics?.all?.true_negatives}</td>
                    <td>{percentage(rawAggMetrics?.all?.accuracy)}</td>
                    <td>{percentage(rawAggMetrics?.all?.recall)}</td>
                    <td>{percentage(rawAggMetrics?.all?.precision)}</td>
                </tr>
                <tr>
                    <td><strong>Question Accuracy</strong></td>
                    <td>{questionAggMetrics?.all?.true_positives}</td>
                    <td>{questionAggMetrics?.all?.false_positives}</td>
                    <td>{questionAggMetrics?.all?.false_negatives}</td>
                    <td>{questionAggMetrics?.all?.true_negatives}</td>
                    <td>{percentage(questionAggMetrics?.all?.accuracy)}</td>
                    <td>{percentage(questionAggMetrics?.all?.recall)}</td>
                    <td>{percentage(questionAggMetrics?.all?.precision)}</td>
                </tr>
            </Table>
            <Table striped bordered hover size="sm">
                <tr>
                    <th>Median Stay Accuracy By Lane</th>
                </tr>
                <tr>
                    <td>{percentage(stayAggMetrics.median_lane_accuracy)}</td>
                </tr>
            </Table>
        </div>
    }

    function getStayMatchingData(cameraId: string, day: Date, debugData: any){
        const stayDataForCamera: StayMatch[][] = debugData[cameraId]
        const staysOnDay = stayDataForCamera.filter((stayGroup) => {return isSameDay(new Date(stayGroup[0].day), new Date(day))})
        return staysOnDay
    }

    function selectionChanged(cellRange: any){
        if (!showDebug) return
        if (!rawDebugData) return
        const rowIndex = cellRange.topRow
        if (rowIndex < 0 || rowIndex >= data.length) return
        console.log("Selected row", rowIndex)
        const selectedData = data[rowIndex]
        const stayMatchingData = getStayMatchingData(selectedData.cameraId, selectedData.day, rawDebugData.stay_matching)
        setSelectedStayDebugData(stayMatchingData)
    }

    return (
        <div>

            <Container fluid style={{maxHeight: "65vh", overflowY: "scroll", minHeight: "50vh"}}>
                <Row>
                    <FlexGrid
                        itemsSource={tableSource}
                        allowMerging={true}
                        isReadOnly={true}
                        alternatingRowStep={0}
                        autoGenerateColumns={false}
                        columnGroups={getColumnGroups()}
                        selectionChanged={(grid: any) => selectionChanged(grid.selection)}

                    />
                </Row>
                <Row><div style={{height: "10px"}}/></Row>
                {showDebug && <Row>
                    <AccuracyDebugWindow stayMatchingData={selectedStayDebugData} />
                </Row>}
            </Container>
            <br></br>
            <MetricsTable/>
        </div>
    );


}
