
//coded for google maps right now
export default class MapFmt {
    
    //take lng and lat and return geojson Point
    public static pntToJson( lng: number, lat: number ): any {
        return { geometry: { type: "Point", coordinates: [ lng, lat ] } };
    }

    //take geojson point and return gmap coord
    public static jsonToPnt( json: any ) {
        return { lat: json.geometry.coordinates[1], 
                 lng: json.geometry.coordinates[0] };
    }
}