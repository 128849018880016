//react
import { useEffect, useState  } from "react";
import { Link, useLocation, useNavigate, useParams                     } from "react-router-dom";

//vade
import Api          from "./Api/Api";
import ApiUrl       from "./Api/ApiUrl";
import { IPnt }     from "./Api/IPnt";
import { IGetSessionsBetweenTimesResp, INewStay, IStay, IStayOpts, IStayRaw } from "./Api/IStay";
import ILane        from "./Api/ILane";

import DatePicker   from "react-date-picker"; 
import TraceGallery from "./TraceGallery";
import "./App.css"
import { ITraceWFrameNum, displayId, getTraceByOffset, parseUtcToTz, loadTracesFromJson, loadStaysFromJson, processStay, getVehicleSquare } from "./CamLaneSessionsPageUtil";
import { HelpModal } from "./HelpModal";
import { DateTime } from "luxon"
import DateUtil from "./Util/DateUtil";
import { ICamNew } from "./Api/ICam";
import { IDeployment } from "./Api/Deployment";
import { CalendarIcon, CameraIcon, TrashIcon } from "@heroicons/react/solid";
import { Button, Nav } from "react-bootstrap";

export default function CamLaneSessionsPage() {
    const querystring = new URLSearchParams( window.location.search );
    let   initDate    = new Date();
    const qsDate      = querystring.get("date");
    if( qsDate ) {
        initDate = DateTime.fromISO( qsDate ).toJSDate();
    }
    const [cam,        setCam]        = useState<ICamNew>();
    const [depl,       setDepl]       = useState<IDeployment>();
    const [tz,         setTz]         = useState<string>( "America/New_York" );
    const [curDate,    setCurDate]    = useState<Date>( initDate );
    const [lane,       setLane]       = useState<ILane>();
    const [traces,     setTraces]     = useState<Array<ITraceWFrameNum>>( [] );
    const [sessions,   setSessions]   = useState<Array<IStay>>( [] );
    const [session,    setSession]    = useState<IStay>();
    const [ind,        setInd]        = useState<number>( 0 );
    const [statusMsg,  setStatusMsg]  = useState<string>( "" );
    const [fontSize,   setFontSize]   = useState<number>( 0 );
    const [createMode, setCreateMode] = useState<boolean>( false );
    const [moveMode,   setMoveMode]   = useState<boolean>( false );
    const [createVehicleType, setCreateVehicleType] = useState<string>( "sedan" );
    const [showHelp,   setShowHelp]   = useState<boolean>( false );
    const params   = useParams();
    const camId    = params.camId!;
    const loc      = useLocation();
    const navigate = useNavigate(); 
    const laneId   = params.laneId!;
    const domain   = params.domain!;

    useEffect( () => {
        Api.getCam( camId ).then( resp => resp.json() )
                           .then( json => { 
                               let c: ICamNew = json.camera;
                               setCam( c );
                           } );
    }, [camId] );

    useEffect( () => {
        if( !cam ) { return; }
        Api.getDeployment( cam.deployment_uuid ).then( resp => resp.json() )
                                                .then( json => { 
                                                    let d: IDeployment = json.deployment;
                                                    setTz( d.info.tz );
                                                    setDepl( d );
                                                } );
    }, [params.camId, cam] );

    useEffect( () => {
        window.addEventListener( "keydown", onKeyDownHandler );
        return () => { 
            window.removeEventListener( "keydown", onKeyDownHandler );
        }
    }, [traces, ind, session, fontSize] );

    useEffect( () => {
        if( !depl ) { return; }
        Api.getLanes( params.camId! ).then( resp => resp.json() )
                                     .then( processLanes );

        //load the full week of traces
        const startOfDay = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).startOf("day").toUTC();
        const endOfDay   = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true }).endOf("day").toUTC();
        Api.getTracesByTime( params.camId!, startOfDay, endOfDay ).then( resp => resp.json()           )
                                                                  .then( resp => processTraces( resp ) );
        
        //load all sessions for the selected day
        Api.getSessionsInDateRange( params.camId!, startOfDay, endOfDay ).then( resp => resp.json() )
                                                                         .then( loadSessions );

    }, [params.camId, curDate, depl] );

    function local( dateStr: DateTime ) {
        return parseUtcToTz( tz, dateStr.toISO() );
    }

    function updateSessions( session: IStay ) {
        const updatedSessions = sessions.map( s => {
            if( s.vehicle_id !== session.vehicle_id ) {
                return s;
            }
            return { ...s, ...session }; //update all props of this object using the new object
        } );
        setSessions( updatedSessions );
    }

    function receiveSession( rawStay: IStayRaw ) {
        let stay = processStay( rawStay );
        setSession( stay );
        updateSessions( stay );
    }

    function setStartTrace( session: IStay, ind: number ): void {
        const newStart = traces[ind];
        if( newStart.timeCapturedLocal.valueOf() > session.end_time.valueOf() ) {
            setNotification( "cannot set session start after end" );
            return;
        }
        Api.updateStartTrace( session.vehicle_id, traces[ind].traceId ).then( s => s.json() )
                                                                       .then( json => { receiveSession( json.session ); } );
    }

    function setEndTrace( session: IStay, ind: number ): void {
        const newEnd = traces[ind];
        if( newEnd.timeCapturedLocal.valueOf() < session.start_time.valueOf() ) {
            setNotification( "cannot set session end before start" );
            return;
        }
        Api.updateEndTrace( session.vehicle_id, traces[ind].traceId ).then( s => s.json() )
                                                                     .then( json => { receiveSession( json.session ); } );
    }

    function sameDay( t: ITraceWFrameNum ) {
        const a = DateTime.fromJSDate( curDate ).setZone( tz, { keepLocalTime: true } );
        const b = t.timeCapturedLocal;
        let res = ( a.year  === b.year
            && a.month === b.month
            && a.day   === b.day );
        if( !res ) {
            console.log( a.toISO() + " | " + b.toISO() );
        }
        return res;
    }

    function processTraces( json: any ) {
        const newTraces = loadTracesFromJson( tz, json );
        setTraces( newTraces );
        const first = newTraces.findIndex( t => sameDay( t ) );
        if( first !== -1 ) {
            setInd( first );
        }
    }

    function processLanes( json: any ) {
        const lanes: Array<ILane> = json.lanes;
        const lane = lanes.find( l => l.uuid == laneId );
        if( lane ) {
            setLane( lane );
        }
    }

    function getTraceInd( traceId: string ) {
        return traces.findIndex( t => traceId === t.traceId );
    }

    function loadNextSession( s: IStay, inc: number ) {
        const cur = sessions.findIndex( innerSession => innerSession.vehicle_id === s.vehicle_id );
        let next  = cur + inc;
        next      = Math.min( next, sessions.length - 1 );
        next      = Math.max( next, 0 );
        if( cur !== next ) {
            loadSession( sessions[next] );
        }
    }

    function changeVehicleType( type: string ) {
        setCreateVehicleType( type );
        setNotification( "create mode: " + type );
    }

    function onKeyDownHandler( e: KeyboardEvent ) {
        let newInd = ind;
        const isEditable = domain === "ground";
        if( session ) {
            if( e.key === ")" ) { setFontSize( 0 ); }
            if( e.key === "+" ) { setFontSize( fontSize + 1 ); }
            if( e.key === "_" ) { setFontSize( fontSize - 1 ); }
            if( e.key === "q" ) {
                newInd = getTraceByOffset( traces, session.start_trace, -1 )?.frameNum!;
            }
            if( e.key === "w" ) { newInd = getTraceInd( session.start_trace );         }
            if( e.key === "e" ) { newInd = getTraceInd( session.end_trace );           }
            if( e.key === "r" ) {
                newInd = getTraceByOffset( traces, session.end_trace, 1 )?.frameNum!;
            }

            //if either of these are pressed, do not continue
            if( e.key === "," ) { loadNextSession( session, -1 ); return; }
            if( e.key === "." ) { loadNextSession( session,  1 ); return; }

            //editing functions
            if( isEditable && e.key === "[" ) {
                setNotification( "set session start to " + ind );
                setStartTrace( session, ind );
            }
            if( isEditable && e.key === "]" ) {
                setNotification( "set session end to " + ind );
                setEndTrace( session, ind );
            }
            if( isEditable && e.key === "Backspace" ) { delSession ( session ); }
            if( isEditable && e.key === "m"         ) { 
                setNotification( "moving session: " + session.vehicle_id.toString() )
                setMoveMode( true    );
            }
        }

        if( e.key === "1" ) { changeVehicleType( "sedan"         ); }
        if( e.key === "2" ) { changeVehicleType( "suv"           ); }
        if( e.key === "3" ) { changeVehicleType( "minivan"       ); }
        if( e.key === "4" ) { changeVehicleType( "pickup truck"  ); }
        if( e.key === "5" ) { changeVehicleType( "service van"   ); }
        if( e.key === "6" ) { changeVehicleType( "box truck"     ); }
        if( e.key === "7" ) { changeVehicleType( "bus"           ); }
        if( e.key === "8" ) { changeVehicleType( "freight"       ); }
        if( e.key === "9" ) { changeVehicleType( "motorbike"     ); }

        if( !e.metaKey && e.key === "c" ) { //enter session creation mode!
            setNotification( "create mode: " + createVehicleType );
            changeVehicleType( "sedan" );
            setCreateMode( true );
        }

        if( !e.shiftKey && e.key === "a" ) { newInd = 0;             }
        if( !e.shiftKey && e.key === "z" ) { newInd = traces.length; }

        //step by 5 shortcuts
        if( e.shiftKey  && e.key === "ArrowLeft"  ) { newInd -= 5; }
        if( e.shiftKey  && e.key === "ArrowRight" ) { newInd += 5; }

        //single step shortcuts
        if( !e.shiftKey && e.key === "ArrowLeft"  ) { newInd--; }
        if( !e.shiftKey && e.key === "ArrowRight" ) { newInd++; }

        //help toggle
        if( e.key == "h") { setShowHelp( !showHelp ); }
        if( e.key === "Escape" ) { 
            setCreateMode( false  );
            setMoveMode  ( false  );
        }

        //safety checks
        newInd = Math.min( newInd, traces.length - 1 );
        newInd = Math.max( newInd, 0 );
        setInd( newInd );
    }

    function loadSessions( json: IGetSessionsBetweenTimesResp ) {
        let sorted = loadStaysFromJson( json.sessions );
        sorted = sorted.filter( s => s.domain === domain && s.lane_uuid === laneId );
        setSessions( sorted );

        //always select the first session
        if( sorted.length !== 0 ) {
            loadSession( sorted[0] );
        }
    }

    function processStays( sessions: IStay[] ): IStay[] {
        const laneSessions = sessions.filter( s => s.lane_uuid == laneId ); 
        laneSessions.sort( (a, b) =>
            a.start_time.valueOf() > b.start_time.valueOf() ? -1 : 1 ).reverse();
        return laneSessions;
    }

    function createModeMouseDownHandler( pnt: IPnt ) {
        let newStay: INewStay = {
            lane_uuid:    laneId!,
            start_trace:  traces[ind].traceId,
            end_trace:    traces[ind].traceId,
            class_label:  createVehicleType,
            bbox:         getVehicleSquare( pnt ),
            ground_plane: getVehicleSquare( pnt ),
            domain:       domain
        };
        Api.createSession( newStay ).then( resp => resp.json() )
                                            .then( json => {
                                                const recvSession: IStay = processStay( json.session );
                                                setSessions( processStays( [...sessions, recvSession] ) );
                                                loadSession( recvSession );
                                            } );
        setCreateMode( false );
        setMoveMode( false );
    }

    function moveModeMouseDownHandler( pnt: IPnt ) {
        let changes: IStayOpts = {
            ground_plane: getVehicleSquare( pnt ),
            bbox:         getVehicleSquare( pnt )
        };
        Api.updateSession( session?.vehicle_id!, changes ).then( resp => resp.json() )
                                    .then( json => {
                                                const recvSession: IStay = processStay( json.session );
                                                const mergedSessions = sessions.map( el => {
                                                    if( el.vehicle_id === recvSession.vehicle_id ) {
                                                        return recvSession;
                                                    }
                                                    return el;
                                                } );
                                                setSessions( processStays( mergedSessions ) );
                                                loadSession( recvSession );
                                            } );
        setCreateMode( false );
        setMoveMode( false );
    }


    function traceGalleryMouseDownHandler( pnt: IPnt ) {
        if( createMode ) { 
            createModeMouseDownHandler( pnt );
            return;
        }
        if( moveMode ) {
            moveModeMouseDownHandler( pnt );
            return;
        }
    }

    function createModeMouseMoveHandler( pnt: IPnt, ctx: CanvasRenderingContext2D) {
        ctx.save();
        ctx.strokeStyle = "yellow";
        ctx.lineWidth = 3;
        ctx.strokeStyle = "solid";
        ctx.beginPath();
        ctx.lineTo( pnt.x - 15, pnt.y - 15 );
        ctx.lineTo( pnt.x + 15, pnt.y - 15 );
        ctx.lineTo( pnt.x + 15, pnt.y + 15 );
        ctx.lineTo( pnt.x - 15, pnt.y + 15 );
        ctx.lineTo( pnt.x - 15, pnt.y - 15 );
        ctx.stroke();
        ctx.font = "12pt Monaco";
        ctx.fillText( createVehicleType, pnt.x + 20, pnt.y + 10 );
        ctx.restore();
    }

    function moveModeMouseMoveHandler( pnt: IPnt, ctx: CanvasRenderingContext2D ) {
        ctx.save();
        ctx.strokeStyle = "yellow";
        ctx.lineWidth = 3;
        ctx.strokeStyle = "solid";
        ctx.beginPath();
        ctx.lineTo( pnt.x - 15, pnt.y - 15 );
        ctx.lineTo( pnt.x + 15, pnt.y - 15 );
        ctx.lineTo( pnt.x + 15, pnt.y + 15 );
        ctx.lineTo( pnt.x - 15, pnt.y + 15 );
        ctx.lineTo( pnt.x - 15, pnt.y - 15 );
        ctx.stroke();
        ctx.font = "12pt Monaco";
        const text = `${displayId( session?.vehicle_id! )} ${session?.class_label}`;
        ctx.lineWidth = 5;
        ctx.strokeStyle = "black";
        ctx.strokeText( text, pnt.x + 20, pnt.y + 10 );
        ctx.fillText( text, pnt.x + 20, pnt.y + 10 );
        ctx.restore();
    }

    function traceGalleryMouseMoveHandler( pnt: IPnt, ctx: CanvasRenderingContext2D ) {
        if( createMode ) {
            createModeMouseMoveHandler( pnt, ctx );
            return;
        }
        if( moveMode && session ) {
            moveModeMouseMoveHandler( pnt, ctx );
            return;
        }
    }

    //only show image gallery when there are traces
    let imageGallery = <h3>No Images Loaded</h3>;
    if( traces.length > 0 && lane ) {
        imageGallery = (
            <TraceGallery
                    style={{maxWidth: "640px"}}
                    fontSize={fontSize}
                    ind={ind}
                    traces={traces}
                    lane={lane}
                    session={session}
                    sessions={sessions}
                    onMouseDown={traceGalleryMouseDownHandler}
                    onMouseMove={traceGalleryMouseMoveHandler}
                    onIndChange={ ( ind ) => setInd( ind ) }
                /> );
    }

    function baseLoadSession( session: IStay, newInd: number ) {
        if( newInd !== -1 ) {
            setInd( newInd );
        }
        setNotification( "editing session: " + session.vehicle_id.toString() );
    }

    //loads the session and selects the first trace
    function loadSession( s: IStay ) {
        setSession( s );
        let newInd = traces.findIndex( t => t.traceId === s.start_trace );
        baseLoadSession( s, newInd );
    }

    //loads the session and selects the specified trace
    //traceId must be a trace included in the session
    function loadSessionByTrace( session: IStay, traceId: string ) {
        setSession( session );
        let newInd = traces.findIndex( t => t.traceId == traceId );
        baseLoadSession( session, newInd );
    }

    function delSession( s: IStay ) {
        const ans = window.confirm( "Do you want to delete this session?" );
        if( ans ) {
            Api.deleteSession( s.vehicle_id ).then( resp => resp.json() )
                                               .then( json => {
                                                   /* TODO: check for errors, assume it worked */
                                                   loadNextSession( session!, 1 ); //load previous session
                                                   const newSessions = sessions.filter( innerSession => innerSession !== s );
                                                   setSessions( newSessions );
                                               } );
        }
    }

    function SessionRow( s: IStay, sel: boolean, duration: string ) {
        let cn: string = "";
        if( sel ) {
            cn = "bg-primary text-white";
        }
        const id    = displayId( s.vehicle_id );
        const strId = s.vehicle_id.toString();
        const pre   = getTraceByOffset( traces, s.start_trace, -1 );    
        const post  = getTraceByOffset( traces, s.end_trace,   +1 );
        return <tr id={strId} key={strId} className={cn}>
            <td>
                { id }
            </td>
            <td>
                {s.class_label}
            </td>
            <td>
                { local( s.start_time ).toFormat("h:mma").toLowerCase() }
            </td>
            <td className="text-end">
                {duration}
            </td>
            <td>
                <img width="100" src={pre?.imgUrl} onClick={ () => loadSessionByTrace( s, pre?.traceId! ) } />
            </td>
            <td>
                <img width="100" src={ApiUrl.traceImgOriginal( s.start_trace )} onClick={() => loadSessionByTrace( s, s.start_trace )} />
            </td>
            <td>
                <img width="100" src={ApiUrl.traceImgOriginal( s.end_trace )} onClick={() => loadSessionByTrace( s, s.end_trace )} />
            </td>
            <td>
                <img width="100" src={pre?.imgUrl} onClick={ () => loadSessionByTrace( s, post?.traceId! ) } />
            </td>
        </tr>;
    }
    
    let sessionTable = <table className="table align-middle table-sm session-editor-text w-auto font-monospace">
        <thead>
            <tr>
                <td>id</td>
                <td>type</td>
                <td>start</td>
                <td>duration</td>
                <td>pre   (q)</td>
                <td>first (w)</td>
                <td>last  (e)</td>
                <td>post  (r)</td>
            </tr>
        </thead>
        <tbody>
            { sessions.map( (s, i)=> {
                const selected = s.vehicle_id === session?.vehicle_id;
                let sessionDur = "";
                if( s.start_trace && s.end_trace ) {
                    const end   = s.end_time;
                    const start = s.start_time;
                    sessionDur = DateUtil.hoursMinutes( start, end );
                }
                return SessionRow( s, selected, sessionDur );
            } ) }
        </tbody>
    </table>;

    function onDateChange( date: Date ): Date {
        const isoDate = DateTime.fromJSDate( date ).toISODate();
        navigate( loc.pathname + "?date=" + isoDate );
        setCurDate( date );
        return date;
    }

    async function setNotification( text: string ) {
        setStatusMsg( text );
    }

    if( traces.length === 0 ) {
        return <>Loading!</>;
    }

    function SessionDetails() {
        return <table className="table table-sm session-editor-text">
            <tbody>
                <tr>
                    <td>Vehicle</td>
                    <td>{session?.vehicle_id}</td>
                </tr>
                <tr>
                    <td>Start Time</td>
                    <td>{session?.start_time.toISO()} </td>
                </tr>
                <tr>
                    <td>End Time</td>
                    <td>{session?.end_time.toISO()}</td>
                </tr>
                <tr>
                    <td>First Trace </td>
                    <td>{session?.start_trace}</td>
                </tr>
                <tr>
                    <td>Last Trace </td>
                    <td>{session?.end_trace}</td>
                </tr>
            </tbody>
        </table>;
    }

    //using main means that we get scrolling
    // (see DeploymentViewPage.css (need to rename))
    const dateStr = DateTime.fromJSDate( initDate ).toISODate();
    return <main>
        <div className="d-flex flex-column align-items stretch m-1">
            <span>
                <Link to={`/cam/${camId}`}>
                    <CameraIcon width={20} />Cam
                </Link>
                <Link to={`/cam/${camId}/cal?date=${dateStr}`}>
                    <CalendarIcon width={20} />Calendar
                </Link>
            </span>
            <div className="font-monospace">
                {imageGallery}
                <SessionDetails />
            </div>
        </div>
        <div className="container">
            <div className="row" style={{ height: "4vh" }}>
                <div className="col">
                    <span>
                        <DatePicker className="text-end w-auto font-sm" value={curDate} clearIcon={null} onChange={onDateChange} />
                        &nbsp;
                        <HelpModal showHelp={showHelp} setShowHelp={setShowHelp} />
                        &nbsp;
                        <span>{statusMsg}</span>
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col scrollarea" style={{ height: "90vh", width: "30vw" }}>
                    {sessionTable}
                </div>
            </div>
        </div>
    </main>;
}
