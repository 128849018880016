import {Cam} from "../Api/Cam";
import {ICam} from "../Api/ICam";
import ILane from "../Api/ILane";
import {da} from "date-fns/locale";
import {CameraJob} from "../CVControllerComponents/CVControllerMainTable";

export interface TimeFrame {
    day: Date
    accuracyData: AccuracyDataForDay[] | undefined | null
    selected: boolean
}



export interface AccuracySessionData {
    correct: number
    gt_unlinked: number
    cv_unlinked: number
}

export interface AccuracyEventData {
    cv_starts: number
    tpos_starts: number
    fpos_starts: number
    fneg_starts: number
    cv_ends: number
    tpos_ends: number
    fpos_ends: number
    fneg_ends: number
}

export interface AccuracyDataMap {
    sesh_acc_2_frame: AccuracySessionData
    event_acc_2_frame: AccuracyEventData
    sesh_acc_1_frame: AccuracySessionData
    event_acc_1_frame: AccuracyEventData
    sesh_acc_0_frame: AccuracySessionData
    event_acc_0_frame: AccuracyEventData
}

export interface AccuracyDataForDay {
    day: Date
    camera_uuid: string
    lane_uuid: string
    domain: string
    sfs: AccuracyDataMap
    no_sfs: AccuracyDataMap
}


export interface AggregatedAccuracyMetrics{
    sfs: {
        event_acc_1_frame: {
            starts: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number
            },
            ends: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number
            },
            all: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number
            }
        },
        sesh_acc_1_frame: {
            sum_true_positives: number
            sum_false_positives: number
            sum_false_negatives: number
            accuracy: number
            recall: number
            precision: number,
            median_lane_accuracy: number
        },
    },
    no_sfs: {
        event_acc_1_frame: {
            starts: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number
            },
            ends: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number
            },
            all: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number
            }
        },
        sesh_acc_1_frame: {
            sum_true_positives: number
            sum_false_positives: number
            sum_false_negatives: number
            accuracy: number
            recall: number
            precision: number,
            median_lane_accuracy: number
        }
    }
}


export interface AggregatedQuestionMetrics{
    sfs: {
        event_acc_0_frame: {
            all: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number,
                true_negatives: number
            },
            ends: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number,
                true_negatives: number
            },
            starts: {
                accuracy: number,
                false_negatives: number,
                false_positives: number,
                precision: number,
                recall: number,
                true_positives: number,
                true_negatives: number
            },
        }
    }
}

export function getCurrWeekRange(){
    const currDate = new Date()
    let prevWeek = new Date()
    prevWeek.setDate(currDate.getDate() - 7)
    const weekRange = {
        from: {year: prevWeek.getFullYear(), month: prevWeek.getMonth() + 1, day: prevWeek.getDate()},
        to: {year: currDate.getFullYear(), month: currDate.getMonth() + 1, day: currDate.getDate()}
    }
    return weekRange
}

export function getDaysArray(start: Date, end: Date) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
}


export function isSameDay(day1: Date, day2: Date){
    return (day1.getFullYear() === day2.getFullYear()) && (day1.getDate() === day2.getDate()) && (day1.getMonth() === day2.getMonth())
}


export function cleanDateName(date: Date){
    return date.toISOString().split('T')[0]
}


