
export default class CoordUtil {

    public static toStr( x?: number ): string {        
        if( x === undefined ) {
            return "0.0";
        }
        if( x === null ) {
            return "0.0";
        }
        return x.toFixed(8); //plenty precise for what we need
    }
}