import { ICam } from "./ICam";
import { IGisLine } from "./IGisLine";
import ILane from "./ILane";

export interface ICurbZone {
    curb_zone_id:            string;
    geometry:                Geometry;
    curb_policy_ids:         any[];
    prev_policies:           any[];
    published_date:          string;
    last_updated_date:       string;
    prev_curb_zone_ids:      any[];
    start_date:              string;
    end_date:                null;
    location_references:     any[];
    name:                    string;
    user_zone_id:            null;
    street_name:             string;
    cross_street_start_name: string;
    cross_street_end_name:   string;
    length:                  number;
    available_space_lengths: any[];
    availability_time:       null;
    width:                   number;
    parking_angle:           string;
    num_spaces:              null;
    street_side:             string;
    median:                  boolean;
    entire_roadway:          boolean;
    curb_area_ids:           any[];
    curb_space_ids:          any[];
    gis_line:                IGisLine;
}

export interface Geometry {
    coordinates: Array<Array<number[]>>;
    type:        string;
}

export interface Info {
    geometry: Geometry;
    tz: string
}

export interface IDeployment {
    uuid: string;
    name: string;
    info: Info;
    cameras: Array<ICam>;
    time_created: Date;
    lanes: Array<ILane>;
    boundary: any;
    curb_zones: Array<ICurbZone>;

    double_keying_freq: number;
}

export default class Deployment {
    constructor(
        public uuid:         string,
        public name:         string, 
        public info:         string, 
        public cameras:      ICam[],
        public time_created: Date,
        public lanes:        Array<ILane>,
        public boundary:     any,
        public curb_zones:   ICurbZone[] ) {} //boundary is geojson polygon

    //get one for default display
    static default() {
        return new Deployment( "", "", "", [], new Date(), [], null, [] );
    }
}