//react
import React, { createRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//axios
import axios from "axios";

//mapbox
import CoordUtil   from "./Util/CoordUtil";
import VadeMap     from "./VadeMap";

//vade
import   Api       from "./Api/Api";
import { ICam    } from "./Api/ICam";
import { IGeoPnt } from "./Api/IGeoPnt";
import { IPnt    } from "./Api/IPnt";
import { Cam     } from "./Api/Cam";
import { Marker, Polyline, useGoogleMap  } from "@react-google-maps/api";
import ApiUrl      from "./Api/ApiUrl";
import ILane       from "./Api/ILane";
import { ITrace } from "./Api/ITrace";
import { IDeployment } from "./Api/Deployment";

function coords( cam: any ) {
    let cs = cam?.current_installation?.location?.coordinates;
    let ret = { lat: 0.0, lng: 0.0 };
    if( !cs ) { return ret }        
    ret = { lat: cs[1], lng: cs[0] };
    return ret;
}

export default function CamVisionPage() {
    const params = useParams();
    const camId  = params.camId;
    const laneId = params.laneId;

    // Core data to show
    const [camera,    setCamera]    = useState<ICam>();
    const [camLoaded, setCamLoaded] = useState<boolean>(false);

    // Props for Drawing
    const [background, setBackground] = useState<HTMLImageElement>();    
    
    //drawing data
    const [drawMode,         setDrawMode]        = useState<string>( "line" );   //canvas draw mode (what data are we filling in)
    const [lineCoords,       setLineCoords]      = useState<IPnt[]>( [] ); //gis line stuff
    const [lineGeoCoords,    setLineGeoCoords]   = useState<IGeoPnt[]>( [] );    
    const [curLineCoordInd,  setCurLineCoordInd] = useState<number>( -1 );
    const [polyCoords,       setPolyCoords]      = useState<IPnt[]>( [] );
    const [hoverCoords,      setHoverCoords]     = useState<IPnt>( { x: -1, y: -1 } );
    const [polygonClosed,    setPolygonClosed]   = useState<boolean>( false );
    const [camCoord,         setCamCoord]        = useState<IGeoPnt | null>( null );
    const [panTo,            setPanTo]           = useState<IGeoPnt | null>( null );
    const [depl,             setDepl]            = useState<IDeployment>();
    const [laneType,         setLaneType]        = useState<string>("curb_parking");

    const [canvasRef] = useState<React.RefObject<HTMLCanvasElement>>( createRef<HTMLCanvasElement>() );
    const nav = useNavigate();

    const reset = () => {
        setPolygonClosed( false );
        setPolyCoords   ( [] );
        setLineCoords   ( [] );
        setLineGeoCoords( [] );
    };

    function resetPoly() {
        setPolygonClosed( false );
        setPolyCoords( [] );
    }

    function mouseDownHandler( e: React.MouseEvent ) {
        //not sure what we are checking for here
        if ( canvasRef.current && background !== undefined ) {
            const canvas       = canvasRef.current;
            const boundingRect = canvas.getBoundingClientRect();
            const coord = {
                x: Math.round( ( e.clientX - boundingRect.x ) * ( canvas.width / canvas.clientWidth ) ),
                y: Math.round( ( e.clientY - boundingRect.y ) * ( canvas.width / canvas.clientWidth ) ),
            };
            if ( drawMode === "polygon" && !polygonClosed ) {
                // check for collision with first polygon point
                if ( polyCoords.length > 2 ) {
                    if ( coord.x >= polyCoords[0].x - 15 && coord.x <= polyCoords[0].x + 15 &&
                         coord.y >= polyCoords[0].y - 15 && coord.y <= polyCoords[0].y + 15 ) {
                        const newPolyCoords = [...polyCoords];
                        setPolyCoords( newPolyCoords );
                        setPolygonClosed( true );
                        return;
                    }
                }
                const newPolyCoords = [...polyCoords, coord];
                setPolyCoords( newPolyCoords );
            }
            if( drawMode === "line" ) {
                const newLineCoords = [...lineCoords, coord];
                setLineCoords( newLineCoords );
                
                //have it set to -1 at first, we'll watch for these
                const newLineGeoCoords = [...lineGeoCoords, { lng: -1, lat: -1 }];
                setLineGeoCoords( newLineGeoCoords );
            }
        }
    };

    function mouseMoveHandler( e: React.MouseEvent ) {
        if ( canvasRef.current && background !== undefined ) {
            const canvas       = canvasRef.current;
            const boundingRect = canvas.getBoundingClientRect();
            const coord = {
                x: Math.round( ( e.clientX - boundingRect.x ) * ( canvas.width / canvas.clientWidth ) ),
                y: Math.round( ( e.clientY - boundingRect.y ) * ( canvas.width / canvas.clientWidth ) ),
            };
            if ( drawMode === 'polygon' && !polygonClosed ) {
                setHoverCoords( coord );
            }
        }
    }

    const canvasElement = (
        <canvas
            style={{ width: "100%" }}
            onMouseDown={ mouseDownHandler }
            onMouseMove={ mouseMoveHandler }
            className={"w-full"}
            ref={canvasRef}
        ></canvas>
    );

    const fetchCamera = async ( camera_uuid: string ) => {
        try {
            const result = await axios.get( ApiUrl.camDetails( camera_uuid ) );
            let cam: Cam = new Cam();
            if ( result.data.camera ) {
                cam = result.data.camera;
            }
            setCamera( cam );
            setCamCoord( coords( cam ) ); //map center?
            setPanTo( coords( cam ) );
        }
        catch ( err: any ) {
            console.error("error loading camera info");
        }
        setCamLoaded( true );
    };

    const fetchBackground = async ( camera_uuid: string ) => {
        let img = "";
        if( params.traceId ) {
            let traceResp = await axios.get( ApiUrl.traceById( params.traceId ) );
            let trace: ITrace = traceResp.data.trace;
            img = trace.original_image_url;
        }
        else {
            let traces = await axios.get( ApiUrl.camTraces( camera_uuid, 1, 1 ) );
            img = traces.data.traces[0].original_image_url;
        }
        let bgImage = new Image();
        bgImage.src = img;
        await new Promise<void>( resolve => {
            bgImage.onload  = ( _e ) => { return resolve(); };
            bgImage.onerror = ( _e ) => { return resolve(); };
        } );
        setBackground( bgImage );
    };

    const fetchLane = async ( camera_uuid: string ) => {
        let resp = await axios.get( ApiUrl.lanesByCamId( camera_uuid ) );
        let lanes: Array<ILane> = resp.data.lanes;
        if( lanes.length === 0 ) {
            return;
        }

        //for now just get the last one and call it a day
        let desiredLane      = lanes.find( (el) => el.uuid == laneId )!;
        let newLineGeoCoords = desiredLane.gis_line.coordinates.map       ( (el) => { return { lng: el[0], lat: el[1] }; } );
        let newLineCoords    = desiredLane.image_to_gis_reference_line.map( (el) => { return { x: el[0], y: el[1] } }      );
        let newPolyCoords    = desiredLane.image_trigger_boundary.map     ( (el) => { return { x: el[0], y: el[1] } }      );

        //it should be loaded now!
        setLineGeoCoords( newLineGeoCoords );
        setLineCoords   ( newLineCoords    );
        setPolyCoords   ( newPolyCoords    );
        setLaneType     ( desiredLane.lane_type );
        setPolygonClosed( true ); //assume they closed it!
    }

    useEffect( () => {
        fetchCamera    ( camId! );
        fetchLane      ( camId! );
        fetchBackground( camId! );
    }, [camId] );

    useEffect( () =>  {
        if( !camera || !camera.deployment_uuid ) {
            return;
        }
        Api.getDeployment( camera.deployment_uuid ).then( r => r.json() )
                                                   .then( j => setDepl( j.deployment ) )
    }, [camera] );

    function drawLine( ctx: CanvasRenderingContext2D ) {
        ctx.beginPath();
        if ( lineCoords.length > 0 ) {                
            lineCoords?.forEach( ( coord, idx ) => {
                ctx.strokeStyle = "#FFEA31";
                ctx.fillStyle   = "#FFEA31";
                ctx.lineWidth   = 2;
                if( idx === curLineCoordInd ) {
                    ctx.fillStyle = "#0000FF"; //red!
                }
                if ( idx === 0 ) {
                    ctx.fillRect( coord.x - 8, coord.y - 8, 16, 16 );
                    ctx.stroke();
                    ctx.moveTo( coord.x, coord.y );
                } else {
                    ctx.lineTo( coord.x, coord.y );
                    ctx.fillRect( coord.x - 4, coord.y - 4, 8, 8 );
                    ctx.stroke();
                }
            } );
        }
    }

    function drawContextLanes( ctx: CanvasRenderingContext2D ) {
        if( !depl ) {
            return;
        }
        const camLanes = depl.lanes.filter( l => l.uuid !== laneId && l.camera_uuid === camId ); //get lanes for our camera
        for( let i = 0; i < camLanes.length; i++ ) {
            ctx.beginPath();
            const rdyToDraw = camLanes[i].image_trigger_boundary.map( el => { return { x: el[0], y: el[1] } } );
            rdyToDraw?.forEach( ( coord, idx ) => {
                if( idx === 0 ) {
                    ctx.moveTo( coord.x, coord.y );
                }
                ctx.lineTo( coord.x, coord.y );
                ctx.strokeStyle = "#000000";
                ctx.lineWidth   = 3;
                ctx.stroke();

                //outline it
                ctx.strokeStyle = '#FFFFFF';
                ctx.lineWidth = 2;
                ctx.stroke();
            } );
        }
    }

    function drawPoly( ctx: CanvasRenderingContext2D ) {
        if( polyCoords.length > 0 /* && hoverCoords.x >= 0 && hoverCoords.y >= 0 */ ) {
            ctx.beginPath();
            ctx.strokeStyle = "#FF00BD";
            ctx.fillStyle   = "#FF00BD";
            ctx.lineWidth   = 2;
            polyCoords?.forEach( ( coord, idx ) => {
                if( idx === 0 ) {
                    ctx.fillRect( coord.x - 8, coord.y - 8, 16, 16 );
                    ctx.stroke();
                    ctx.moveTo( coord.x, coord.y );
                }
                else {
                    ctx.lineTo( coord.x, coord.y );
                    ctx.fillRect( coord.x - 4, coord.y - 4, 8, 8 );
                    ctx.stroke();
                }
            } );

            if( polygonClosed ) {
                ctx.lineTo( polyCoords[0].x, polyCoords[0].y );
                ctx.stroke();
            }
            else {
                ctx.lineTo( hoverCoords.x, hoverCoords.y );
                ctx.stroke();
            } //draws an uncommitted line to wherever the mouse is            
        }
    }

    const drawImage = ( background: HTMLImageElement | undefined ) => {
        if( !background ) {
            return;
        }
        const canvas = canvasRef.current;
        if( canvas && canvas !== null ) {
            canvas.height = background.naturalHeight;
            canvas.width  = background.naturalWidth;
            const ctx = canvas.getContext( "2d" );
            if ( ctx ) {
                ctx.drawImage( background, 0, 0 );
                drawContextLanes( ctx );
                drawLine( ctx );
                drawPoly( ctx );
            }
        }
        return <></>; //this func doesn't emit markup
        //it just calls effectful functions on canvas
    };

    //tables of coordinates
    const coordRows = polyCoords.map( (c: IPnt) => {
        return <tr>
            <td className="w-auto text-end">{c.x}</td>
            <td className="w-auto text-end">{c.y}</td>
        </tr>;
    } );
    const polyCoordTable = (<table className="table table-striped table-compact">
        <thead>
            <tr>
                <td className="w-auto text-end">lane p.x</td>
                <td className="w-auto text-end">lane p.y</td>
            </tr>
        </thead>
        <tbody>{coordRows}</tbody>
    </table>);

    function mapMouseDownHandler( e: google.maps.MapMouseEvent ) {
        if( curLineCoordInd === -1 ) { return; }
        if( !e.latLng             ) { return; } 
        let tmp = [...lineGeoCoords];
        let cur = { ...tmp[curLineCoordInd] }
        cur.lng = e.latLng.lng();
        cur.lat = e.latLng.lat();
        tmp[curLineCoordInd] = cur;
        setLineGeoCoords( tmp );
        setCurLineCoordInd( -1 ); //so additional clicks won't set the point
    }

    const lineCoordRows = lineCoords.map( (c: IPnt, i) => {
        let active = "";
        if( curLineCoordInd === i ) {
            active = "table-active";
        }
        return <tr className={active}>
            <td className="w-auto text-end">{c.x}</td>
            <td className="w-auto text-end">{c.y}</td>
            <td className="w-auto text-end">{CoordUtil.toStr( lineGeoCoords[i]?.lat )}</td>
            <td className="w-auto text-end">{CoordUtil.toStr( lineGeoCoords[i]?.lng )}</td>
            <td className="w-auto text-end">
                <button className="btn btn-primary" onClick={() => setCurLineCoordInd( i )}>Set</button>
            </td>
        </tr>;
    } );
    const lineCoordTable = (
    <table className="table table-striped table-compact">
        <thead>
            <tr>
                <td className="w-auto text-end">line px</td>
                <td className="w-auto text-end">line py</td>
                <td className="w-auto text-end">line p.lat</td>
                <td className="w-auto text-end">line p.lng</td>
                <td className="w-auto text-end">actions</td>
            </tr>
        </thead>
        <tbody>
            {lineCoordRows}
        </tbody>
    </table>);

    let pins = lineGeoCoords.map( ( coord, index ) => {
        return <Marker
            key={`marker-${index}`}
            position={{ lat: coord.lat, lng: coord.lng }}
            draggable={true}
            onDragEnd={( e: google.maps.MapMouseEvent ) => {
                if ( !e.latLng ) {
                    return;
                }
                //this is bulky!
                let tmp = [...lineGeoCoords];
                let cur = { ...tmp[curLineCoordInd] }
                if ( !e.latLng ) { return }
                cur.lng = e.latLng.lng();
                cur.lat = e.latLng.lat();
                tmp[index] = cur;
                setLineGeoCoords( tmp );
                setCurLineCoordInd( -1 ); //so additional clicks won't set the point
            }}>
        </Marker>
    } );

    function labelMaker( s: string, i: number ) {
        return {
            color:     "white",
            text:      s,
            fontSize: "10px"
        };
    };

    let camPin = <Marker
            key={`marker-cam`}
            label={ labelMaker( "cam", 0 ) }            
            position={ camCoord! }
            draggable={ false }>
        </Marker>;

    function save() {
        let body: any = {};
        body.camera_uuid = camera?.uuid;
        body.lane_type   = laneType;
        body.vehicle_trigger_section = 'bottom'; //bottom for now

        //geo coords
        let lgcs = lineGeoCoords.map( coord => [coord.lng, coord.lat] );
        body.gis_line = { type: 'LineString', coordinates: lgcs }

        //ref line
        let lcs = lineCoords.map( pnt => [pnt.x, pnt.y] );
        body.image_to_gis_reference_line = lcs;

        //image trig boundary
        let pcs = polyCoords.map( pnt => [pnt.x, pnt.y] );
        body.image_trigger_boundary = pcs;

        console.log( "laneId: " + laneId );
        if( !laneId ) {
            Api.createLane( body ).then( resp => resp.json() )
                                  .then( json => {
                                    console.log( json );
                                    nav( "/cam/" + camId );
                                  } );
        }
        else {
            Api.updateLane( laneId, body ).then( resp => resp.json() )
                                  .then( json => {
                                    console.log( json );
                                    nav( "/cam/" + camId );
                                  } );
        }
    }

    const containerStyle = { width: '100%', height: '100%' };
    function PanTo() {
        const map = useGoogleMap();
        useEffect( () => {
            if ( !map ) {
                alert("no map found!");
                return;
            }
            if ( !panTo ) {
                return;
            }
            map.panTo( panTo );
            setPanTo( null );
            return;
        } );
        return <></>;
    }

    let polyLines: any = <></>;
    if( depl ) {
        polyLines = depl.lanes.map( ( l, i ) => {
            let gisCoords = l.gis_line.coordinates;
            let line: google.maps.LatLngLiteral[] = gisCoords.map( ( el ) => {
                return { lat: el[1], lng: el[0] }
            } );
            return <Polyline
                key={`marker-${i}`}
                path={line}
                options={{ strokeWeight: 5.0,
                           strokeColor:  l.uuid === laneId ? "#ffea31" : "#FF0000" }}
                draggable={false} />;
        } );
    }

    function laneTypeChanged( e: any ) {
        const val = e.target.value;
        setLaneType( val );
    }

    return (
        <div className="container-fluid mt-2">
            <div className="row">
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={() => { setDrawMode( "polygon" ); }}
                        disabled={drawMode === "polygon"}>
                        Draw Poly
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-primary"
                        onClick={() => { setDrawMode( "line" ); }}
                        disabled={drawMode === "line"}>
                        Draw Line
                    </button>
                    &nbsp;
                    <button className="btn btn-primary" onClick={() => { resetPoly(); }}>Reset Poly</button>
                    &nbsp;
                    <button className="btn btn-primary" onClick={() => { reset(); }}>Reset All</button>
                    &nbsp;
                    <button className="btn btn-warning" onClick={() => { save(); }}>Save Lane!</button>
                    &nbsp;
                    <select id="laneType" className="d-inline w-auto form-select" onChange={laneTypeChanged}>
                        {["curb_parking", "bike", "no_parking", "fire_hydrant", "no_standing", "double_parking"].map(
                            o => <option selected={o === laneType} value={o}>{o}</option> )}
                    </select>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    {canvasElement}
                    {drawImage( background )}
                </div>
                <div className="col" style={{ width: '640', height: '480' }}>
                    <VadeMap onClick={mapMouseDownHandler}
                             mapContainerStyle={containerStyle}
                             zoom={17}>
                        {pins}
                        <PanTo />
                        {polyLines}
                        {camPin}
                    </VadeMap>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        {polyCoordTable}</div>
                    <div className="col">
                        {lineCoordTable}</div>
                </div>
            </div>
        </div> );
}
