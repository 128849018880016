import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { useEffect, useState } from "react";
import { IStay } from "./Api/IStay";
import { IDeployment } from "./Api/Deployment";
import Api from "./Api/Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { ICamNew } from "./Api/ICam";
import { loadStaysFromJson, parseUtcToTz, trunc } from "./CamLaneSessionsPageUtil";
import { EventSourceInput } from "@fullcalendar/core";
import { Badge, Button } from "react-bootstrap";
import { CameraIcon } from "@heroicons/react/solid";
import ILane, { showLaneType } from "./Api/ILane";

export function getDomainColor( str: string ) {
    if( str === "ground"     ) { return "#964B00"; }
    if( str === "raw"        ) { return "#0d6efd"; }
    if( str === "helios"     ) { return "#AAAA00"; }
    if( str === "matchmaker" ) { return "#00AA00"; }
    return "black";
}

export function showDomain( domain: string ) {
    if( domain === "ground" ) { return "Ground Truth"; }
    if( domain === "raw"    ) { return "Raw"; }
    return "black";
}

export function CamCalendarPage() {
    const querystring = new URLSearchParams( window.location.search );
    let   initDate    = new Date();
    const qsDate      = querystring.get("date");
    if( qsDate ) {
        initDate = DateTime.fromISO( qsDate ).toJSDate();
    }
    const [start,       setStart] = useState<DateTime>   ( DateTime.fromJSDate( initDate ) );
    const [end,           setEnd] = useState<DateTime>   ( DateTime.fromJSDate( initDate ) );
    const [depl,     setDepl    ] = useState<IDeployment>();
    const [sessions, setSessions] = useState<IStay[]>    ( [] );
    const [cam,           setCam] = useState<ICamNew>    ();
    const [lanes,       setLanes] = useState<ILane[]>    ( [] );
    const params   = useParams();
    const camId    = params.camId!;
    const navigate = useNavigate();
    const loc      = useLocation();
    
    //load cam, deployment, then sessions...in that order!
    useEffect( () => {
        Api.getCam( camId ).then( resp => resp.json() )
                           .then( json => { 
                               let c: ICamNew = json.camera;
                               setCam( c );
                           } );
    }, [camId] );

    useEffect( () => {
        if( !cam ) { return; }
        Api.getDeployment( cam.deployment_uuid ).then( resp => resp.json() )
                                                .then( json => { 
                                                    let d: IDeployment = json.deployment;
                                                    setDepl( d );
                                                } );
    }, [params.camId, cam] );

    useEffect( () => {
        Api.getLanes( params.camId! ).then( resp => resp.json() )
                                     .then( json => {
                                         setLanes( json.lanes );
                                     } );
    }, [cam] );

    useEffect( () => {
        if( !depl ) { return; }
        const startOfDay = start.setZone( depl.info.tz, { keepLocalTime: true }).startOf("month").toUTC();
        const endOfDay   = end.setZone  ( depl.info.tz, { keepLocalTime: true }).endOf  ("month").toUTC();
        Api.getSessionsInDateRange( camId!, startOfDay, endOfDay ).then( resp => resp.json() )
                                                                  .then( json => {
                                                                      const stays = loadStaysFromJson( json.sessions );
                                                                      setSessions( stays );
                                                                   } );
    }, [depl?.info.tz, start, end, camId, depl] );

    if( !cam                ) { return <></>; }
    if( !depl               ) { return <></>; }
    if( lanes?.length === 0 ) { return <></>; }

    const tz = depl?.info.tz

    //Map<domain, Map<day, count>> 
    const evtMap = new Map<string, Map<string, number>>();
    for( const session of sessions ) {
        if( !evtMap.has( session.domain ) ) {
            evtMap.set( session.domain, new Map<string, number>() );
        }
        const domain     = evtMap.get( session.domain )!;
        const dayOfMonth = parseUtcToTz( tz, session.start_time.toISO() ).toFormat( "yyyy-MM-dd" );
        const get = domain.get( session.lane_uuid + "," + dayOfMonth ) ?? 0;
        domain.set( session.lane_uuid + "," + dayOfMonth, get + 1 );
    }
    const calEvents: EventSourceInput = [];
    evtMap.forEach( ( v: Map<string, number>, domain: string ) => {
        v.forEach( ( v: number, key: string ) => {
            const date = key.split( "," )[1];
            const lane = key.split( "," )[0];
            const laneType = lanes?.find( el => el.uuid === lane )?.lane_type;
            calEvents.push( {
                            title: `${lane.trim().slice(0, 4)} ${showLaneType( laneType! )} | ${v.toString()}`, 
                            date: date,
                            color: getDomainColor( domain ),
                            extendedProps: { laneId: lane, domain: domain }
                        } );
        } );
    } );

    const active = <Badge>{cam.is_active === false ? "Processing Disabled" : "Processing Enabled" }</Badge>;
    return <div className="p-1 cam-calendar-page">
        <div className="p-2">
            <Button className="w-100">{depl.name}</Button><br />
            <CameraIcon width={20} /> {cam.imei.toUpperCase()}<br />
            {active}<br />
            <Badge>Installed {DateTime.fromISO( cam.time_created ).toLocaleString()}</Badge>
        </div>
        <FullCalendar
            eventClassNames={"cam-calendar-page-small-text"}
            initialDate={start.toJSDate()}
            datesSet={ (dateInfo) => { 
                setStart( DateTime.fromJSDate( dateInfo.view.currentStart ) );
                setEnd( DateTime.fromJSDate  ( dateInfo.view.currentEnd   ) );
                const isoDate = DateTime.fromJSDate( dateInfo.view.currentStart ).toFormat( "yyyy-MM-dd" );
                navigate( loc.pathname + "?date=" + isoDate );
            }}
            dayHeaderFormat={{ weekday: "short" }}
            showNonCurrentDates={false}
            plugins={[ dayGridPlugin ]}
            events={calEvents}
            eventClick={ ( eventClickInfo ) => {
                const lane   = eventClickInfo.event.extendedProps.laneId;
                const domain = eventClickInfo.event.extendedProps.domain;
                const date   = DateTime.fromJSDate( eventClickInfo.event.start! ).toFormat( "yyyy-MM-dd" );
                navigate( `/cam/${cam.uuid}/sessions/${lane}/${domain}?date=${date}` );
             } }
            initialView="dayGridMonth" />
    </div>
}
