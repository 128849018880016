import {CameraJob, TimeFrame} from "./CVControllerMainTable";
import {CvRunPanelProgress} from "./CVControllerRunPanel";
import {Cam} from "../Api/Cam";

export interface CVDataForDay{
    date: Date
    camera_uuid: string
    traces?: number
    parking_vision_traces?: number
    stays?: number
    all_tasks?: number
    completed_tasks?: number
    domain?: string
}

export function getCurrWeekRange(){
    const currDate = new Date()
    let prevWeek = new Date()
    prevWeek.setDate(currDate.getDate() - 7)
    const weekRange = {
        from: {year: prevWeek.getFullYear(), month: prevWeek.getMonth() + 1, day: prevWeek.getDate()},
        to: {year: currDate.getFullYear(), month: currDate.getMonth() + 1, day: currDate.getDate()}
    }
    return weekRange
}

export function getDaysArray(start: Date, end: Date) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
};


export function isSameDay(day1: Date, day2: Date){
    return (day1.getFullYear() === day2.getFullYear()) && (day1.getDate() === day2.getDate()) && (day1.getMonth() === day2.getMonth())
}

export function calculateProgress(jobs: CameraJob[]){
    const parkingVisJobs = jobs.filter((job) => job.currJobTask = "parking-vision")
    const completedParkingVisionJobs =  parkingVisJobs.filter((job: CameraJob) => job.currJobProgress ?? 0 > 99)


    const prog: CvRunPanelProgress = {
        parkingVisProgress: completedParkingVisionJobs.length / parkingVisJobs.length,
        stayGenProgress: 0,
        taskGenProgress: 0,
        matchmakerProgress: 0
    }
    return prog
}

export function getTimelinesToBeRun(allJobs: CameraJob[]){
    let timeFrames: TimeFrame[] = []
    allJobs.forEach((cam) => {
        cam.timeFrames.forEach((timeframe) => {
            if (timeframe.selected){
                timeFrames.push(timeframe)
            }
        })
    })
    return timeFrames
}
