import {StayMatch} from "../interfaces/DebugInterfaces";
import {createRef, useEffect, useRef, useState} from "react";
import {cA} from "@fullcalendar/core/internal-common";
import Api from "../../Api/Api";
import ApiUrl from "../../Api/ApiUrl";
import {IObj} from "../../IVisionOutput";

export default function StayDebugCanvas(props: {stay: StayMatch, style?: any}){

    const [canvasRef] = useState<React.RefObject<HTMLCanvasElement>>( createRef<HTMLCanvasElement>() );
    const [background, setBackground] = useState<HTMLImageElement>();

    useEffect(() => {
        processImage()
    }, [])

    async function processImage() {
        let bgImage = new Image();
        const imageUrl = ApiUrl.traceImgOriginal(props.stay.start_trace)
        bgImage.src = imageUrl;
        await new Promise<void>( ( resolve ) => {
            bgImage.onload  = ( _e ) => { return resolve(); };
            bgImage.onerror = ( _e ) => { return resolve(); };
        } );
        setBackground( bgImage );
    }

    function setDrawStyle( ctx: CanvasRenderingContext2D,
                           fillColor: string = "black",
                           strokeWidth: number = 1,
                           strokeColor: string = "black",
                           alpha: number = 1.0 ) {
        ctx.fillStyle   = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth   = strokeWidth;
        ctx.globalAlpha = alpha;
    }

    function drawPoints( ps:   number[][],
                                ctx:  CanvasRenderingContext2D ) {
        if( ps.length === 0 ) {
            return;
        }
        ctx.beginPath();
        ctx.lineWidth = 3;
        ctx.moveTo( ps[0][0], ps[0][1] );
        for ( let i = 1; i < ps.length; i++ ) {
            ctx.lineTo( ps[i][0], ps[i][1] );
        }
        ctx.lineTo( ps[0][0], ps[0][1] );
        ctx.stroke();
    }

    function boundingBoxToPoints(boundingBox: number[][]): number[][] {
        const xMin = boundingBox[0][0]
        const yMax = boundingBox[0][1]
        const xMax = boundingBox[1][0]
        const yMin = boundingBox[1][1]
        return [
            [xMin, yMin ],
            [xMin, yMax ],
            [xMax, yMax ],
            [xMax, yMin ],
        ];
    }

    function drawBbox( stay: StayMatch, ctx: CanvasRenderingContext2D ) {
        let strokeColor = "yellow"
        if (stay.domain == "ground"){
            strokeColor = "green"
        }
        setDrawStyle( ctx, "white", 10, strokeColor, 0.9 );
        let pointsToDraw = stay.bbox
        if (pointsToDraw.length === 2){
            pointsToDraw = boundingBoxToPoints(stay.bbox)
        }
        drawPoints(pointsToDraw, ctx );
    }


    const drawImage = ( background: HTMLImageElement | undefined ) => {
        if ( !background ) {
            return;
        }
        const canvas = canvasRef.current;
        if ( !canvas ) {
            return;
        }

        canvas.height = background.naturalHeight;
        canvas.width  = background.naturalWidth;
        const ctx = canvas.getContext("2d", { alpha: false })
        if ( !ctx ) {
            return <></>;
        }
        ctx.drawImage( background, 0, 0 );
        drawBbox(props.stay, ctx );

        // return <></>; //this func doesn't emit markup
    };
    if (props.style){
        return <>
            <canvas ref={canvasRef} style={props.style}></canvas>
            {drawImage( background ) }
        </>;
    }
    return <>
        <canvas ref={canvasRef} style={{width: "100%"}}></canvas>
        {drawImage( background ) }
    </>;

}