
export interface IGetReidObjsResp {
    reid_objs: ReidObjInternal[];
}

export interface ReidObjInternal {
    trace_id:     string;
    obj_id:       number;
    reid_id:      string;
    bbox:         Array<number[]>;
    ground_plane: Array<number[]>;
    class_label:  string;
}

export interface ReidObj {
    traceId:     string;
    objId:       number;
    reidId:      string;
    bbox:        Array<number[]>;
    groundPlane: Array<number[]>;
    classLabel:  string;
}

export function ReidConv( src: ReidObjInternal ): ReidObj {
    return { 
        traceId:     src.trace_id,
        objId:       src.obj_id,
        reidId:      src.reid_id,
        bbox:        src.bbox,
        groundPlane: src.ground_plane,
        classLabel:  src.class_label,        
    };
}