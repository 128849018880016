//react
import { useEffect, useState } from "react";
import { useParams  }          from   "react-router";
import { Link       }          from   "react-router-dom";

//vade stuff
import DateUtil                from "./Util/DateUtil";
import Api                     from "./Api/Api";
import { ITrace     }          from "./Api/ITrace";
import { ICam       }          from "./Api/ICam";
import { Cam        }          from "./Api/Cam";
import ILane                   from "./Api/ILane";
import ImageGallery            from "./ImageGallery";
import { Button } from "react-bootstrap";

export default function CamViewPage() {
    //state mgmt
    const [cam,    setCam   ] = useState<ICam>( new Cam() );
    const [traces, setTraces] = useState<Array<ITrace>>( [] );
    const [lanes,  setLanes ] = useState<Array<ILane>>( [] );

    //routing
    const params = useParams();

    //hooks
    useEffect( () => {
        Api.getCam( params.camId! ).then( resp => resp.json() )
                                   .then( json => { setCam( json.camera ); } );
        
        Api.getTraces( params.camId!, 1, 100 ).then( resp => resp.json() )
                                              .then( json => setTraces( json.traces ) );

        Api.getLanes( params.camId! ).then( resp => resp.json() )
                                     .then( json => setLanes( json.lanes ) );
    }, [params.camId] );

    //everything else
    interface CamViewProps { camId: string; }
    function CamViewDetails( props: CamViewProps ) {
        const imgs = traces.map( trace => {
            let dateLabel = DateUtil.toSmallDateTimeStr( new Date( trace.time_captured ) );
            return { original:       trace.original_image_url, 
                     thumbnail:      trace.thumbnail_image_url,
                     thumbnailLabel: dateLabel,
                     description:    dateLabel }
        } );

        //only show image gallery when there are traces        
        let imageGallery = <h4>No Images Have Been Receieved Yet!</h4>;
        if( traces.length != 0 ) {
            imageGallery = (
                <div className="">
                    <ImageGallery
                        style={{ width: "100%" }}
                        items={imgs} />
                </div>);
        }

        let deplLnk = "/cam/" + cam.uuid + "/edit";
        let deplTxt = "Not Deployed";
        if( cam.deployment_uuid ) {
            deplLnk = `/dep/${cam.deployment_uuid}`;
            deplTxt = "View Deployment";
        }

        let laneBtns = lanes.map( (l, i) => { 
            return <Link to={"/cam/" + cam.uuid + "/viscfg/" + l.uuid} >
                <button className="w-auto m-1 btn btn-primary">{l.lane_type} Lane {i}</button>
            </Link>
        } );

        let laneSesBtns = lanes.map( (l, i) => { 
            return <Link to={"/cam/" + cam.uuid + "/sessions/" + l.uuid} >
                <button className="w-auto m-1 btn btn-primary">{l.lane_type} Lane {i}</button>
            </Link>
        } );

        return <div className="container-fluid">
            <div className="row">
                <div className="">
                    <h3>Camera Status</h3>
                    <table className="table table-striped table-sm w-50">
                        <thead>
                            <tr>
                                <td>
                                    <Link to={`/cam/${cam.uuid}/edit`}>
                                        <button className="btn btn-primary">Edit Camera</button>
                                    </Link>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>IMEI</td>
                                <td>
                                    {cam.imei}
                                </td>
                            </tr>
                            <tr>
                                <td>Is Active</td>
                                <td>
                                    <input readOnly type="checkbox" checked={!!cam.is_active} />
                                </td>
                            </tr>
                            <tr>
                                <td>Deployment</td>
                                <td>
                                    <Link to={deplLnk} >
                                        <button className="btn m-1 btn-primary">{deplTxt}</button>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <Link to={"/cam/" + cam.uuid + "/cal/"} >
                                        <button className="w-auto m-1 btn btn-primary">View Calendar</button>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Lane Setup
                                </td>
                                <td>
                                    {laneBtns}
                                    <Link to={"/cam/" + cam.uuid + "/viscfg/"} >
                                        <button className="w-auto m-1 btn btn-primary">Add</button>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>Sessions</td>
                                <td>
                                    {laneSesBtns}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row" style={{maxWidth: "640px"}}>
                    {imageGallery}
                </div>
            </div>
        </div>;
    }
    return <CamViewDetails camId={params.camId!} />;
}