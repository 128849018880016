import TaskCheckerImageViewer from "./TaskCheckerImageViewer";
import {useEffect, useState} from "react";
import {ITrace} from "../Api/ITrace";
import {Alert, Col, Container, Row, Stack} from "react-bootstrap";
import {ITraceWFrameNum, loadTracesFromJson} from "../CamLaneSessionsPageUtil";
import {ICam} from "../Api/ICam";
import {SelectOption, Question} from "./taskCheckerInterfaces";
import * as helpers from "./TaskCheckerHelpers";
import ILane from "../Api/ILane";
import { useHotkeys } from 'react-hotkeys-hook'
import Select from 'react-select';


export interface TaskCheckerMainViewProps{
    camera: ICam
    day: Date
    camLanes: ILane[]
}


export default function TaskCheckerMainView(props: TaskCheckerMainViewProps) {
    const camera = props.camera
    const camLanes = props.camLanes
    const day = props.day
    const [traces, setTraces] = useState<ITraceWFrameNum[] | undefined>(undefined)
    const [traceInd, setTraceInd] = useState<number>(0)
    const [allTasks, setAllTasks] = useState<Question[]>( [] );
    const taskDomains = helpers.taskDomains(allTasks)
    const [selectedTaskDomain, setSelectedTaskDomain] = useState<SelectOption | null>(null);
    const [selectedTaskType, setSelectedTaskType] = useState<SelectOption>(helpers.taskTypeOptions[0])
    const filteredTasks = allTasks.filter((task) => {
        const isSameDomain = selectedTaskDomain ? task.domain === selectedTaskDomain.value : false
        let isSameType = true
        if (selectedTaskType.value != "all"){
            isSameType = selectedTaskType.value === task.question_type
        }
        return isSameDomain && isSameType
    })
    const [tz, setTz] = useState<string>( "America/New_York" );


    useHotkeys(['ArrowRight', 'shift+ArrowRight'], (event: KeyboardEvent) => {
        if (event.shiftKey) updateTraceIndex(5)
        if (!event.shiftKey) updateTraceIndex(1)
    }, [traceInd, traces])

    useHotkeys(['ArrowLeft', 'shift+ArrowLeft'], (event: KeyboardEvent) => {
        if (event.shiftKey) updateTraceIndex(-5)
        if (!event.shiftKey) updateTraceIndex(-1)
    }, [traceInd, traces])

    useEffect( () => {
        helpers.loadTraces(setTraces, camera.uuid, day)
        let laneIds: string[] = camLanes.map((lane) => lane.uuid)
        helpers.loadQuestions(setAllTasks, laneIds, day)
    }, [camera, day] );

    useEffect( () => {
        if (taskDomains.length > 0){
            setSelectedTaskDomain(taskDomains[0])
        }
    }, [allTasks] );

    function updateTraceIndex(amount: number){
        if (!traces) return
        let newTraceIndex = traceInd + amount
        if (newTraceIndex < 0){
            newTraceIndex = 0
        }
        if(newTraceIndex >= traces.length){
            newTraceIndex = traces.length - 1
        }
        setTraceInd(newTraceIndex)
    }

    if(!traces){
        return <h3>Loading traces...</h3>
    }
    if (traces.length === 0){
        return <h3>NO TRACES</h3>
    }
    if (traceInd >= traces.length){
        console.log("Fucked index")
        updateTraceIndex(1)
        return <h3>Updating trace index...</h3>
    }

    return <Container  >
        { (allTasks.length === 0) ? <Alert style={{minWidth: "500px"}} variant={"warning"}> <Alert.Heading> No Tasks found on this Day </Alert.Heading> </Alert>  : ""}
        <Container style={{minWidth: "1000px"}}>
            <Stack direction="horizontal" gap={3}>
                <Select
                    placeholder={"domain"}
                    value={selectedTaskDomain}
                    onChange={(e: any) => setSelectedTaskDomain(e)}
                    options={taskDomains}
                    isMulti={false}
                />
                <Select
                    value={selectedTaskType}
                    onChange={(e: any) => setSelectedTaskType(e)}
                    options={helpers.taskTypeOptions}
                    isMulti={false}
                />
                <h4>{`Viewing ${filteredTasks.length} tasks`}</h4>
            </Stack>
        </Container>
        <TaskCheckerImageViewer ind={traceInd} traces={traces} tasks={filteredTasks}/>
    </Container>


}