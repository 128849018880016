
import { DateTime } from "luxon";
import ApiUrl     from "./ApiUrl";
import Deployment from "./Deployment";
import { INewStay, IStayOpts } from "./IStay";
import {ITrace} from "./ITrace";
import {Cam} from "./Cam";

export default class Api {    

    //cams
    static getCams( pgNum: number, pgSize: number ) {
        return fetch( ApiUrl.camList( pgNum, pgSize ) );
    }
    
    static getCam( uuid: string ) {
        return fetch( ApiUrl.camDetails( uuid ) );
    }

    static createCam( values: any ) {
        values.tags = [];
        values.computer_vision_config = {};
        return fetch( ApiUrl.camCreate(), {
            method : 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( values ) }
        );
    }

    static updateCam( values: any ) {
        values.tags = [];
        values.computer_vision_config = {};
        return fetch( ApiUrl.camUpdate(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( values ) }
        );
    }

    //lanes
    static createLane( createLnObj: any ) {
        return fetch( ApiUrl.createLane(), {
            method : 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( createLnObj ) }
        );
    }

    static getLanes( camId: string ) {
        return fetch( ApiUrl.lanesByCamId( camId ) );
    }

    static updateLane( laneId: string, values: any ) {
        values.uuid = laneId;
        return fetch( ApiUrl.laneUpdate(), {
            method : 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( values ) }
        );
    }

    static updateCurbZone( curbZoneId: string, values: any ) {
        values.curb_zone_id = curbZoneId;
        return fetch( ApiUrl.curbZoneUpdate(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( values ) }
        );
    }

    //traces
    static getTraces( uuid: string, start: number, cnt: number ) { 
        return fetch( ApiUrl.camTraces( uuid, start, cnt ) );
    }

    static getTrace( uuid: string ) {
        return fetch( ApiUrl.traceById( uuid ) );
    }

    static getTracesByTime( camId: string, startDate: DateTime, endDate: DateTime ) {
        let payload = { camera_uuid: camId,
                        start:       startDate.toJSON(), 
                        end:         endDate.toJSON() };

        return fetch( ApiUrl.tracesByTime(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    //depls
    static getDeployments() {
        return fetch( ApiUrl.deployments( 1, 2000 ) );
    }

    static getDeployment( deplId: string ) {
        return fetch( ApiUrl.deploymentDetails( deplId ) );
    }

    static updateDeployment( depl: Deployment ) {
        return fetch( ApiUrl.deploymentUpdate(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( depl ) }
        );
    }
    
    //sessions
    static getSession( vehicleId: string ) {
        return fetch( ApiUrl.session( vehicleId ) );
    }

    static getSessionsInDateRange( camId: string, startDate: DateTime, endDate: DateTime ) {
        let payload = { camera_uuid: camId,
                        start:       startDate.toJSON(), 
                        end:         endDate.toJSON() };
        return fetch( ApiUrl.sessionsInDateRange(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static getSessionsForTraces( traces: Array<string> ) {
        let payload = { trace_ids: traces };
        return fetch( ApiUrl.sessionIntersectingTraces(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static updateSession( vehicleId: number, values: IStayOpts ) {
        const payload = { vehicle_id: vehicleId, ...values };
        return fetch( ApiUrl.updateSession(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static setSessionValidation( sessionId: number, valid: boolean ) {
        let payload = { vehicle_uuid: sessionId, validated: valid };
        return fetch( ApiUrl.sessionSetValidation(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static updateStartTrace( sessionId: number, traceId: string ) {
        let payload = { vehicle_id: sessionId, session_start: traceId };
        return fetch( ApiUrl.sessionUpdateStartTrace(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static updateEndTrace( sessionId: number, traceId: string ) {
        let payload = { vehicle_id: sessionId, session_end: traceId };
        return fetch( ApiUrl.sessionUpdateEndTrace(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static deleteSession( sessionId: number ) {
        let payload = { vehicle_uuid: sessionId };
        return fetch( ApiUrl.deleteSession(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static createSession( newSession: INewStay ) {
        let payload = newSession;
        return fetch( ApiUrl.createSession(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static getReidObjsByTime( camId: string, startDate: DateTime, endDate: DateTime ) {
        let payload = { camera_uuid: camId,
                        start:       startDate.toJSON(), 
                        end:         endDate.toJSON() };

        return fetch( ApiUrl.reidObjsByTime(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }
    
    static getGroundTruthByTime( camId: string, startDate: DateTime, endDate: DateTime ) {
        let payload = { camera_uuid: camId,
                        start:       startDate.toJSON(), 
                        end:         endDate.toJSON() };

        return fetch( ApiUrl.groundTruthByTime(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static getCVStatsByCameras( camIds: string[], startDate: Date, endDate: Date, source: "vis" | "stays" | "tasks" | "traces" ) {
        let payload = {
            camera_uuids: camIds,
            start_date:   startDate.toJSON(),
            end_date:     endDate.toJSON(),
            source:       source
        };

        return fetch( ApiUrl.cvProcessingDataByTime(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static runParkingVision( trace: ITrace, cam: Cam) {
        let payload = {
            trace: JSON.stringify(trace),
            camera: JSON.stringify(cam),
        };

        return fetch( ApiUrl.runParkingVision(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static runStayGenerator(laneIds: string[], day: Date, domain: string, force_delete_questions: boolean = false){
        let payload = {
            day: day.toISOString(),
            lane_uuids: laneIds,
            domain: domain,
            force_delete_questions: force_delete_questions
        };

        return fetch( ApiUrl.runStayGen(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static runTaskGenerator(laneIds: string[], day: Date, domain: string, ommit_single_frame_stays: boolean = false, only_single_frame_stays: boolean = false, delete_previous: boolean = true){
        let payload = {
            day: day.toISOString(),
            lane_uuids: laneIds,
            domain: domain,
            ommit_single_frame_stays: ommit_single_frame_stays,
            only_single_frame_stays: only_single_frame_stays,
            delete_previous: delete_previous
        };

        return fetch( ApiUrl.runTaskGen(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static runMatchmaker(laneIds: string[], day: Date, domain: string, out_domain: string, add_default_starts: boolean = false, add_default_ends: boolean = false ){
        let payload = {
            day: day.toISOString(),
            lane_ids: laneIds,
            domain: domain,
            out_domain: out_domain,
            add_default_starts: add_default_starts,
            add_default_ends: add_default_ends
        };

        return fetch( ApiUrl.runMatchmaker(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static getQuestionsForLaneAndDay(lane_uuids: string[], day: Date, domain: string = "all") {
        let payload = {
            lane_uuids: lane_uuids,
            day: day.toISOString(),
            domain: domain
        };
        return fetch( ApiUrl.getQuestionsByLaneAndDay(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }

    static getAccuracyForLaneAndDay(lane_uuids: string[], startDay: Date, endDay:Date, domain: string, getDebug=false) {
        let payload = {
            lane_uuids: lane_uuids,
            start_day: startDay.toISOString(),
            end_day: endDay.toISOString(),
            domain: domain,
            debug: getDebug
        };
        return fetch( ApiUrl.getAccuracyByLaneAndDay(), {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( payload ) }
        );
    }
}

