import { ICoord       } from "./ICoord";
import { IGisLine } from "./IGisLine";

export default interface ILane {
    uuid:                        string;
    camera_uuid:                 string;
    lane_type:                   LaneType;
    gis_line:                    IGisLine;
    image_to_gis_reference_line: Array<ICoord>;
    image_trigger_boundary:      Array<Array<number>>;
    vehicle_trigger_section:     string;
    time_created:                string;
    curb_zone_id:                string;
}

export enum LaneType {
    CurbParking   = "curb_parking",
    Bike          = "bike",
    NoParking     = "no_parking",
    FireHydrant   = "fire_hydrant",
    NoStanding    = "no_standing",
    DoubleParking = "double_parking"
}

export function showLaneType( laneType: LaneType ) {
    if( laneType === LaneType.CurbParking   ) { return "Curb";           }
    if( laneType === LaneType.Bike          ) { return "Bike";           }
    if( laneType === LaneType.NoParking     ) { return "No Parking";     }
    if( laneType === LaneType.FireHydrant   ) { return "Fire Hydrant";   }
    if( laneType === LaneType.NoStanding    ) { return "No Standing";    }
    if( laneType === LaneType.DoubleParking ) { return "Double Parking"; }
    return "Err!";
}