import Api from "../Api/Api";
import {IDeployment} from "../Api/Deployment";
import {ICam} from "../Api/ICam";
import {da} from "@fullcalendar/core/internal-common";
import {DateTime} from "luxon";
import {start} from "repl";
import {ITrace} from "../Api/ITrace";
import {loadTracesFromJson} from "../CamLaneSessionsPageUtil";
import {SelectOption, Question} from "./taskCheckerInterfaces";


export async function loadDeployments(setFunc: any){
    const req = await Api.getDeployments()
    const reqJson = await req.json()
    const depls: IDeployment[] = reqJson.deployments
    setFunc(depls)
}


export async function loadQuestions(setFunc: any, laneIds: string[], day: Date){
    const req = await Api.getQuestionsForLaneAndDay(laneIds, day)
    const reqJson = await req.json()
    const questions: Question[] = reqJson.questions
    setFunc(questions)
}

export async function loadTraces(setFunc: any, cameraId: string, day: Date){
    const tz = "America/New_York"
    // const dayStartLocal:Date = new Date(day.toLocaleString("en-US", {timeZone: tz}))
    const dayStart: DateTime = DateTime.fromJSDate(day, {zone: tz}).set({hour: 0, minute:0, second: 0})
    const dayEnd: DateTime = DateTime.fromJSDate(day, {zone: tz}).set({hour: 23, minute:59, second: 59})
    const req = await Api.getTracesByTime(cameraId, dayStart, dayEnd)
    const reqJson = await req.json()
    const newTraces = loadTracesFromJson( tz, reqJson );
    setFunc( newTraces );
    // const first = newTraces.findIndex( t => sameDay( t ) );
    // if( first !== -1 ) {
    //     setInd( first );
    // }
}

export function taskDomains(tasks: Question[]){
    let taskDomains: Set<string> = new Set()
    tasks.map((task) => taskDomains.add(task.domain))
    let taskDomainsArr = Array.from(taskDomains)
    let optionArr = taskDomainsArr.map((domain) => {return { value: domain, label: domain }})
    return optionArr
}

export const taskTypeOptions: SelectOption[] = [
    {value: "all", label: "all"},
    {value: "start", label: "start"},
    {value: "end", label: "end"},
]