import { DateTime } from "luxon";
import { IPnt } from "./Api/IPnt";
import { IStay, IStayRaw } from "./Api/IStay";
import { ITrace } from "./Api/ITrace";

export function processStay( el: IStayRaw ): IStay {
    const res: IStay = { ...el,
        start_time: parseUtc( el.start_time ),
        end_time:   parseUtc( el.end_time   ) };
    return res;
}

//convert the server UTC times to local time and sort them
export function loadStaysFromJson( sessions: IStayRaw[] ): IStay[] {
    const dateSessions = sessions.map( processStay );
    const laneSessions = dateSessions;
    laneSessions.sort( (a, b) =>
        a.start_time.valueOf() > b.start_time.valueOf() ? -1 : 1 ).reverse();
    return laneSessions;
}

export function loadTracesFromJson( tz: string, json: any ): Array<ITraceWFrameNum> {
    let jsonTraces: Array<ITrace> = json.traces;
    let newTraces: Array<ITraceWFrameNum> = jsonTraces.map( (trace: ITrace, i: number) => {
        const utc  = parseUtc( trace.time_captured );
        const loc  = parseUtcToTz( tz, trace.time_captured );
        const fst  = loc.toFormat( "yyyy LLL dd h:mm" );
        const snd  = loc.toFormat( "a" ).toLowerCase()
        const desc = fst + snd + " EST " + trunc( trace.trace_id );
        return { traceId:           trace.trace_id,
                 imgUrl:            trace.original_image_url,
                 thumbImgUrl:       trace.thumbnail_image_url,
                 timeCaptured:      utc,
                 timeCapturedLocal: loc,
                 frameNum:          i,
                 markup:            desc };
    } );
    return newTraces;
}

export interface ITraceWFrameNum {
    traceId:           string;
    imgUrl:            string;
    thumbImgUrl:       string;
    timeCaptured:      DateTime;
    timeCapturedLocal: DateTime;
    frameNum:          number;
    markup:            string;
}

export function trunc( str: string ) {
    return str.slice( 0, 8 );
}

export function displayId( id: number ) {
    const s = id.toString();
    return s.toString().slice( s.length - 3, s.length );
}

//duplicated in two places, need to figure out best place for it soon
export function coordsFromCam( cam: any ) {
    let cs = cam?.current_installation?.location?.coordinates;
    let ret = { lat: 0.0, lng: 0.0 };
    if( !cs ) { return ret; }
    ret = { lat: cs[1], lng: cs[0] };
    return ret;
}

export function coordsFromArray( coords: any ) {
    let cs = coords;
    let ret = { lat: 0.0, lng: 0.0 };
    if( !cs ) { return ret; }
    ret = { lat: cs[1], lng: cs[0] };
    return ret;
}

//traces must be sorted!
export function getTraceByOffset( traces: ITraceWFrameNum[], 
                                  traceId: string, 
                                  offset: number ): ITraceWFrameNum | undefined {
    const ind    = traces.findIndex( el => el.traceId === traceId );
    const target = Math.min( Math.max( 0, ind + offset ), traces.length - 1 );
    return traces.at( target );
}

export function parseUtc( dateStr: string ): DateTime {
    let parsedDate: DateTime = DateTime.fromISO( dateStr, { zone: 'utc' } );
    if( !parsedDate.isValid ) {
        parsedDate = DateTime.fromFormat( dateStr, "yyyy-MM-dd HH:mm:ss", { zone: 'utc' } );
    }
    return parsedDate;
}

export function parseUtcToTz( tz: string, dateStr: string ): DateTime {
    console.log(dateStr)
    if (dateStr === null){
        return DateTime.fromSeconds(1)
    }
    let parsedDate: DateTime = DateTime.fromISO( dateStr, { zone: 'utc' } );
    if( !parsedDate.isValid ) {
        parsedDate = DateTime.fromFormat( dateStr, "yyyy-MM-dd HH:mm:ss", { zone: 'utc' } );
    }
    return parsedDate.setZone( tz );
}

export function getVehicleSquare( pnt: IPnt ): number[][] {
    const rectPoints: number[][] = [];
    rectPoints.push( [pnt.x - 15, pnt.y - 15] );
    rectPoints.push( [pnt.x + 15, pnt.y - 15] );
    rectPoints.push( [pnt.x + 15, pnt.y + 15] );
    rectPoints.push( [pnt.x - 15, pnt.y + 15] );
    return rectPoints;
}