import { ICam } from "./ICam";

export class Cam implements ICam {
    uuid: string = "";
    imei: string = "";
    name: string = "";
    is_active: string = "";
    current_installation: any;
    tags: string = "";
    deployment_uuid: string = "";
}
