
//react
import { Navbar, Container, Nav }       from 'react-bootstrap';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
//google maps
import { LoadScript } from '@react-google-maps/api';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import '@grapecity/wijmo.styles/themes/wijmo.theme.material.css';

// import 'react-day-picker/dist/style.css';


//our pages
import CamListPage         from './CamListPage'
import CamEditPage         from './CamEditPage';
import CamViewPage         from './CamViewPage';
import CamVisionPage       from './CamVisionPage';
import DeploymentListPage  from './DeploymentListPage';
import DeploymentEditPage  from './DeploymentEditPage';
import DeploymentViewPage  from './DeploymentViewPage';
import CamLaneSessionsPage from './CamLaneSessionsPage';
import CurbZoneListPage    from './CurbZoneListPage';
import CvDebuggerPage      from './CvDebuggerPage';
import { CamCalendarPage } from "./CamCalendarPage";
import CvStayDebuggerPage from "./CvStayDebuggerPage";
import CVControllerMain from "./CVControllerComponents/CVControllerMain";
import TaskCheckerPage from "./TaskChecker/TaskCheckerPage";
import AccuracyPageMain from "./AccuracyPageComponents/AccuracyPageMain";

function Menu() {
    return <Navbar bg="light" expand="sm" style={{ maxHeight: "8vh" }}>
        <Container>
            <Navbar.Brand href="/">
                <img src="/img/vade.svg" style={{ marginBottom: "5px" }} height="15" alt="VADE" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto mx-3">
                    <Nav.Link href="/dep/">Deployments</Nav.Link>
                    <Nav.Link href="/cam/unreg/">Register Camera</Nav.Link>
                    <Nav.Link href="/cam/">All Cameras</Nav.Link>
                    <Nav.Link href="/cvmaster/">CV Controller</Nav.Link>
                    <Nav.Link href="/taskChecker/">Task Checker</Nav.Link>
                    <Nav.Link href="/accuracy/">Accuracy</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>;
}

const libs: any = ["drawing", "places"];
function App() {
    return <>
        <Menu />
        <LoadScript googleMapsApiKey="AIzaSyAcdlV9hsHL9qllzRgttb0-1JGsaJt6-Rw" libraries={libs}>
            <BrowserRouter>
                <Routes>
                    <Route path="/"                                    element={<DeploymentListPage  />} />
                    <Route path="/cam"                                 element={<CamListPage         />} />
                    <Route path="/cam/unreg"                           element={<CamListPage         />} />
                    <Route path="/cam/:camId/"                         element={<CamViewPage         />} />
                    <Route path="/cam/:camId/cal"                      element={<CamCalendarPage     />} />
                    <Route path="/cam/:camId/edit"                     element={<CamEditPage         />} />
                    <Route path="/cam/:camId/viscfg"                   element={<CamVisionPage       />} />
                    <Route path="/cam/:camId/viscfg/:laneId"           element={<CamVisionPage       />} />
                    <Route path="/cam/:camId/viscfg/:laneId/:traceId"  element={<CamVisionPage       />} />
                    <Route path="/cam/:camId/sessions/:laneId/:domain" element={<CamLaneSessionsPage />} />
                    <Route path="/cam/:camId/acc/:laneId"              element={<CvDebuggerPage      />} />
                    <Route path="/cam/:camId/accuracy/:laneId/:domain" element={<CvStayDebuggerPage  />} />
                    <Route path="/dep/"                                element={<DeploymentListPage  />} />
                    <Route path="/dep/:depId"                          element={<DeploymentViewPage  />} />
                    <Route path="/dep/:depId/cams"                     element={<DeploymentViewPage  />} />
                    <Route path="/dep/:depId/edit"                     element={<DeploymentEditPage  />} />
                    <Route path="/dep/:depId/cam"                      element={<CamListPage         />} />
                    <Route path="/dep/:depId/zones"                    element={<CurbZoneListPage    />} />
                    <Route path="/taskChecker"                         element={<TaskCheckerPage         />} />
                    <Route path="/cvmaster"                            element={<CVControllerMain    />} />
                    <Route path="/accuracy"                            element={<AccuracyPageMain    />} />
                </Routes>
            </BrowserRouter>
        </LoadScript>
    </>;
}

export default App;
