import { DateTime } from "luxon";

export default class DateUtil {

    static local( date: Date, tz: string ) {
        return DateTime.fromJSDate( date, { zone: tz } );
    }

    static readonly tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    static hoursMinutes( start: DateTime, end: DateTime ): string {
        //end > start
        const diff = end.diff( start, ["days", "hours", "minutes"] );
        const days    = diff.days;
        const hours   = diff.hours;
        const minutes = diff.minutes;
        let res = "";
        if( days != 0 ) {
            res += days + "d ";
        }
        if( hours != 0 ) {
            res += hours + "h ";
        } //round is needed for the smallest unit in luxon
        res += Math.round(minutes) + "m ";
        return res;
    }

    static toSmallDateStr( date: Date ): string { 
        return date.toLocaleDateString('en-US');
    }
    static toHoursMinutesAmPmStr( date: Date ): string {
        return date.toLocaleTimeString("en-US").replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3").replace( " AM", "am" ).replace(" PM", "pm");
    }    
    static toHoursMinutesSecsAmPmStr( date: Date ): string { 
        return date.toLocaleTimeString();
    }
    static toSmallDateTimeStr( date: Date ): string { 
        return this.toSmallDateStr( date ) + " " + this.toHoursMinutesAmPmStr( date );
    }
}
