import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface HelpModalProps {
    showHelp: boolean;
    setShowHelp: React.Dispatch<React.SetStateAction<boolean>>;
}

export function HelpModal( props: HelpModalProps ) {
    const handleClose = () => props.setShowHelp( false );
    const handleShow  = () => props.setShowHelp( true  );

    return <>
        <Button variant="primary" size="sm" onClick={handleShow}>
            Help
        </Button>
        <Modal show={props.showHelp} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Session Editor Help</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Keyboard Shortcuts</h5>
                <div className="container-fluid font-monospace">
                    <div className="row">
                    <div className="col">
                            <table className="table" style={{ fontSize: ".8em" }}>
                                <tbody>
                                    <tr>
                                        <td>a</td>
                                        <td>go to first frame of day</td>
                                    </tr>
                                    <tr>
                                        <td>z</td>
                                        <td>go to last frame of day</td>
                                    </tr>
                                    <tr>
                                        <td>◀</td>
                                        <td>step back one frame</td>
                                    </tr>
                                    <tr>
                                        <td>▶</td>
                                        <td>step forward one frame</td>
                                    </tr>
                                    <tr>
                                        <td>shift ◀</td>
                                        <td>step back five frames</td>
                                    </tr>
                                    <tr>
                                        <td>shift ▶</td>
                                        <td>step forward five frames</td>
                                    </tr>
                                    <tr>
                                        <td>shift - (minus)</td>
                                        <td>reduce font size</td>
                                    </tr>
                                    <tr>
                                        <td>shift = (equals)</td>
                                        <td>increase font size</td>
                                    </tr>
                                    <tr>
                                        <td>c</td>
                                        <td>enter create mode</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <table className="table" style={{ fontSize: ".8em" }}>
                                <tbody>
                                    <tr>
                                        <td>q</td>
                                        <td>go to the frame before session</td>
                                    </tr>
                                    <tr>
                                        <td>w</td>
                                        <td>go to the first frame of session</td>
                                    </tr>
                                    <tr>
                                        <td>e</td>
                                        <td>go to the last frame of session</td>
                                    </tr>
                                    <tr>
                                        <td>r</td>
                                        <td>go to the frame after session</td>
                                    </tr>
                                    <tr>
                                        <td>, (comma)</td>
                                        <td>load prev session</td>
                                    </tr>
                                    <tr>
                                        <td>. (period)</td>
                                        <td>load next session</td>
                                    </tr>
                                    <tr>
                                        <td>[</td>
                                        <td>set session start</td>
                                    </tr>
                                    <tr>
                                        <td>]</td>
                                        <td>set session end</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <img src="/img/keys.png" width="100%" />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}