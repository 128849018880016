
export default class ApiUrl {

    static detectServ( url: string ): string {
        if( url.includes( "localhost" ) ) {
           // return "http://localhost:3011/vade_crud_api";
            return 'https://us-central1-vade-prod.cloudfunctions.net/vade_crud_api';
        }
        if( url.includes( "curb-config.vade.ai" ) || url.includes( "vade-prod.web.app/" ) || (url.includes("tempprod"))) {
            return 'https://us-central1-vade-prod.cloudfunctions.net/vade_crud_api';
        }
        return 'https://us-central1-vade-dev-342822.cloudfunctions.net/vade_crud_api';
    }

    static detectImageServ( url: string ): string {
        if( url.includes( "localhost" ) ) {
            return 'https://storage.googleapis.com/trace_images_prod';
        }
        if( url.includes( "curb-config.vade.ai" ) || url.includes( "vade-prod.web.app/" ) || (url.includes("tempprod"))) {
            return 'https://storage.googleapis.com/trace_images_prod';
        }
        return 'https://storage.googleapis.com/trace_images';
    }

    static detectStayGenServ( url: string ): string {
        if( url.includes( "localhost" ) ) {
            return 'https://us-central1-vade-prod.cloudfunctions.net/stay_generator';
        }
        if( url.includes( "curb-config.vade.ai" ) || url.includes( "vade-prod.web.app/" ) || (url.includes("tempprod"))) {
            return 'https://us-central1-vade-prod.cloudfunctions.net/stay_generator';
        }
        return 'https://us-central1-vade-dev-342822.cloudfunctions.net/stay_generator';
    }

    static detectParkingVisServ( url: string ): string {
        if( url.includes( "localhost" ) ) {
            return 'https://us-central1-vade-prod.cloudfunctions.net/parking_vision';
        }
        if( url.includes( "curb-config.vade.ai" ) || url.includes( "vade-prod.web.app/" ) || (url.includes("tempprod"))) {
            return 'https://us-central1-vade-prod.cloudfunctions.net/parking_vision';
        }
        return 'https://us-central1-vade-dev-342822.cloudfunctions.net/parking_vision';
    }

    static detectMatchmakerServ( url: string ): string {
        if( url.includes( "localhost" ) ) {
            // return 'http://localhost:3020/match_maker'
            return 'https://us-central1-vade-prod.cloudfunctions.net/match_maker';
        }
        if( url.includes( "curb-config.vade.ai" ) || url.includes( "vade-prod.web.app/" ) || (url.includes("tempprod"))) {
           return 'https://us-central1-vade-prod.cloudfunctions.net/match_maker';
        }
        return 'https://us-central1-vade-dev-342822.cloudfunctions.net/match_maker';
    }

    static readonly serv      = this.detectServ( window.location.href );
    static readonly imageServ = this.detectImageServ( window.location.href );
    static readonly stayGenServ = this.detectStayGenServ( window.location.href );
    static readonly parkingVisionServ = this.detectParkingVisServ( window.location.href );
    static readonly matchmakerServ = this.detectMatchmakerServ( window.location.href );


    static traceImgOriginal( traceId: string ) {
        return `${this.imageServ}/${traceId}_original.jpg`;
    }

    static traceImgThumbnail( traceId: string ) {
        return `${this.imageServ}/${traceId}_thumbnail.jpg`;
    }

    static camCreate() { //POST
        return `${this.serv}/camera/create_camera`;
    }

    static camUpdate() { //POST
        return `${this.serv}/camera/update_camera`;
    }

    static camList( pgNum: number, pgSize: number ): string { //GET
        return `${this.serv}/camera/get_camera_page?page_number=${pgNum}&page_size=${pgSize}`;
    }

    static lanesByCamId( camId: string ) { //GET
        return `${this.serv}/lane/get_lanes_by_camera?camera_uuid=${camId}`;
    }

    static laneUpdate() { //PUT
        return `${this.serv}/lane/update_lane`;
    }

    static curbZoneUpdate() { //PUT
        return `${this.serv}/curb_zone/update_curb_zone`;
    }

    static session( vehicleId: string ) {
        return `${this.serv}/sessions/get_session_by_vehicle_uuid?vehicle_uuid=${vehicleId}`;
    }

    static camDetails( camId: string ): string { //GET
        return `${this.serv}/camera/get_camera_by_uuid?uuid=${camId}`;
    }

    static traceById( uuid: string ) {
        return `${this.serv}/trace/get_trace_by_uuid?uuid=${uuid}`;
    }

    static tracesByTime(): string {
        return `${this.serv}/trace/get_traces_by_time`;
    }

    static camTraces( camId: string, startPage: number, cnt: number ): string { //GET
        return `${this.serv}/trace/get_trace_page?camera_uuid=${camId}&page_number=${startPage}&page_size=${cnt}&sort_order=desc`;
    }

    static createLane() { //POST
        return `${this.serv}/lane/create_lane`;
    }

    static deployments( pgNum: number, pgSize: number ) { //GET
        return `${this.serv}/deployment/get_deployment_page?page_number=${pgNum}&page_size=${pgSize}`;
    }

    static deploymentDetails( deplId: string ) { //GET
        return `${this.serv}/deployment/get_deployment_by_uuid?uuid=${deplId}`;
    }

    static deploymentCreate( deplId: string ) { //POST
        return `${this.serv}/deployment/create_deployment`;
    }

    static deploymentUpdate() { //POST
        return `${this.serv}/deployment/update_deployment`;
    }

    static sessionIntersectingTraces() { //POST
        return `${this.serv}/sessions/get_sessions_intersecting_traces`;
    }

    static sessionsInDateRange() { //POST
        return `${this.serv}/sessions/get_sessions_by_time`;
    }

    static updateSession() {
        return `${this.serv}/sessions/update_session`;
    }

    static sessionUpdateStartTrace() {
        return `${this.serv}/sessions/set_session_start`;
    }

    static sessionUpdateEndTrace() {
        return `${this.serv}/sessions/set_session_end`;
    }

    static sessionSetValidation() {
        return `${this.serv}/sessions/set_session_validation`;
    }

    static deleteSession() {
        return `${this.serv}/sessions/delete_session_by_vehicle_uuid`;
    }

    static createSession() {
        return `${this.serv}/sessions/create_session`;
    }

    static reidObjsByTime(): string {
        return `${this.serv}/parking/get_reid_objs`;
    }

    static groundTruthByTime(): string {
        return `${this.serv}/parking/get_ground_truth`;
    }

    static cvProcessingDataByTime(): string {
        return `${this.stayGenServ}/get_processing_stats`;
    }

    static runParkingVision(): string {
        return `${this.parkingVisionServ}`;
    }
    static runStayGen(): string {
        return `${this.stayGenServ}/lanes_on_day`;
    }
    static runTaskGen(): string {
        return `${this.stayGenServ}/create_event_tasks`;
    }
    static runMatchmaker(): string {
        return `${this.matchmakerServ}/run`;
    }
    static getTasksByLaneAndDay(): string {
        return `${this.serv}/helios/get_questions_by_lane_and_day`;
    }
    static getAccuracyByLaneAndDay(): string {
        return `${this.matchmakerServ}/accuracy/get_accuracy`;
        // return `${this.matchmakerServ}/parking/get_accuracy`
    }

    static getQuestionsByLaneAndDay(): string {
        return `${this.serv}/helios/get_questions_by_lane_and_day`;
    }
}