
//react
import { useEffect, useState } from "react";
import { useParams           } from "react-router";
import {Card, Col, Row, Table, Button, Collapse} from "react-bootstrap";

//formik
import { Field, Form, Formik } from "formik";

//vade
import Api        from "../Api/Api";
import { ICam }   from "../Api/ICam";
import Deployment, {IDeployment} from "../Api/Deployment";
import {an, C} from "@fullcalendar/core/internal-common";

export interface CVControllerTableProps {
    deployments: IDeployment[]
    selectedCameraIds: Set<string>
    setCamIdsFunction: any
    selectedLaneIds: Set<string>
}


export function CVControllerTable( props: CVControllerTableProps) {
    const deployments = props.deployments!
    const allCameras: ICam[] = deployments.map((depl: IDeployment) => depl.cameras).flat(1)
    const selectedCameraIds = props.selectedCameraIds!
    const selectedLaneIds = props.selectedLaneIds!
    const setCamIdsFunction = props.setCamIdsFunction!

    const [expandedDepls, setExpandedDepls] = useState<Set<string>>(new Set())

    //routing
    const params = useParams();

    //hooks
    useEffect( () => {

    }, [] );

    function selectCameras( cameraIds: Set<string>, massForce = false ) {
        let cleanedIds = new Set( selectedCameraIds );
        let anySelected = false
        cameraIds.forEach( ( cameraId ) => {
            let isSelected = cleanedIds.has( cameraId )
            if( isSelected ) {
                anySelected = true
            }
        } )
        if( massForce ) {
            if( anySelected ) {
                cameraIds.forEach( ( cameraId ) => {
                    cleanedIds.delete( cameraId )
                } )
            } else {
                cameraIds.forEach( ( cameraId ) => {
                    cleanedIds.add( cameraId )
                } )
            }
        } else {
            cameraIds.forEach( ( cameraId ) => {
                let isSelected = cleanedIds.has( cameraId )
                if( isSelected ) {
                    cleanedIds.delete( cameraId )
                } else {
                    cleanedIds.add( cameraId )
                }
            } )
        }
        setCamIdsFunction( cleanedIds )
    }

    function CameraRow(props: {cam: ICam}){
        let cam = props.cam
        let cleanedIds = Array.from(selectedCameraIds).slice();
        let isSelected = cleanedIds.includes(cam.uuid)
        let bgColor = isSelected ? "primary" : "grey"

        return (
            <Button onClick={() => selectCameras(new Set([cam.uuid]))}
                    style={{backgroundColor: bgColor}}>
                {/*{cam.name}<br />*/}
                {cam.imei}
                {/*{cam.uuid}*/}
            </Button>
        )
    }

    function DeploymentTableSection(props: {deployment: IDeployment}){
        const depl = props.deployment
        const camerasForSection = depl.cameras.sort(((d1,d2) => d1.imei < d2.imei ? -1 : 1));
        const isSelected = expandedDepls.has(depl.uuid)
        const open = isSelected
        let hasSelectedCameras = false
        camerasForSection.forEach((cam) => {
            if (selectedCameraIds.has(cam.uuid)){
                hasSelectedCameras = true
            }
        })

        return (
            <Row>
                <Button
                    onClick={(e: any) => {
                        if (e.shiftKey && isSelected){
                            let deplCamIds = camerasForSection.map((cam) => cam.uuid)
                            selectCameras(new Set(deplCamIds), true)
                            return
                        }
                        let deplIdCopy = new Set(expandedDepls)
                        if (isSelected){
                            deplIdCopy.delete(depl.uuid)
                        }else{
                            deplIdCopy.add(depl.uuid)
                        }
                        setExpandedDepls(deplIdCopy)
                    }}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    variant={hasSelectedCameras ? "primary" : "secondary"}
                >
                    {depl.name}
                </Button>
                <Collapse in={open}>
                    <div>
                        {camerasForSection.map((deplCam) => <CameraRow cam={deplCam}
                                                                       key={`${deplCam.uuid}_selector`}
                        />)}
                    </div>
                </Collapse>
            </Row>
        );
    }

    return (
        <Col>
            {deployments.map((depl) => <DeploymentTableSection deployment={depl}
                                                               key={`${depl.uuid}_selector`} />)}
        </Col>
    );
}
