import {MainTableProps} from "./AccuracyPageMainTable";
import {Card, Container, Stack} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import {StayMatch} from "./interfaces/DebugInterfaces";
import StayDebugCanvas from "./DebugComponents/StayDebugCanvas";
import {Col, Row} from "react-bootstrap"

export interface DebugWindowProps {
    stayMatchingData: StayMatch[][] | null
}

function StayMatchRow(props: {gt: StayMatch | undefined, cv: StayMatch | undefined}){
    return <tr>
        <td>
            {props.gt && <StayDebugCanvas stay={props.gt}/>}
        </td>
        <td>
            {props.cv && <StayDebugCanvas stay={props.cv}/>}
        </td>
    </tr>
}

function StaySegment(props: {stay: StayMatch}){
    const stay = props.stay
    return  <Card className="card text-center">
        <Card.Body>
            <StayDebugCanvas stay={stay} />
        </Card.Body>
        <Card.Title>{stay.domain}</Card.Title>
        <Card.Footer>Vehicle ID: {stay.vehicle_id}</Card.Footer>
        <Card.Footer>Start Time: {stay.stay_start_time.toString()}</Card.Footer>
    </Card>

}


function StayMatchCard(props: {gt: StayMatch | undefined, cv: StayMatch | undefined}){
    return <Container>
                <Row className="justify-content-md-center">
                    <Col>
                        {props.gt && <StaySegment stay={props.gt}/>  ||  <Card><Card.Title>No GT</Card.Title></Card>}
                    </Col>
                    <Col>
                        {props.cv && <StaySegment stay={props.cv}/>  ||  <Card><Card.Title>No CV</Card.Title></Card>}
                    </Col>
                </Row>
            </Container>

}


function StayMatchTable(props: {stays: StayMatch[][]}){

    const stayMatchRows = props.stays.map((stayGroup) => <StayMatchRow gt={stayGroup[0]} cv={stayGroup[1]} key={stayGroup[0]?.vehicle_id ?? "FUCK"}/> )

    return <Table>
        <tr>
            <th>CV</th>
            <th>GT</th>
        </tr>
        {stayMatchRows}
    </Table>
}

function StayMatchCollection(props: {stays: StayMatch[][]}){
    const stayMatchCards = props.stays.map((stayGroup) => <StayMatchCard gt={stayGroup[0]} cv={stayGroup[1]} key={stayGroup[0]?.vehicle_id ?? "FUCK"}/> )
    return <Stack gap={3}>
        {stayMatchCards}
    </Stack>
}

function NoSelectionCard(){
    return <Card className="card text-center">
        <Card.Title>
            Please Select a row above to view debug data
        </Card.Title>
    </Card>
}

export function AccuracyDebugWindow(props: DebugWindowProps) {
    return <Container style={{ overflowY: 'scroll', maxHeight: '70%' }} >
        {props.stayMatchingData && <StayMatchCollection stays={props.stayMatchingData} />  || <NoSelectionCard/> }
    </Container>



}