//react
import { Marker, useGoogleMap } from "@react-google-maps/api";
import { useEffect, useState  } from "react";
import { Link, useParams      } from "react-router-dom";

//vade
import Api        from "./Api/Api";
import Deployment from "./Api/Deployment";
import { ICam }   from "./Api/ICam";
import VadeMap    from "./VadeMap";

import "./App.css"
import React from "react";
import MapFmt from "./Util/MapFmt";

export default function DeploymentEditPage() {
    const params = useParams();
    const [depl,      setDepl]    = useState<Deployment>();
    const [cams,      setCams]    = useState<ICam[]>( [] );
    const [curCamInd, setCurCam]  = useState(-1);
    const [panTo,     setPanTo]   = useState<google.maps.LatLngLiteral | null>(null);
    const [camFilt,   setCamFilt] = useState<string>("");

    useEffect( () => {
        Api.getDeployment( params.depId! ).then( resp => resp.json() )
                                          .then( json => { 
                                              setDepl( json.deployment );
                                              setPanTo( MapFmt.jsonToPnt( json.deployment.info ) );
                                              setCams( json.deployment.cameras );
                                          } );
    }, [params.depId] );
    
    if( !depl ) {
        return <h3>Loading!</h3>;
    }

    function getInstallation( cam: ICam, latLng: any ) {
        return { "location": {
            "type":                "Point",
            "coordinates":         [latLng.lng(), latLng.lat()] },
            "installer":           null,
            "altitude_cm":         cam.current_installation.altitude_cm,
            "image_pan_deg":       cam.current_installation.image_pan_deg,
            "image_tilt_deg":      cam.current_installation.image_tilt_deg,
            "solar_panel_pan_deg": cam.current_installation.solar_panel_pan_deg };
    }

    //probably should just let pages set h and w
    const containerStyle = { width: '75vw', height: '92vh' };
    function mapMouseDownHandler( e: google.maps.MapMouseEvent ) {
        if( curCamInd == -1 ) {
            return;
        }
        //is this irreducible?
        let tmp = [...cams];
        let cur = { ...tmp[curCamInd] };
        cur.current_installation = getInstallation( cur, e.latLng );
        tmp[curCamInd] = cur;
        setCams( tmp );
        setCurCam( -1 ); //so additional clicks won't set the point
        Api.updateCam( cur );
    }

    function labelMaker( s: string, i: number ): google.maps.MarkerLabel {
        return {
            color:      "black",
            fontWeight: "900",
            text:       s,
            fontSize:   "10px"
        };
    };

    let camPins: any = <></>;
    if ( depl.info ) {
        camPins = cams.map( ( cam, i ) => <Marker 
            key={`marker-${i}`}
            icon={{ url: "/img/campin.svg",
            scaledSize: new google.maps.Size(30, 30) }}
            label={labelMaker( shortName( cam.name ), i)}
            position={ coords( cam )}
            draggable={true}
            onDragEnd={( e: google.maps.MapMouseEvent ) => {
            if ( !e.latLng ) {
                return;
            }
            //is this irreducible?
            let tmp = [...cams];
            let cur = { ...tmp[i] }
            if ( !e.latLng ) { return }
            cur.current_installation = getInstallation( cam, e.latLng );
            tmp[i] = cur;
            setCams( tmp );
            setCurCam( -1 ); //so additional clicks won't set the point
            Api.updateCam( cur );
        }} /> );
    }

    function btnClick( e: React.SyntheticEvent, i: number ) {
        setCurCam( i );
    }

    function shortName( s: string ) {
        let ns = s.replace( "OCULI_", "" )
        if( ns.length == 2 )
            return "0" + ns;
        return ns;
    }

    function filterCams( cam: ICam ) {
        return cam.name.includes( camFilt );
    }

    function coords( cam: any ) {
        let cs = cam?.current_installation?.location?.coordinates;
        let ret = { lat: 0.0, lng: 0.0 };
        if( !cs ) { return ret }        
        ret = { lat: cs[1], lng: cs[0] };
        return ret;
    }

    function CameraSidebar() {
        return <>
            <div className="d-flex flex-column align-items-stretch flex-shrink-0" style={{ width: "25vw" }}>
                <a className="d-flex align-items-center fs-5 p-2 link-dark text-decoration-none border-bottom">
                    <div style={{width: "100%"}} className="text-center">Cameras</div>
                </a>
                <div className="scrollarea">
                    {cams.filter(filterCams).map( (cam, i) => {
                        return <button
                            style={{ width: "100%" }}
                            key={i} //woah that's a lot of style
                            className="list-group-item text-center list-group-item-action py-3 lh-tight border-bottom"
                            onClick={( e ) => { 
                                btnClick( e, i );
                                setPanTo( coords( cam ) ); //pan baby pan
                            }} ><img src="/img/cam.svg" height="25px" /> {shortName( cam.name )}
                            &nbsp;
                            <Link to={"/cam/" + cam.uuid}>
                                <input type="button" className="btn btn-secondary" value="View" />
                            </Link>
                        </button> })}
                </div>
            </div>
        </>;
    }    

    function PanToCam() {
        const map = useGoogleMap();
        useEffect( () => {
            if ( !map ) {
                alert("no map found!");
                return;
            }
            if ( !panTo ) {
                return; //this is fine
            }
            map.panTo( panTo );
            setPanTo( null );
            return;
        } );
        return <></>;
    }

    return <main>
        <CameraSidebar />
        <VadeMap
            onClick={mapMouseDownHandler}
            mapContainerStyle={containerStyle}
            zoom={11}>
            {camPins}
            <PanToCam />
        </VadeMap>
    </main>;
}