import {Card, Col, Container, Row, Spinner} from "react-bootstrap";
import { useEffect, useState } from "react";
import Api from "../Api/Api";
import {ICam} from "../Api/ICam";
import * as helpers from "./TaskCheckerHelpers"
import {IDeployment} from "../Api/Deployment";
import {Question} from "./taskCheckerInterfaces";
import {DeplCameraSelector} from "./DeplCameraSelector";
import DatePicker, {Calendar,  DayValue, DayRange, Day } from '@hassanmojab/react-modern-calendar-datepicker';
import {ITrace} from "../Api/ITrace";
import TaskCheckerMainView from "./TaskCheckerMainView";
import ILane from "../Api/ILane";


export default function TaskCheckerPage() {
    const [depls, setDepls]  = useState<IDeployment[] | undefined>(undefined)
    const [selectedCam, setSelectedCam]  = useState<ICam | null>(null)
    const [selectedDay, setSelectedDay]   = useState<Date>( new Date() );
    const dayAsCalendarObj: Day = {day: selectedDay.getDate(), year: selectedDay.getFullYear(), month: selectedDay.getMonth() + 1}
    const camLanes: ILane[] = getCameraLanes()

    useEffect( () => {
        helpers.loadDeployments(setDepls)
    }, [] );

    function getCameraLanes(){
        if (!depls) return []
        if (!selectedCam) return []
        let lanes: ILane[] = []
        depls.forEach((depl) =>{
            depl.lanes.forEach((lane) => {
                if (lane.camera_uuid === selectedCam.uuid){
                    lanes.push(lane)
                }
            })
        })
        return lanes
    }

    function handleCamsSelection(selectedCams: ICam[]){
        console.log(selectedCams)
        if (selectedCams.length === 0){
            setSelectedCam(null)
        }else{
            setSelectedCam(selectedCams[0])
        }
    }

    function handleDateChange(e: Day){
        const newDay = new Date(e.year, e.month - 1, e.day)
        console.log(newDay)
        setSelectedDay(newDay)
    }

    if(depls === undefined){
       return <Card>
           <Card.Title> Loading Deployments... </Card.Title>
           <Card.Body>  <Spinner/>              </Card.Body>
       </Card>
    }


    return <Container>
        <Row>
            <DatePicker value={dayAsCalendarObj}  onChange={handleDateChange} />
        </Row>
        <Row>
            <Col style={{maxWidth: "250px"}}>
                <DeplCameraSelector deployments={depls}
                                    selectedCams={selectedCam ? [selectedCam] : []}
                                    setCamsFunc={handleCamsSelection}
                                    singleSelection={true} />

            </Col>
            <Col sm={2}>
                { selectedCam ?  <TaskCheckerMainView camera={selectedCam}
                                                      day={selectedDay}
                                                      camLanes={camLanes}
                                    /> :  <h3>select a camera</h3> }
            </Col>
        </Row>
    </Container>
}
