//react
import { useEffect, useState } from "react";
import { Link, matchPath, useLocation, useMatch, useParams } from "react-router-dom";

//vade
import Api      from "./Api/Api";
import { ICam } from "./Api/ICam";
import DateUtil from "./Util/DateUtil";
import Fmt      from "./Util/Fmt";
import {getCameraLatestImageUrl} from "./Util/christiansUtils";

enum SortMethod{
    imei= "imei",
    status = "status",
    lastCapture = "lastCapture"
}

export default function CamListPage() {
    const location = useLocation();
    const params   = useParams();
    const [items, setItems] = useState<Array<ICam>>( [] );
    const [sortMethod, setSortMethod] = useState(SortMethod.lastCapture)

    useEffect(() => {
        console.log("sort method changed", sortMethod)
        if (sortMethod === SortMethod.imei) setItems([...items.sort(imeiSort)])
        if (sortMethod === SortMethod.lastCapture) setItems([...items.sort(lastCaptureSort)])
        if (sortMethod === SortMethod.status) setItems([...items.sort(statusSort)])
    }, [sortMethod])

    function statusSort(a: ICam, b:ICam){
        return a.is_active > b.is_active ? -1 : 1
    }

    function imeiSort(a: ICam, b:ICam){
        return a.name > b.name ? -1 : 1
    }

    function lastCaptureSort(a: ICam, b:ICam){
        let aDate = new Date(1999, 1, 1)
        let bDate = new Date(1999, 1, 1)
        if (a.latest_information?.last_image_captured_at){
            aDate = new Date(a.latest_information.last_image_captured_at)
        }
        if (b.latest_information?.last_image_captured_at){
            bDate = new Date(b.latest_information.last_image_captured_at)
        }
        return bDate.getTime() - aDate.getTime()
    }


    //only show unregistered cams
    function unregistered( cam: ICam ) {
        if(  cam.imei.includes( "FAKE-" )      ) { return false; }
        if( !cam.imei.includes( "OCULi" )      ) { return false; }
        if(  cam.deployment_uuid === undefined ) { return true;  }
        if(  cam.deployment_uuid === null      ) { return true;  }
        if(  cam.deployment_uuid === ""        ) { return true;  }
        return false;
    }

    let depId = "";
    function depFilter( cam: ICam ) {
        if( cam.deployment_uuid == depId ) {
            return true;
        }
        return false;
    }

    function all( cam: ICam ) {
        if(  cam.imei.includes( "FAKE-" )　) { return false; }
        return true;
    　}

    let title      = "All Cameras";
    let filt       = all; //the default
    let deplMatch  = useMatch("/dep/:depId/cam/");
    let unregMatch = useMatch("/cam/unreg/");
    if( deplMatch ) {
        filt = depFilter;
        depId = deplMatch.params.depId!; //see depFilter func for var decl
        title = "Cameras in Deployment " + deplMatch.params.depId;
    }
    else if( unregMatch ) {
        filt  = unregistered;        
        title = "Unregistered Cameras";
    }



    //get our data from the backend
    useEffect( () => {
        Api.getCams( 1, 2000 ).then( resp => resp.json() )
                              .then( json => {
                                  const filteredItems = json.cameras.filter( filt )
                                  const sortedItems = filteredItems.sort(imeiSort)
                                  setItems( sortedItems )
                              } );
    }, [1] ); // I just want to run this once, and never again

    const listItems = items.map( ( item: any ) => <tr key={item.uuid}>
        <td className="col-md-auto">
            <img src={getCameraLatestImageUrl(item)} style={{height: "100%", width: "150px"}}/>
            {item.latest_information?.last_image_captured_at &&
                <h6>Last Capture: {DateUtil.toSmallDateStr( new Date( item.latest_information?.last_image_captured_at ) )}</h6>
            }

        </td>
        <td className="col-md-auto">{DateUtil.toSmallDateStr( new Date( item.time_created ) )}</td>
        <td className="col-md-auto">{item.imei}</td>
        <td className="col-md-auto">{Fmt.toActiveInactive( item.is_active ) }</td>
        <td className="col-md-auto" width="1">
            <Link to={"/cam/" + item.uuid}>
                <button className='btn btn-primary'>view</button>
            </Link>
        </td>
        <td className="col-md-auto" width="1">
            <Link to={"/cam/" + item.uuid + "/edit"}>
                <button className='btn btn-primary'>edit</button>
            </Link>
        </td>
    </tr>
    );

    return (<>
        <div className="m-3 row align-middle">
            <h3 className="">{title}</h3>
            <table className="table w-auto table-striped">
            <thead>
                <tr>
                    <td className="col-md-auto" onClick={() => setSortMethod(SortMethod.lastCapture)}> Image       </td>
                    <td className="col-md-auto"                                                      > Created     </td>
                    <td className="col-md-auto" onClick={() => setSortMethod(SortMethod.imei)}       > IMEI        </td>
                    <td className="col-md-auto" onClick={() => setSortMethod(SortMethod.status)}     > Status      </td>
                    <td></td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {listItems}
            </tbody>
        </table>
        </div>
    </> );
}