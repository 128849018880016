import { DateTime } from "luxon";
import { IGetReidObjsResp, ReidConv, ReidObj } from "./Api/GetReidObjsResp";
import { IStayRaw } from "./Api/IStay";
import { ITraceWFrameNum, parseUtc } from "./CamLaneSessionsPageUtil";
import { GroundTruthRec as IGroundTruthRec } from "./Data/IGroundTruth";
import { IObj } from "./IVisionOutput";
import {forEach} from "react-bootstrap/ElementChildren";
import {al, an, cl, de, en} from "@fullcalendar/core/internal-common";

export function findFirstTrace( date: Date, tz: string, traces: Array<ITraceWFrameNum> ) {
    function sameDay( t: ITraceWFrameNum ) {
        const a = DateTime.fromJSDate( date ).setZone( tz, { keepLocalTime: true } );
        const b = t.timeCapturedLocal;
        return ( a.year  === b.year
                && a.month === b.month
                && a.day   === b.day );
    }
    const first = traces.findIndex( t => sameDay( t ) );
    if( first !== -1 ) {
        return first;
    }
    return 0;
}

export const sum = ( arr: Array<number> ) => arr.reduce( ( ps: number, a: number ) => ps + a, 0 );
export const zip = ( a: any[], b: any[] ) => a.map(      ( k: any,     i: number ) => [k, b[i]] );

export interface SessionFrame {
    traceId: string;
    time:    DateTime;
    obj:     IObj;
}

export interface SessionRec {
    vehicleId: string;
    frames:    SessionFrame[];
    pre:       ITraceWFrameNum;
    post:      ITraceWFrameNum;
}

function convert2PointBoxTo4PointBox( asdf: number[][] ) {
    let topLeft     = { x: asdf[0][0],    y: asdf[0][1]    };
    let bottomRight = { x: asdf[1][0],    y: asdf[1][1]    };
    let topRight    = { x: bottomRight.x, y: topLeft.y     };
    let bottomLeft  = { x: topLeft.x,     y: bottomRight.y };
    return [[ topLeft.x,     topLeft.y     ],
            [ topRight.x,    topRight.y    ],
            [ bottomRight.x, bottomRight.y ],
            [ bottomLeft.x,  bottomLeft.y  ]
        ];
}

interface TraceReidObj {
    traceId: string;
    obj:     ReidObj;
}

export function getReidSessionRecs( visionOutput: IGetReidObjsResp,
                                    traces:       Array<ITraceWFrameNum> ) {
    let res: SessionRec[] = [];
    const map = new Map<string, Array<TraceReidObj>>(); 
    //need to sort frames by 
    for( const frame of visionOutput.reid_objs ) {
        if( map.has( frame.reid_id ) ) {
            map.get( frame.reid_id )!.push( { traceId: frame.trace_id,
                                              obj:     ReidConv( frame ) } );
            continue;
        }
        //else
        map.set( frame.reid_id, [{ traceId: frame.trace_id,
                                   obj:      ReidConv( frame ) }] );
    }
    //figure out the times for them... :( this is all kind of painful
    for( const vehicleId of Array.from( map.keys() ) ) {
        const ts = map.get( vehicleId )!;
        ts.sort( (a, b) => { //mutates ts
            let at = traces.find( el => el.traceId === a.traceId )?.timeCaptured.toISO()!;
            let bt = traces.find( el => el.traceId === b.traceId )?.timeCaptured.toISO()!;
            return DateTime.fromISO( at ).toMillis()
                   - DateTime.fromISO( bt ).toMillis()
                  || a.traceId.localeCompare( b.traceId );
            } );
    }
    for( const vehicleId of Array.from( map.keys() ) ) {
        const frames      = map.get( vehicleId )!;
        const first       = frames[0];
        const last        = frames[frames.length - 1];
        const firstInd    = traces.findIndex( el => el.traceId === first.traceId );
        const lastInd     = traces.findIndex( el => el.traceId === last.traceId  );
        const prevFrame   = first;
        const rec = { vehicleId: vehicleId, 
                    frames:    new Array<SessionFrame>(),
                    pre:       traces[firstInd-1],
                    post:      traces[lastInd+1] };
        
        //console.log( `${vehicleId}: ${firstInd}->${lastInd}` );
        for( let i = firstInd; i <= lastInd; i++ ) {
            const traceId = traces[i].traceId;
            let frame = frames.find( el => el.traceId === traceId ) ?? first;
                frame = frame ?? prevFrame;
            const obj = { ...frame.obj };
            const fixedBox = convert2PointBoxTo4PointBox( obj.bbox );
            const frameWExtras = { ...obj, vehicleId:  vehicleId, 
                                           bbox:       fixedBox,
                                           centroid:   centroid( obj.groundPlane ),
                                           //centroid:   centroid( fixedBox ),
                                           classLabel: obj.classLabel,
                                           groundPoly: obj.groundPlane
                                 };
            rec.frames.push( { traceId: traceId,
                               time: traces[i].timeCaptured,
                               obj: frameWExtras } );
        }
        res.push( rec );
    }
    // res = res.filter( el => el.frames.length > 1 );
    // res = res.filter( el => el.frames[0].obj.classLabel !== "bicycle" );
    // res = res.filter( el => el.frames[0].obj.classLabel !== "motorbike" );
    return res;
}

export function getStayRecs( visionOutput: IStayRaw[],
                             traces:       Array<ITraceWFrameNum> ) {
    let res: SessionRec[] = [];
    for( let session of visionOutput ) {
        let fstTrace    = session.start_trace;
        let lstTrace    = session.end_trace;
        let firstInd    = traces.findIndex( el => el.traceId === fstTrace );
        let lastInd     = traces.findIndex( el => el.traceId === lstTrace  );
        let prevFrame   = fstTrace;
        let rec = { vehicleId: session.vehicle_id.toString(), 
                    frames:    new Array<SessionFrame>(),
                    pre:       traces[firstInd-1],
                    post:      traces[lastInd+1] };
        if( firstInd === -1 || lastInd === -1 ) {
            continue;
        }
        for( let i = firstInd; i <= lastInd; i++ ) {
            let traceId = traces[i].traceId;
            let frame = fstTrace;
            frame     = frame ?? prevFrame;
            const fixedBox = session.bbox.slice( 0, 4 );
            let frameWExtras = { vehicleId:  session.vehicle_id.toString(), 
                                 bbox:       fixedBox,
                                 centroid:   centroid( session.ground_plane ), //centroid( fixedBox ),
                                 classLabel: session.class_label,
                                 groundPoly: session.ground_plane
                               };
            rec.frames.push( { traceId: traceId,
                               time: traces[i].timeCaptured,
                               obj: frameWExtras } );
        }
        console.log( `${rec.vehicleId}: ${rec.frames.length} min` );
        res.push( rec );
    }
    return res;
}

export function getSessionRecs( visionOutput: IGroundTruthRec[],
                                traces:       Array<ITraceWFrameNum> ) {
    let res: SessionRec[] = [];
    for( let session of visionOutput ) {
        let fstTrace    = session.session_start;
        let lstTrace    = session.session_end;
        let firstInd    = traces.findIndex( el => el.traceId === fstTrace );
        let lastInd     = traces.findIndex( el => el.traceId === lstTrace  );
        let prevFrame   = fstTrace;
        let rec = { vehicleId: session.vehicle_uuid.toString(), 
                    frames:    new Array<SessionFrame>(),
                    pre:       traces[firstInd-1],
                    post:      traces[lastInd+1] };
        if( firstInd === -1 || lastInd === -1 ) {
            continue;
        }
        for( let i = firstInd; i <= lastInd; i++ ) {
            let traceId = traces[i].traceId;
            let frame = fstTrace;
            frame     = frame ?? prevFrame;
            const fixedBox = session.vehicle_bbox.slice( 0, 4 );
            let frameWExtras = { vehicleId:  session.vehicle_uuid.toString(), 
                                 bbox:       fixedBox,
                                 centroid:   centroid( session.vehicle_polygon ), //centroid( fixedBox ),
                                 classLabel: session.vehicle_class_label,
                                 groundPoly: session.vehicle_polygon
                               };
            rec.frames.push( { traceId: traceId,
                               time: traces[i].timeCaptured,
                               obj: frameWExtras } );
        }
        console.log( `${rec.vehicleId}: ${rec.frames.length} min` );
        res.push( rec );
    }
    return res;
}

export function getTraceToSessionMap( visionOutput: SessionRec[], defaultStroke: string = "white" ) {
    let traceMap = new Map<string, IObj[]>();
    for( let vehicle of visionOutput ) {
        let frames = vehicle.frames;
        for( const frame of frames ) {
            const traceId: string = frame.traceId;
            if( !traceMap.has( traceId ) ) {
                traceMap.set( traceId, [] );
            }
            let   obj          = { ...frame.obj };
            const fixedBox     = obj.bbox.slice( 0, 4 )
            const frameWExtras = { ...obj, vehicleId:  obj.vehicleId, 
                                            bbox:      fixedBox,
                                            centroid:  obj.centroid,
                                            strokeColor: defaultStroke};
            traceMap.get( traceId )!.push( frameWExtras );
        }
    }
    return traceMap;
}

export function fmtPercent( n: number ) {
    return (100 * n).toFixed(1) + "%";
}

export function centroid( pnts: Array<Array<number>> ) {
    let xs = sum( pnts.map( p => p[0] ) );
    let ys = sum( pnts.map( p => p[1] ) );
    return [xs / pnts.length, ys / pnts.length];
}

export function getDist( p: Array<number>, q: Array<number> ) {
    let sqDiff = 0;
    for( let [pi, qi] of zip( p, q ) ) {
        sqDiff += (pi - qi) ** 2;
    }
    let distance = sqDiff ** 0.5;
    return distance;
}

export function getNew( as: IObj[], bs: IObj[] ) { //is present in b but not in a
    return bs.filter( b => as.findIndex( a => a.vehicleId === b.vehicleId ) === -1 );
}

export function getKept( as: IObj[], bs: IObj[] ) { //is same in cur as in pre
    return as.filter( a => bs.findIndex( b => b.vehicleId === a.vehicleId ) !== -1 );
}

export function getDel( as: IObj[], bs: IObj[] ) { //is in a but not in b
    return as.filter( a => bs.findIndex( b => a.vehicleId === b.vehicleId ) === -1 );
}

//we compare ground truth with the vision
//output to produce an array of CompareResult
interface CompareResult {
    visionId:  string;
    groundId:  string;
    action:    string;
    dist:      number;
}

export function compareDecisions( visVehicles:  IObj[], 
                                  grndVehicles: IObj[],
                                  action:       string ): Array<CompareResult> {
    const matches: CompareResult[] = []; 
    for( const grndVehicle of grndVehicles ) {
        const compares: CompareResult[] = [];
        for( const visVehicle of visVehicles ) {
            const dist = getDist( grndVehicle.centroid, visVehicle.centroid );
            if( isNaN( dist ) ) { continue; }
            if( dist > 450    ) { continue; }
            compares.push( { groundId: grndVehicle.vehicleId,
                             visionId: visVehicle.vehicleId,
                             action:   action,
                             dist:     dist } );
        }
        compares.sort( (a, b) => a.dist - b.dist );
        if( compares.length > 0 ) {
            matches.push( compares[0] ); //get the best match
        }
    }
    const dels = new Set<number>();
    matches.forEach( (a, i) =>
        matches.forEach( (b, j) => {
            if( i === j                   ) { return; }
            if( a.visionId !== b.visionId ) { return; }
            //we want to delete the bigger one
            //    a < b = true  -> 1 then del b
            //    a < b = false -> 0 then del a
            const k = +(a.dist < b.dist);
            dels.add( [i, j][k] );
    } ) );
    const refined = matches.filter( ( _, i ) => !dels.has( i ) );
    const tums    = new Set( visVehicles.map( el => el.vehicleId ) );
    const matchedTestVehicles = refined.map( el => el.visionId );
    for( const m of matchedTestVehicles ) {
        tums.delete( m );
    }
    for( const tum of Array.from( tums.keys() ) ) {
        refined.push( { groundId: "", visionId: tum, action: action, dist: -1 } );
    }
    const gums    = new Set( grndVehicles.map( el => el.vehicleId ) );
    const matchedGrndVehicles = refined.map( el => el.groundId );
    for( const m of matchedGrndVehicles ) {
        gums.delete( m );
    }
    for( const gum of Array.from( gums.keys() ) ) {
        refined.push( { groundId: gum, visionId: "", action: action, dist: -1 } );
    }
    return refined;
}

export function getObjStats( output: SessionRec[] ) {
    let sum = 0;
    for( let vehicle of output ) {
        sum += vehicle.frames.length;
    }
    return sum;
}

type IMyOutput = Map<string, Array<IObj>>;

export function statsVehicleCount( output: SessionRec[] ) {
    return output.length;
}

export function statsAvgStayDur( output: SessionRec[] ) {
    let sum = 0;
    for( let vehicle of output ) {
        sum += vehicle.frames.length;
    }
    return sum / statsVehicleCount( output );
}

export function getDecisions( traces: ITraceWFrameNum[], traceId: string, visionOutput: IMyOutput ) {
    let index = traces.findIndex( f => f.traceId === traceId );
    let fpre  = visionOutput.get( traces[index - 1]?.traceId );
    if( !fpre ) {
        fpre = [];
    }

    let fcur = visionOutput.get( traceId );
    if( !fcur ) {
        fcur = [];
    }
    const added = getNew ( fpre, fcur );
    const kept  = getKept( fpre, fcur );
    const del   = getDel ( fpre, fcur );
    return [added, kept, del];
}
    
export function getDebug( vision: Map<string, IObj[]>,
                          ground: Map<string, IObj[]>,
                          traces: ITraceWFrameNum[], 
                          ind: number ) {
    let [ta, tk, td]  = getDecisions( traces, traces[ind]?.traceId, vision );
    let [ga, gk, gd]  = getDecisions( traces, traces[ind]?.traceId, ground );
    let compareAdd    = compareDecisions( ta, ga, "Add"    );
    let compareKeep   = compareDecisions( tk, gk, "Keep"   );
    let compareDel    = compareDecisions( td, gd, "Delete" );
    return [...compareAdd, ...compareKeep, ...compareDel];
}

export function getDecisionStats( vision: Map<string, IObj[]>, 
                                  ground: Map<string, IObj[]>,
                                  traces: ITraceWFrameNum[] ) {
    let vstats        = { a: 0, k: 0, d: 0, t: 0 };
    let gstats        = { a: 0, k: 0, d: 0, t: 0 };
    let vstatsCorrect = { a: 0, k: 0, d: 0, t: 0 };

    traces.forEach( ( tr, i ) => {
        let [ta, tk, td] = getDecisions( traces, traces[i]?.traceId, vision );
        let [ga, gk, gd] = getDecisions( traces, traces[i]?.traceId, ground );
        
        //total decisions made in vision
        vstats.a += ta.length;
        vstats.k += tk.length;
        vstats.d += td.length;
        vstats.t += ta.length + tk.length + td.length;

        //total decisions made in ground truth
        gstats.a += ga.length;
        gstats.k += gk.length;
        gstats.d += gd.length;
        gstats.t += ga.length + gk.length + gd.length;
        
        //decisions made correctly
        vstatsCorrect.a += getDebug( vision, ground, traces, i ).filter( el => el.action === "Add" )
                                                                .filter( el => el.groundId !== "" && el.visionId !== "" ).length;
        vstatsCorrect.k += getDebug( vision, ground, traces, i ).filter( el => el.action === "Keep" )
                                                                .filter( el => el.groundId !== "" && el.visionId !== "" ).length;
        vstatsCorrect.d += getDebug( vision, ground, traces, i ).filter( el => el.action === "Delete" )
                                                                .filter( el => el.groundId !== "" && el.visionId !== "" ).length;
        vstatsCorrect.t += getDebug( vision, ground, traces, i ).filter( el => el.groundId !== "" && el.visionId !== "" ).length;
    } );
    return { visDecs:        vstats,
             grnDecs:        gstats,
             visDecsCorrect: vstatsCorrect };
}


export function pointInPolygonNested (point: Array<number>, vs: Array<Array<number>> ) {
    var x = point[0], y = point[1];
    var inside = false;
    const start = 0;
    const end   = vs.length;
    var len = end - start;
    for (var i = 0, j = len - 1; i < len; j = i++) {
        var xi = vs[i+start][0], yi = vs[i+start][1];
        var xj = vs[j+start][0], yj = vs[j+start][1];
        var intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};




interface Stay {
    vehicle_id: number;
    src_reid_id: string;
    src_obj_id: number;
    start_trace: string;
    end_trace: string;
    lane_uuid: string;
    bbox: any;
    ground_plane: any;
    class_label: string;
    domain: string;

    equals(other: Stay): boolean;
}


function get_diff_between_traces(trace_1: string, trace_2: string, all_traces: ITraceWFrameNum[]){
    let trace_1_obj = all_traces.find((obj) => {
        return obj.traceId === trace_1;
    });
    let trace_2_obj = all_traces.find((obj) => {
        return obj.traceId === trace_2;
    });
    if (!trace_1_obj || !trace_2_obj){
        console.warn("Could not find trace ", trace_1, trace_2, " in traces saved locally")
        return 99999
    }
    const frame_diff = trace_1_obj.frameNum - trace_2_obj.frameNum
    return frame_diff
}

export function getSessionComparison (rawGrnd: any[] | undefined, rawVis: any[] | undefined, trace_tolerance: number,
                                      all_traces: ITraceWFrameNum[], setFunc: any | undefined){
    if (!rawGrnd || !rawVis){
        return {
            start_end_correct: 0,
            gt_not_linked: 0,
            vis_not_linked: 0,
            gt_match_percent: 0.0,
            rel_err_percent: 0.0,
            miss_percent: 0.0
        }
    }

    let matchedGt: any[] = [];
    let unmatchedGt: any[] = [];
    let unMatchedVis: any[] = [];
    rawVis.forEach(val => unMatchedVis.push(Object.assign({}, val)));

    for (let gt_stay of rawGrnd) {
        // const gt_start: string = gt_stay.start_trace
        // const gt_end: string = gt_stay.end_trace;
        const gt_start: string = gt_stay.frames[0].traceId
        const gt_end: string = gt_stay.frames[gt_stay.frames.length - 1].traceId
        let matched = false;
        for( let vis_stay of unMatchedVis) {
            const vis_start: string = vis_stay.start_trace;
            const vis_end: string = vis_stay.end_trace;
            const start_trace_frame_dif = Math.abs(get_diff_between_traces(gt_start, vis_start, all_traces))
            const end_trace_frame_diff = Math.abs(get_diff_between_traces(gt_end, vis_end, all_traces))
            if ((start_trace_frame_dif <= trace_tolerance) && (end_trace_frame_diff <= trace_tolerance)) {
                let matchDict = {
                    gt: gt_stay,
                    vis: vis_stay
                }
                matchedGt.push(matchDict)
                // remove item from unmatched vis array
                unMatchedVis = unMatchedVis.filter(t_vis_stay => t_vis_stay.vehicle_id !== vis_stay.vehicle_id);
                matched = true
                break;
            }
        }
        if (!matched){
            unmatchedGt.push(gt_stay)
        }
    }

    let rel_err = Math.abs((unMatchedVis.length) / rawGrnd.length)
    let grndMatchPercent = (matchedGt.length / rawGrnd.length)
    let missPercent = (unmatchedGt.length / rawGrnd.length)
    if (setFunc){
        setFunc(matchedGt)
    }
    return {
        start_end_correct: matchedGt.length,
        gt_not_linked: unmatchedGt.length,
        vis_not_linked: unMatchedVis.length,
        gt_match_percent: grndMatchPercent,
        rel_err_percent: rel_err,
        miss_percent: missPercent
    }

}


export function getEventComparison (rawGrnd: any[] | undefined, rawVis: any[] | undefined, trace_tolerance: number,
                                      all_traces: ITraceWFrameNum[], setFunc: any | undefined){
    if (!rawGrnd || !rawVis) {
        return {
            vis_starts_total: 0,
            vis_starts_false_pos: 0,
            vis_starts_false_neg: 0,
            vis_ends_total: 0,
            vis_ends_false_pos: 0,
            vis_ends_false_neg: 0
        }
    }
    if (rawVis.length === 0) {
        return {
            vis_starts_total: 0,
            vis_starts_false_pos: 0,
            vis_starts_false_neg: 0,
            vis_ends_total: 0,
            vis_ends_false_pos: 0,
            vis_ends_false_neg: 0
        }
    }

    let allGtStarts: any[] = []
    let allGtEnds: any[] = []

    let allVisStarts: any[] = []
    let allVisEnds: any[] = []


    rawGrnd.forEach(val => {
        let startTrace = val.frames[0].traceId
        let endTrace = val.frames[val.frames.length - 1].traceId
        allGtStarts.push({vehicleId: val.vehicleId, start_trace: startTrace, matched: false})
        allGtEnds.push({vehicleId: val.vehicleId, end_trace: endTrace, matched: false})
    });

    rawVis.forEach(val => {
        let startDict = {vehicleId: String(val.vehicle_id), start_trace: val.start_trace, matched: false}
        let endDict = {vehicleId: String(val.vehicle_id), end_trace: val.end_trace, matched: false}
        allVisStarts.push(startDict)
        allVisEnds.push(endDict)
    });

    for (let gt_start of allGtStarts) {
        let visComparisons = []
        let unMatchedVisStarts = allVisStarts.filter((val) => !val.matched)
        for (let vis_start of unMatchedVisStarts) {
            const start_frame_dif = Math.abs(get_diff_between_traces(gt_start.start_trace, vis_start.start_trace, all_traces))
            if (start_frame_dif <= trace_tolerance) {
                let comparisonDict = {
                    gt_start: gt_start,
                    vis_start: vis_start,
                    distance: start_frame_dif
                }
                visComparisons.push(comparisonDict)
            }
        }
        if (visComparisons.length === 0){
            // GT could not be matched
        }else{
            //GT got matched
            visComparisons.sort((a, b) => a.distance - b.distance )
            let closestComp = visComparisons[0]
            for (let i=0; i < allVisStarts.length; i++){
                let visObj = allVisStarts[i]
                if (visObj.vehicleId === closestComp.vis_start.vehicleId){
                    gt_start.matched = true
                    gt_start.matched_vis_id = visObj.vehicleId
                    visObj.matched = true
                    visObj.matched_gt_id = gt_start.vehicleId
                    visObj.matched_gt_frame_id = gt_start.start_trace
                }
            }
        }
    }

    for (let gt_end of allGtEnds) {
        let visComparisons = []
        let unMatchedVisEnds = allVisEnds.filter((val) => !val.matched)
        for (let vis_end of unMatchedVisEnds) {
            const start_frame_dif = Math.abs(get_diff_between_traces(gt_end.end_trace, vis_end.end_trace, all_traces))
            if (start_frame_dif <= trace_tolerance) {
                let comparisonDict = {
                    gt_end: gt_end,
                    vis_end: vis_end,
                    distance: start_frame_dif
                }
                visComparisons.push(comparisonDict)
            }
        }
        if (visComparisons.length === 0){
            // GT could not be matched
        }else{
            //GT got matched
            visComparisons.sort((a, b) => a.distance - b.distance )
            let closestComp = visComparisons[0]
            for (let i=0; i < allVisEnds.length; i++){
                let visObj = allVisEnds[i]
                if (visObj.vehicleId === closestComp.vis_end.vehicleId){
                    gt_end.matched = true
                    gt_end.matched_vis_id = visObj.vehicleId
                    visObj.matched = true
                    visObj.matched_gt_id = gt_end.vehicleId
                    visObj.matched_gt_frame_id = gt_end.end_trace
                }
            }
        }
    }
    let visStartNoLink = allVisStarts.filter((val) => !val.matched)
    let gtStartNoLink = allGtStarts.filter((val) => !val.matched)

    let visEndNoLink = allVisEnds.filter((val) => !val.matched)
    let gtEndNoLink = allGtEnds.filter((val) => !val.matched)


    let debuggerMap = {
        matchedStartsVis: allVisStarts.filter((val) => val.matched),
        matchedEndsVis: allVisEnds.filter((val) => val.matched),
        matchedStartsGt: allGtStarts.filter((val) => val.matched),
        matchedEndsGt: allGtEnds.filter((val) => val.matched),
    }
    if (setFunc){
        setFunc(debuggerMap)
    }

    return {
        vis_starts_total: allVisStarts.length,
        vis_starts_false_pos: visStartNoLink.length,
        vis_starts_false_neg: gtStartNoLink.length,
        vis_ends_total: allVisEnds.length,
        vis_ends_false_pos: visEndNoLink.length,
        vis_ends_false_neg: gtEndNoLink.length
    }
}
