import React, { createRef, useEffect, useState } from "react";
import ILane from "./Api/ILane";
import { IObj } from "./IVisionOutput";

export interface ITraceGalleryAccuracyItem {
    traceId:      string,
    original:     string;
    description:  string;
}

interface TraceGalleryAccuracyProps {
    ind:          number;
    items:        Array<ITraceGalleryAccuracyItem>;
    sessions:     Map<string, Array<IObj>>;
    lane:         ILane;
    style?:       React.CSSProperties;
}

export default function TraceGalleryAccuracy( props: TraceGalleryAccuracyProps ) {
    const [canvasRef]   = useState<React.RefObject<HTMLCanvasElement>>( createRef<HTMLCanvasElement>() );
    const [img, setImg] = useState<HTMLImageElement>();
    const ind           = props.ind;
    const items         = props.items;
    const lane          = props.lane;
    const sessions      = props.sessions;
    const fontIncr      = 0;
    
    useEffect( () => {
        processImage();
    }, [items, ind] );

    async function processImage() {
        let bgImage = new Image();
        if( items.length == 0 ) {
            return;
        }
        if( !items[ind] ) {
            return;
        }
        bgImage.src = items[ind].original;
        await new Promise<void>( ( resolve ) => {
            bgImage.onload  = ( _e ) => { return resolve(); };
            bgImage.onerror = ( _e ) => { return resolve(); };
        } );
        setImg( bgImage );
    }

    //get all sessions that are in this trace
    function getHistEntriesInTrace( traceId: string, sessions: Map<string, Array<IObj>> ): Array<IObj> {
        if( !sessions.has( traceId ) ) {
            return [];
        }
        return sessions.get( traceId )!;
    }

    function drawBoundingBoxes( ps:   number[][], 
                                ctx:  CanvasRenderingContext2D ) {
        if( ps.length === 0 ) {
            return;
        }
        ctx.beginPath();
        ctx.lineWidth = 3;
        ctx.moveTo( ps[0][0], ps[0][1] );
        for ( let i = 1; i < ps.length; i++ ) {
            ctx.lineTo( ps[i][0], ps[i][1] );
        }
        ctx.lineTo( ps[0][0], ps[0][1] );
        ctx.stroke();
    }

    function trunc( str: string ) {
        return str.slice(0, 4);
    }

    function setTextStyle( ctx: CanvasRenderingContext2D, 
                           fontName:    string = "Monaco",
                           fontSize:    number = 10,
                           fillColor:   string = "black", 
                           strokeColor: string = "black",
                           strokeWidth: number = 1,
                           alpha:       number = 1.0 ) {
        ctx.font        = `${fontSize}pt ${fontName}`;
        ctx.fillStyle   = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth   = strokeWidth;
        ctx.globalAlpha = alpha;
    }

    function setDrawStyle( ctx: CanvasRenderingContext2D,
                           fillColor: string = "black",
                           strokeWidth: number = 1,
                           strokeColor: string = "black",
                           alpha: number = 1.0 ) {
        ctx.fillStyle   = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth   = strokeWidth;
        ctx.globalAlpha = alpha;
    }

    function drawCentroids( histEntries: Array<IObj>, ctx: CanvasRenderingContext2D ) {
        histEntries.forEach( flds => {
            const centroid = flds.centroid;
            if( !centroid || centroid.length === 0 ) {
                return;
            }
            const [x, y] = [centroid[0], centroid[1]];
            
            //draw the centroid
            setDrawStyle( ctx, "black", 5, "white" );
            ctx.beginPath();
            ctx.arc( x, y, 2, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
    
            //now label the centroid
            setTextStyle( ctx, "Monaco", 8 + fontIncr, "white", "black", 4 );
            let vehicleType = flds.classLabel;
            if( !vehicleType ) {
                vehicleType = "";
            }
            // let shortVehicleId = flds.vehicleId!.slice(-4)
            let shortVehicleId = flds.vehicleId!.slice(-6)
            const text = shortVehicleId + " " + vehicleType;
            ctx.strokeText( text, x + 8, y + 8 );
            ctx.fillText  ( text, x + 8, y + 8 );
        } );
    }

    function drawBoxes( histEntries: Array<IObj>, ctx: CanvasRenderingContext2D ) {
        histEntries.forEach( histEntry => {
            setDrawStyle( ctx, "white", 10, "white", 0.65 );
            drawBoundingBoxes( histEntry.bbox, ctx );
        } );
    }

    function drawGroundPolygons( histEntries: Array<IObj>, ctx: CanvasRenderingContext2D ) {
        histEntries.forEach( histEntry => {
            let strokeColor = histEntry.strokeColor ?? "white"
            setDrawStyle( ctx, "white", 10, strokeColor, 0.65 );
            drawBoundingBoxes( histEntry.groundPoly, ctx );
        } );
    }

    function drawLanePoly( lane: ILane, ctx: CanvasRenderingContext2D ) {
        setDrawStyle( ctx, "yellow", 4, "aqua", 0.50 );
        ctx.beginPath();
        let pts = lane.image_trigger_boundary;
        ctx.moveTo( pts[0][0], pts[0][1] );
        for( let i = 1; i < pts.length; i++ ) {
            ctx.lineTo( pts[i][0], pts[i][1] );
        }
        ctx.stroke();
    }

    function drawExtras( item: ITraceGalleryAccuracyItem, ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement ) {
        if( !sessions.has( item.traceId ) ) {
            return; //indicate we don't have any session data to show on this trace
        }
        setTextStyle( ctx, "Monaco", 16, "white", "black", 4 );
        let desc = "* "; //just an indicator that there is session data for the current image
        ctx.strokeText( desc, canvas.width - ctx.measureText(desc).width, canvas.height - 10 );
        ctx.fillText  ( desc, canvas.width - ctx.measureText(desc).width, canvas.height - 10 );
    }

    function drawTimecode( item: ITraceGalleryAccuracyItem, ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement ) {
        let desc = item.description;
        setTextStyle( ctx, "Monaco", 20, "white", "black", 4 );
        ctx.strokeText( desc, 10, canvas.height - 10 );
        ctx.fillText  ( desc, 10, canvas.height - 10 );
    }
    
    const draw = ( background: HTMLImageElement | undefined ) => {
        if ( !background ) {
            return;
        }
        const canvas = canvasRef.current;
        if ( !canvas ) {
            return;
        }
        canvas.height = background.naturalHeight;
        canvas.width  = background.naturalWidth;
        const ctx = canvas.getContext( "2d", { alpha: false } );
        if ( !ctx ) {
            return;
        }

        //draw the image first
        ctx.drawImage( background, 0, 0 );

        //now let's draw a poly!
        drawLanePoly ( lane, ctx );
        //drawLaneLine ( lane, ctx );

        //draw boxes and centroids
        let histories = getHistEntriesInTrace( items[ind].traceId, sessions );
        drawBoxes         ( histories, ctx );
        drawGroundPolygons( histories, ctx );
        drawCentroids     ( histories, ctx );
        

        //draw these last so they are on top of everything
        drawExtras   ( items[ind], ctx, canvas );
        drawTimecode ( items[ind], ctx, canvas );
        return;
    };
    
    return <>
        <canvas ref={canvasRef} 
                style={props.style} />
        { draw( img ) }
    </>;
}


