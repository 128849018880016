
export default class Fmt {
    //boolean
    static str( b: boolean, t: string, f: string ):  string {
        return b ? t : f;
    }
    static toTrueFalse( b: boolean ):  string {
        return this.str( b, "True", "False" );
    }
    static toActiveInactive( b: boolean ) {
        return this.str( b, "Active", "Inactive" );
    }
}