import { createRef, useEffect, useState } from "react";

interface ImageGalleryProps { 
    style?: React.CSSProperties;
    items: any;
}

export default function ImageGallery( props: ImageGalleryProps ) {
    const [canvasRef] = useState<React.RefObject<HTMLCanvasElement>>( createRef<HTMLCanvasElement>() );
    const [ind,        setInd]        = useState<number>( 0 );
    const [background, setBackground] = useState<HTMLImageElement>();
    const items                       = props.items;
    
    useEffect( () => {
        processImage();
        window.addEventListener('keydown', onKeyDownHandler );
        return () => { 
            window.removeEventListener('keydown', onKeyDownHandler );
        }
    }, [items, ind] );

    interface KeyboardEvent {
        key:      string;
        shiftKey: boolean;
    }
    
    function onKeyDownHandler( e: KeyboardEvent ) {
        let newInd = ind;
        if( e.shiftKey != true && e.key == "ArrowLeft" ) {
            newInd++;
        }
        if( e.key == "ArrowRight" ) {
            newInd--;
        }
        newInd = Math.min( newInd, items.length - 1 );
        newInd = Math.max( newInd, 0 );
        setInd( newInd );
    }

    async function processImage() {
        let bgImage = new Image();
        if( items.length == 0 ) {
            return;
        }
        if( !items[ind] ) {
            return;
        }
        bgImage.src = items[ind].original;
        await new Promise<void>( ( resolve ) => {
            bgImage.onload  = ( _e ) => { return resolve(); };
            bgImage.onerror = ( _e ) => { return resolve(); };
        } );
        setBackground( bgImage );
    }

    const drawImage = ( background: HTMLImageElement | undefined ) => {
        if ( !background ) {
            return;
        }
        const canvas = canvasRef.current;
        if ( !canvas ) {
            return;
        }

        canvas.height = background.naturalHeight;
        canvas.width  = background.naturalWidth;
        const ctx = canvas.getContext("2d", { alpha: false })
        if ( !ctx ) {
            return <></>;
        }
        ctx.drawImage( background, 0, 0 );
        drawTimecode( items[ind], ctx, canvas );
        return <></>; //this func doesn't emit markup
        //it just calls effectful functions on canvas
    };
    return <>
        <canvas ref={canvasRef} style={props.style}></canvas>
        {drawImage( background ) }
    </>;

    function drawTimecode( item: any, ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement ) {
        let desc        = item.description;
        ctx.lineWidth   = 1;
        ctx.fillStyle   = "white";
        ctx.strokeStyle = "yellow";
        ctx.font        = '20pt Monaco';
        ctx.fillText  ( desc, 10, canvas.height - 10 );
        ctx.strokeText( desc, 10, canvas.height - 10 );
    }
}
