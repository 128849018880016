
//react
import { useEffect, useState} from "react";
import {Badge, Button, Card, Table} from 'react-bootstrap';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ILane from "../Api/ILane";
import {ICam} from "../Api/ICam";
import ApiUrl from "../Api/ApiUrl";
import {Cam} from "../Api/Cam";
import {isSameDay} from "./CVControllerHelpers";

const animatedComponents = makeAnimated()

export interface StayTimeFrameVal{
    domain: string
    stayCount: number
}

export interface TimeFrame {
    day: Date
    tracesOnDay: number | null | undefined
    visOnDay: number | null | undefined
    staysOnDay: StayTimeFrameVal[] | null | undefined
    tasksOnDay: number | null | undefined
    completedTasksOnDay: number | null | undefined
    parkingVisionResp: string | undefined
    stayGenResp: string | undefined
    taskGenResp: string | undefined
    matchmakerResp: string | undefined
    currTask: string | undefined
    selected: boolean
}

export interface CameraJob {
    camera: ICam
    timeFrames: TimeFrame[]
    lanesToInclude: ILane[]
    lanesToExclude: ILane[]
    currJobProgress: number | undefined
    currJobTask: string | undefined
}

export interface MainTableProps{
    cameraRowJobs: CameraJob[]
    updateJobFunc: any
}

export function CVMainTable(props: MainTableProps) {

    function handleDateClick(job: CameraJob, timeFrame: TimeFrame, e: any){
        let jobsCopy = [...props.cameraRowJobs]
        const ogTfSelected = timeFrame.selected
        jobsCopy.forEach((otherJob) => {
            otherJob.timeFrames.forEach((tf) => {
                let sameDay = isSameDay(timeFrame.day, tf.day)
                let sameCamera = job.camera.uuid === otherJob.camera.uuid
                if (sameDay){
                    if (e.shiftKey){
                        tf.selected = !ogTfSelected
                    }else{
                        if (sameCamera && sameDay){
                            tf.selected = !ogTfSelected
                        }
                    }
                }
            })
        })
        props.updateJobFunc(jobsCopy)
    }

    function openMultipleLinks(links: string[]) {
        // console.log(`WOULD OPEN ${links.length} LINKs`)
        for (var i = 0; i < links.length; i ++) {
            window.open(links[i]);
        }
    }

    function TaskButton(props: {tasksOnDay: number | null | undefined, completedTasksOnDay: number | null | undefined, timelineCardKey: string, cameraUuid: string, dateStrForUrl: string}){
        const tasksOnDay = props.tasksOnDay
        const completedTasksOnDay = props.completedTasksOnDay ?? 0
        let taskTheme = "secondary"
        let taskText = "loading..."
        if (tasksOnDay === null){
            taskText = "none"
            // taskTheme = "warning"
        }
        if (tasksOnDay){
            taskTheme = tasksOnDay! > 0 ? "success" : "danger"
            taskText = `${completedTasksOnDay} / ${tasksOnDay!.toString()}`
        }
        const taskBaseUrl = 'https://helios-e9947.web.app/'
        const taskLink =`${taskBaseUrl}/task/starts/${props.cameraUuid}?day=${props.dateStrForUrl}`
        return <Button
            key={`${props.timelineCardKey}_task`}
            size={"sm"}
            variant={taskTheme}
            style={{textAlign: "left"}}
            type="submit"
            onClick={ (e: any) => openMultipleLinks([taskLink])}>
            tasks: {taskText}
        </Button>
    }

    function StayButton(props: {staysOnDay: StayTimeFrameVal | undefined | null, timelineCardKey:string, lanesToInclude: ILane[], dateStr: string}){
        const baseUrl = window.location.host
        let stayTheme = "secondary"
        let stayText = "loading..."
        let stayDomain = "stays"
        if (props.staysOnDay === null){
            stayDomain = "stays"
            stayTheme = "warning"
            stayText = "none"
        }
        if (props.staysOnDay){
            stayTheme = props.staysOnDay.stayCount > 0 ? "success" : "danger"
            stayText = props.staysOnDay.stayCount.toString()
            stayDomain = props.staysOnDay.domain
        }
        const stayLinksForDomain = props.lanesToInclude.map((lane) => `http://${baseUrl}/cam/${lane.camera_uuid}/accuracy/${lane.uuid}/${stayDomain}?date=${props.dateStr}`)
        return <Button
            key={`${props.timelineCardKey}_stay_${stayDomain}`}
            size={"sm"}
            variant={stayTheme}
            style={{textAlign: "left"}}
            type="submit"
            onClick={ (e: any) => openMultipleLinks(stayLinksForDomain)} >
            {stayDomain}: {stayText}
        </Button>
    }

    function VisButton(props: {visOnDay: number | null | undefined, timelineCardKey:string, lanesToInclude: ILane[], dateStr: string, tracesOnDay: number}){
        let visTheme = "secondary"
        let visText = ""
        if (props.visOnDay === undefined){
            visText = "loading..."
        }else{
            let tempVisOnDay: number = props.visOnDay ?? 0
            if (tempVisOnDay < props.tracesOnDay - 10){
                visTheme = "danger"
                visText = `${tempVisOnDay.toString()} / ${props.tracesOnDay} traces`
            }
            else{
                visTheme = "success"
                visText = `${tempVisOnDay.toString()} / ${props.tracesOnDay} traces`
            }
        }
        const baseUrl = window.location.host
        const accLinks = props.lanesToInclude.map((lane) => `http://${baseUrl}/cam/${lane.camera_uuid}/acc/${lane.uuid}?date=${props.dateStr}`)
        return <Button
            key={`${props.timelineCardKey}_vis`}
            size={"sm"}
            variant={visTheme}
            style={{textAlign: "left"}}
            type="submit"
            onClick={ (e: any) => openMultipleLinks(accLinks)}>
            vis: {visText}
        </Button>
    }



    function JobRowTimeline(props: {job: CameraJob, updateJobFunc: any}){
        const job = props.job
        const timeFrames: TimeFrame[] = job.timeFrames
        const timelineCards = timeFrames.map((timeFrame, index) => {
            const cardKey = `${index}_${props.job.camera.uuid}`
            const cleanDate = `${timeFrame.day.getMonth() + 1}/${timeFrame.day.getDate()}/${timeFrame.day.getFullYear()}`
            const cameraUuid = job.camera.uuid

            // NEEDS REFACTORING TO BE CLEAN, THIS IS UNCESSARY
            const m = timeFrame.day.getMonth() + 1
            let strForMonth = m.toString()
            if (m < 10){
                strForMonth = `0${m}`
            }
            const d = timeFrame.day.getDate()
            let strForDay = d.toString()
            if (d < 10){
                strForDay = `0${d}`
            }
            const dateStrForUrl = `${timeFrame.day.getFullYear()}-${strForMonth}-${strForDay}`
            ////////////////////////////////

            let stayButtons = timeFrame.staysOnDay?.map((domainVal) =>
                <StayButton
                    key={`${cardKey}_stay_btn_${domainVal.domain}`}
                    staysOnDay={domainVal}
                    timelineCardKey={cardKey}
                    lanesToInclude={job.lanesToInclude}
                    dateStr={dateStrForUrl}
                />
            )
            if (timeFrame.staysOnDay === undefined){
                stayButtons = [<StayButton key={`${cardKey}_stay_btn_undef`} staysOnDay={undefined} timelineCardKey={cardKey} lanesToInclude={job.lanesToInclude} dateStr={dateStrForUrl} />]
            }
            if (timeFrame.staysOnDay === null){
                stayButtons = [<StayButton key={`${cardKey}_stay_btn_null`} staysOnDay={null} timelineCardKey={cardKey} lanesToInclude={job.lanesToInclude} dateStr={dateStrForUrl} />]
            }
            // let traceText = "loading..."
            // if (timeFrame.tracesOnDay === null){
            //     traceText = "None"
            // }
            // if (timeFrame.tracesOnDay){
            //     traceText = timeFrame.tracesOnDay!.toString()
            // }

            return <div
                key={cardKey}
                style={{minWidth: "160px", padding: "5px"}}
                className="card card-body">
                <Button variant={timeFrame.selected ? "primary" : "secondary"}
                    onClick={(e: any) => handleDateClick(job, timeFrame, e)}>
                    {cleanDate}
                </Button>
                {timeFrame.currTask? `${timeFrame.currTask}...` : ""}
                <hr className="dashed" style={{margin: "5px"}}/>
                {/*<small>traces: {traceText}</small>*/}
                <VisButton visOnDay={timeFrame.visOnDay}
                           timelineCardKey={cardKey}
                           lanesToInclude={job.lanesToInclude}
                           dateStr={dateStrForUrl}
                           tracesOnDay={timeFrame.tracesOnDay ?? 0} />
                {stayButtons}
                <TaskButton tasksOnDay={timeFrame.tasksOnDay}
                            completedTasksOnDay={timeFrame.completedTasksOnDay}
                            timelineCardKey={cardKey}
                            cameraUuid={cameraUuid}
                            dateStrForUrl={dateStrForUrl} />
            </div>
        })
        return (
            // <div className="container-fluid py-2" style={{maxWidth: "4000px", overflowX: "scroll"}}>
            <div className="container-fluid py-2">
            <div className="d-flex flex-row flex-nowrap" >
                    {timelineCards}
                </div>
            </div>
        )
    }

    function LaneBadgeCell(props: {lanes: ILane[]}){
        let lanes = props.lanes
        let lanesBadges = lanes.map((lane: ILane) => {
            let key = `${lane.uuid}_job_badge`
            const badgeText = `${lane.uuid.substring(0, 4)} | ${lane.lane_type}`
            let badgeColor = 'success'
            if (lane.lane_type != 'curb_parking'){
                badgeColor = 'danger'
            }
            const baseUrl = window.location.host
            const laneUrl = `http://${baseUrl}/cam/${lane.camera_uuid}/viscfg/${lane.uuid}`
            return <Button type={"submit"}
                           key={key}
                           variant={badgeColor}
                            onClick={(e: any) => openMultipleLinks([laneUrl])}
            >
                {badgeText}
            </Button>
        })
        return (
            <div className="container-fluid py-2" style={{maxWidth: "200px", overflowX: "scroll"}}>
                {lanesBadges}
            </div>
        )
    }

    function JobRow(props: {job: CameraJob, updateJobFunc: any}){
        let job = props.job
        let camera = props.job.camera
        let latestTraceId = camera.latest_information?.latest_trace_id
        let latestUrl = ""
        if (latestTraceId){
            latestUrl = ApiUrl.traceImgOriginal(latestTraceId)
        }
        return <tr key={`${camera.uuid}_job_row`} >
            <td><img src={latestUrl} alt="" width="200px" height="auto"  /></td>
            <td>{camera.imei}</td>
            <td> <LaneBadgeCell lanes={job.lanesToInclude} /> </td>
            <td> <LaneBadgeCell lanes={job.lanesToExclude} /> </td>
            <td> <JobRowTimeline job={job} updateJobFunc={props.updateJobFunc} /> </td>
        </tr>
    }

    if (props.cameraRowJobs.length === 0){
        return <Card className="text-center">
            <Card.Title>
                Please Select some cameras on the left
            </Card.Title>
            <Card.Img
                style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "250px"
                }}
                src="https://www.freeiconspng.com/thumbs/camera-icon/camera-icon-21.png" />
            <Card.Footer>
                `shift + click` a deployment name to select all cameras
            </Card.Footer>
        </Card>
    }

    return (
        <Table striped bordered hover size="sm" style={{maxHeight: "500px", overflowY: "scroll"}}>
            <thead>
            <tr>
                <th>Image</th>
                <th>imei</th>
                <th>Lanes To Include</th>
                <th>Lanes To Exclude</th>
                <th>Timeline</th>
            </tr>
            </thead>
            <tbody>
            {props.cameraRowJobs.map((job) => <JobRow key={job.camera.uuid} job={job} updateJobFunc={props.updateJobFunc}/>)}
            </tbody>
        </Table>
    );

}
