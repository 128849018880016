import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import {useEffect, useMemo, useState} from "react";
import { Badge, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ILane from "./Api/ILane";
import { ITraceWFrameNum, trunc } from "./CamLaneSessionsPageUtil";
import {
    compareDecisions,
    fmtPercent,
    getDebug,
    getDecisionStats, getEventComparison,
    getObjStats,
    getSessionComparison,
    SessionRec,
    statsAvgStayDur,
    statsVehicleCount,
    sum
} from "./CvDebuggerUtil";
import { IObj } from "./IVisionOutput";
import TraceGalleryAccuracy from "./TraceGalleryAccuracy";

export interface CvDebuggerPageDebuggerProps {
    ground:    Map<string, IObj[]>;
    vision:    Map<string, IObj[]>;
    grndRecs:  SessionRec[];
    visRecs:   SessionRec[];
    lane:      ILane;
    traces:    ITraceWFrameNum[];
    threshold: number;
    ind:       number;
    tz:        string;
    setInd:    React.Dispatch<React.SetStateAction<number>>;
    removeGrnVehicle: ( vehicleId: string ) => void;
    removeVisVehicle: ( vehicleId: string ) => void;
    rawGrnd: any[] | undefined;
    rawVis: any[] | undefined;
    editGrndUrl: string;
}

export function CvDebuggerPageDebugger( props: CvDebuggerPageDebuggerProps ) {
    let ground    = props.ground;
    let vision    = props.vision;
    let grndRecs  = props.grndRecs;
    let visRecs   = props.visRecs;
    let lane      = props.lane;
    let traces    = props.traces;
    let ind       = props.ind;
    let threshold = props.threshold;
    let tz        = props.tz;
    let setInd    = props.setInd;
    type IMyOutput = Map<string, Array<IObj>>;
    let rawGrnd    = props.rawGrnd;
    let rawVis     = props.rawVis;

    const [gtToVisLinks, setGtToVisLinks] = useState<any[]>([])
    const [gtToVisEventLinks, setGtToVisEventLinks] = useState<any | null>(null)

    const traceRows = useMemo( () => {
        return traces.map( (tr, i) => {
            const dbgOut = getDebug( vision, ground, traces, i  );
            const g = ground.get( tr.traceId );
            const v = vision.get( tr.traceId );
            const gCount = g?.length;
            const vCount = v?.length;

            let matched = [];
            if( v && g ) {
                matched = compareDecisions( v, g, "Exist" );
            }

            const errSum = sum( dbgOut.map(
                el => ( el.groundId !== "" && el.visionId !== "" ) ? 0 : 1 ) );
            const maxDist = dbgOut.map( el => el.dist ).reduce( (a, b) => Math.max( a, b ), 0 );
            const icon    = errSum === 0 ? <CheckCircleIcon color="green" width={15} />
                                         : <XCircleIcon     color="red"   width={15} />;

            //decisions made incorrectly
            let errAdds  = getDebug( vision, ground, traces, i ).filter( el => el.action === "Add" )
                                                                .filter( el => (el.groundId === "" && el.visionId !== "") ).length;
            let errKeeps = getDebug( vision, ground, traces, i ).filter( el => el.action === "Keep" )
                                                                .filter( el => (el.groundId === "" && el.visionId !== "") ).length;
            let errDels  = getDebug( vision, ground, traces, i ).filter( el => el.action === "Delete" )
                                                                .filter( el => (el.groundId === "" && el.visionId !== "") ).length;

            return { traceId:           trunc( tr.traceId ), 
                    time:               tr.timeCapturedLocal.toFormat( "h:mma" ).toLowerCase(),
                    maxDist:            maxDist.toFixed(0),
                    icon:               icon,
                    errSum:             errSum,
                    visionVehicleCount: vCount,
                    groundVehicleCount: gCount,
                    matched:            matched.length,
                    errAdds:            errAdds,
                    errKeeps:           errKeeps,
                    errDels:            errDels
            };
        } );
    }, [threshold, traces, lane, vision, ground] );

    function getNextErrTraceId( ind: number, step: number ) {
        for( let i = ind; i < traceRows.length; i += step ) {
            if( traceRows[i].errSum !== 0  ) {
                return i;
            }
        }
        return ind;
    }

    function onKeyDownHandler( e: KeyboardEvent ) {
        let newInd = ind;

        //step by 5 shortcuts
        if( e.shiftKey  && e.key === "ArrowLeft"  ) { newInd -= 5; }
        if( e.shiftKey  && e.key === "ArrowRight" ) { newInd += 5; }
        if( e.shiftKey  && e.key === "-"          ) { newInd -= 5; }
        if( e.shiftKey  && e.key === "="          ) { newInd += 5; }

        //single step shortcuts
        if( !e.shiftKey && e.key === "ArrowLeft"  ) { newInd--; }
        if( !e.shiftKey && e.key === "ArrowRight" ) { newInd++; }
        if( !e.shiftKey && e.key === "-"          ) { newInd--; }
        if( !e.shiftKey && e.key === "="          ) { newInd++; }

        if( e.key === "," ) { newInd = getNextErrTraceId( ind - 1, -1 ); }
        if( e.key === "." ) { newInd = getNextErrTraceId( ind + 1, +1 ); }

        //safety checks
        newInd = Math.min( newInd, traces.length - 1 );
        newInd = Math.max( newInd, 0 );
        setInd( newInd );
    }

    useEffect( () => {
        window.addEventListener("keydown", onKeyDownHandler );
        return () => { 
            window.removeEventListener("keydown", onKeyDownHandler );
        }
    }, [traces, ind] );   

    const debugOut = getDebug( vision, ground, traces, ind );
    const statsDiv = useMemo( () => {
        console.log( "statsDiv" );
        //stats computation
        const visAvgDur = statsAvgStayDur  ( visRecs  );
        const grnAvgDur = statsAvgStayDur  ( grndRecs );
        const visCnt    = statsVehicleCount( visRecs  );
        const grnCnt    = statsVehicleCount( grndRecs );
        const decs      = getDecisionStats ( vision, ground, traces );
        const visObjs   = getObjStats( visRecs  );
        const grnObjs   = getObjStats( grndRecs );
        let gt_matching_2_frame = getSessionComparison(grndRecs, rawVis, 2, traces, undefined)
        let gt_matching_1_frame = getSessionComparison(grndRecs, rawVis, 1, traces, setGtToVisLinks)
        let gt_matching_0_frame = getSessionComparison(grndRecs, rawVis, 0, traces, undefined)
        let events_matching_2_frame = getEventComparison(grndRecs, rawVis, 2, traces, undefined)
        let events_matching_1_frame = getEventComparison(grndRecs, rawVis, 1, traces, setGtToVisEventLinks)
        let events_matching_0_frame = getEventComparison(grndRecs, rawVis, 0, traces, undefined)


        function errRow( label:  string,
                      act:    number,
                      exp:    number,
                      digits: number = 0 ) {
            return <tr>
                <td className="text-start">{label}</td>
                <td>{act.toFixed( digits )}</td>
                <td>{exp.toFixed( digits )}</td>
                <td>{Math.abs( act - exp ).toFixed(digits)}</td>
                <td>{fmtPercent( Math.abs( act - exp ) / exp )}</td>
            </tr>;
        }

        function sessionComparisonRow(  session_comparison_type: string,
                                        start_end_correct: number,
                                        gt_not_linked: number,
                                        vis_not_linked: number,
                                        gt_match_percent: number,
                                        rel_error_percent: number,
                                        miss_percent: number
        ){
           return <tr>
                <td className="text-start">{session_comparison_type}  </td>
                <td>{start_end_correct}         </td>
                <td>{gt_not_linked}             </td>
                <td>{vis_not_linked}            </td>
                <td>{fmtPercent(gt_match_percent)}  </td>
                <td>{fmtPercent(rel_error_percent)}  </td>
               <td>{fmtPercent(miss_percent)}  </td>
           </tr>;
        }

        function eventComparisonRow( session_comparison_type: string,
                                     vis_starts_total: number,
                                     vis_starts_false_pos: number,
                                     vis_starts_false_neg: number,
                                     vis_ends_total: number,
                                     vis_ends_false_pos: number,
                                     vis_ends_false_neg: number,
        ){
            return <tr>
                <td className="text-start">{session_comparison_type}  </td>
                <td>{vis_starts_total}         </td>
                <td>{vis_starts_false_pos}             </td>
                <td>{vis_starts_false_neg}            </td>
                <td>{vis_ends_total}  </td>
                <td>{vis_ends_false_pos}  </td>
                <td>{vis_ends_false_neg}  </td>
            </tr>;
        }

        //decision row
        function decRow( label:     string,
                         total:     number,
                         matched:   number,
                         unmatched: number,
                         ground:    number ) {
            return <tr>
                <td className="text-start">{label}  </td>
                <td>{total}                         </td>
                <td>{matched}                       </td>
                <td>{unmatched}                     </td>
                <td>{fmtPercent( matched / total  )}</td>
                <td>{ground}                        </td>
                <td>{fmtPercent( matched / ground )}</td>
            </tr>;
        }

        return <Tabs style={{ fontSize: "9pt" }}>
            <Tab eventKey="first" title="Stats">
                <div style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}>
                    {/* <div>Totals Viewer (Threshold: {threshold})</div> */}
                </div>
                <table className="table table-striped font-monospace text-end" style={{ fontSize: "9pt" }}>
                    <thead>
                        <tr>
                            <td className="text-start">Metric</td>
                            <td>Vision </td>
                            <td>Ground </td>
                            <td>Abs Err</td>
                            <td>Rel Err</td>
                        </tr>
                    </thead>
                    <tbody>
                        {errRow( "Total Session Count", visCnt, grnCnt, 0 )}
                        {errRow( "Avg Session Duration", visAvgDur, grnAvgDur, 1 )}
                        {errRow( "Total Objects Detected", visObjs, grnObjs, 0 )}
                    </tbody>
                </table>
                <table className="table table-striped font-monospace text-end" style={{ fontSize: "9pt" }}>
                    <thead>
                        <tr>
                            <td className="text-start">Decision</td>
                            <td>Total       </td>
                            <td>Correct     </td>
                            <td>Errors      </td>
                            <td>Correct %   </td>
                            <td>Ground      </td>
                            <td>Grnd Match %</td>
                        </tr>
                    </thead>
                    <tbody>
                        {decRow( "Total", decs.visDecs.t, decs.visDecsCorrect.t, decs.visDecs.t - decs.visDecsCorrect.t, decs.grnDecs.t )}
                        {decRow( "Adds", decs.visDecs.a, decs.visDecsCorrect.a, decs.visDecs.a - decs.visDecsCorrect.a, decs.grnDecs.a )}
                        {decRow( "Keeps", decs.visDecs.k, decs.visDecsCorrect.k, decs.visDecs.k - decs.visDecsCorrect.k, decs.grnDecs.k )}
                        {decRow( "Deletes", decs.visDecs.d, decs.visDecsCorrect.d, decs.visDecs.d - decs.visDecsCorrect.d, decs.grnDecs.d )}
                    </tbody>
                </table>
            </Tab>
            <Tab eventKey="second" title="Event Stats">
                <table className="table table-striped font-monospace text-end" style={{ fontSize: "9pt" }}>
                    <thead>
                        <tr>
                            <td className="text-start">Sessions</td>
                            <td>Correct </td>
                            <td>GT Unl.</td>
                            <td>Vis Unl.</td>
                            <td>GT Mat%</td>
                            <td>Err%</td>
                            <td>Miss%</td>
                        </tr>
                    </thead>
                    <tbody>
                        {sessionComparisonRow( "GT Mat ~2",
                            gt_matching_2_frame.start_end_correct,
                            gt_matching_2_frame.gt_not_linked,
                            gt_matching_2_frame.vis_not_linked,
                            gt_matching_2_frame.gt_match_percent,
                            gt_matching_2_frame.rel_err_percent,
                            gt_matching_2_frame.miss_percent
                        )}
                        {sessionComparisonRow( "GT Mat ~1",
                            gt_matching_1_frame.start_end_correct,
                            gt_matching_1_frame.gt_not_linked,
                            gt_matching_1_frame.vis_not_linked,
                            gt_matching_1_frame.gt_match_percent,
                            gt_matching_1_frame.rel_err_percent,
                            gt_matching_1_frame.miss_percent
                        )}
                        {sessionComparisonRow( "GT Mat ~0",
                            gt_matching_0_frame.start_end_correct,
                            gt_matching_0_frame.gt_not_linked,
                            gt_matching_0_frame.vis_not_linked,
                            gt_matching_0_frame.gt_match_percent,
                            gt_matching_0_frame.rel_err_percent,
                            gt_matching_0_frame.miss_percent
                        )}
                    </tbody>
                </table>
                <table className="table table-striped font-monospace text-end" style={{ fontSize: "9pt" }}>
                    <thead>
                        <tr>
                            <td className="text-start">Events</td>
                            <td>CV Starts           </td>
                            <td>F+ Starts    </td>
                            <td>F- Starts    </td>
                            <td>CV Ends             </td>
                            <td>F+ Ends      </td>
                            <td>F- Ends      </td>
                        </tr>
                    </thead>
                    <tbody>
                        {eventComparisonRow( "Evt Mat. ~2",
                            events_matching_2_frame.vis_starts_total,
                            events_matching_2_frame.vis_starts_false_pos,
                            events_matching_2_frame.vis_starts_false_neg,
                            events_matching_2_frame.vis_ends_total,
                            events_matching_2_frame.vis_ends_false_pos,
                            events_matching_2_frame.vis_ends_false_neg,
                        )}
                        {eventComparisonRow( "Evt Mat. ~1",
                            events_matching_1_frame.vis_starts_total,
                            events_matching_1_frame.vis_starts_false_pos,
                            events_matching_1_frame.vis_starts_false_neg,
                            events_matching_1_frame.vis_ends_total,
                            events_matching_1_frame.vis_ends_false_pos,
                            events_matching_1_frame.vis_ends_false_neg,
                        )}
                        {eventComparisonRow( "Evt Mat. ~0",
                            events_matching_0_frame.vis_starts_total,
                            events_matching_0_frame.vis_starts_false_pos,
                            events_matching_0_frame.vis_starts_false_neg,
                            events_matching_0_frame.vis_ends_total,
                            events_matching_0_frame.vis_ends_false_pos,
                            events_matching_0_frame.vis_ends_false_neg,
                        )}
                    </tbody>
                </table>
            </Tab>
        </Tabs>;
    }, [grndRecs, visRecs, threshold, traces, lane] );

    const imgs = useMemo( () => traces.map( trace => {
        const date = trace.timeCapturedLocal;
        const fst  = date.toFormat( "yyyy LLL dd h:mm" );
        const snd  = date.toFormat( "a" ).toLowerCase();
        const dateLabel = fst + snd + " EST " + trunc( trace.traceId );
        return { traceId:     trace.traceId, 
                 original:    trace.imgUrl, 
                 description: dateLabel };
    } ), [traces] );

    function findColorsForSessions(){
        if (gtToVisLinks && visRecs && gtToVisEventLinks){
            // Make Vis objects green or red based on if they could be linked
            gtToVisLinks.forEach((link) => {
                let visVehicleId: string = link.vis.vehicle_id.toString()
                let gtVehicleId: string = link.gt.vehicleId
                let visStay = visRecs.find(visStay => visStay.vehicleId === visVehicleId)
                if (visStay){
                    visStay.frames.forEach((frame) => {
                        let frameTrace = frame.traceId
                        let traceObjs = vision.get(frameTrace)
                        if (traceObjs){
                            let visToDraw = traceObjs.find((obj) => obj.vehicleId === visVehicleId)
                            if(visToDraw){
                                visToDraw.strokeColor = 'green'
                            }
                        }
                    })
                }
                let gtStay = grndRecs.find(grndStay => grndStay.vehicleId === gtVehicleId)
                if (gtStay){
                    gtStay.frames.forEach((frame) => {
                        let frameTrace = frame.traceId
                        let traceObjs = ground.get(frameTrace)
                        if (traceObjs){
                            let gtToDraw = traceObjs.find((obj) => obj.vehicleId === gtVehicleId)
                            if(gtToDraw){
                                gtToDraw.strokeColor = 'green'
                            }
                        }
                    })
                }
            })

            // Draw gold on matched starts
            gtToVisEventLinks.matchedStartsVis.forEach((link: any) => {
                let visVehicleId: string = link.vehicleId;
                let gtLinkedVehicleId: string = link.matched_gt_id;
                let gtLinkedFrameId: string = link.matched_gt_frame_id;
                let visStartFrame: string = link.start_trace;
                let visStay = visRecs.find(visStay => visStay.vehicleId === visVehicleId)
                if (visStay) {
                    visStay.frames.forEach((frame) => {
                        if (frame.traceId === visStartFrame){
                            let frameTrace = frame.traceId
                            let traceObjs = vision.get(frameTrace)
                            if (traceObjs){
                                let visToDraw = traceObjs.find((obj) => obj.vehicleId === visVehicleId)
                                if(visToDraw){
                                    visToDraw.strokeColor = 'gold'
                                }
                            }
                        }
                    })
                }
                let groundTraceObjs = ground.get(gtLinkedFrameId)
                if (groundTraceObjs){
                    let groundToDraw = groundTraceObjs.find((obj) => obj.vehicleId === gtLinkedVehicleId)
                    if(groundToDraw){
                        groundToDraw.strokeColor = 'gold'
                    }
                }
            })

            // Draw gold on matched ends
            gtToVisEventLinks.matchedEndsVis.forEach((link: any) => {
                let visVehicleId: string = link.vehicleId;
                let gtLinkedVehicleId: string = link.matched_gt_id;
                let gtLinkedFrameId: string = link.matched_gt_frame_id;
                let visEndFrame: string = link.end_trace;
                let visStay = visRecs.find(visStay => visStay.vehicleId === visVehicleId)
                if (visStay) {
                    visStay.frames.forEach((frame) => {
                        if (frame.traceId === visEndFrame){
                            let frameTrace = frame.traceId
                            let traceObjs = vision.get(frameTrace)
                            if (traceObjs){
                                let visToDraw = traceObjs.find((obj) => obj.vehicleId === visVehicleId)
                                if(visToDraw){
                                    visToDraw.strokeColor = 'gold'
                                }
                            }
                        }
                    })
                }
                let traceObjs = ground.get(gtLinkedFrameId)
                if (traceObjs){
                    let groundToDraw = traceObjs.find((obj) => obj.vehicleId === gtLinkedVehicleId)
                    if(groundToDraw){
                        groundToDraw.strokeColor = 'gold'
                    }
                }
            })
        }
    }

    findColorsForSessions()

    return <div className="m-1" style={{ display: "grid", gridTemplateColumns: "auto auto 1fr", height: "100%" }}>
        <div className="m-1">
            <div>Computer Vision Output</div>
            <TraceGalleryAccuracy
                ind={ind}
                items={imgs}
                lane={lane} 
                sessions={vision}
                style={{ maxWidth: "32vw" }} />
             <div>
                <span>Decision Difference Viewer</span>
                <table className="table table-striped font-monospace" style={{ fontSize: "9pt" }}>
                    <thead>
                        <tr>
                            <td>Matched </td>
                            <td>Decision</td>
                            <td>Vision  </td>
                            <td>Ground  </td>
                            <td>Dist    </td>
                        </tr>
                    </thead>
                    <tbody>
                        {debugOut.map( b => {
                            const dist = b.dist === -1 ? "" : Math.round( b.dist );
                            const matched = b.visionId !== "" && b.groundId !== "";
                            const icon = matched ?
                                <CheckCircleIcon color="green" width={15} /> :
                                <XCircleIcon     color="red"   width={15} />
                            return <tr key={b.visionId + b.groundId}>
                                <td style={{ width: "20%" }}>{icon}</td>
                                <td style={{ width: "20%" }}>{b.action}</td>
                                <td style={{ width: "25%" }}>
                                    <Badge onClick={ () => props.removeVisVehicle( b.visionId ) }>
                                        {trunc( b.visionId )}
                                    </Badge>
                                </td>
                                <td style={{ width: "25%" }}>
                                    <Badge onClick={ () => props.removeGrnVehicle( b.groundId ) }>
                                        {trunc( b.groundId )}
                                    </Badge>
                                </td>
                                <td style={{ width: "10%" }}>{dist}</td>
                            </tr>;
                        } )}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="m-1">
            <div>
                <Link to={props.editGrndUrl}>
                    Ground Truth
                </Link>
            </div>
            <TraceGalleryAccuracy
                ind={ind}
                items={imgs}
                lane={lane}
                sessions={ground} 
                style={{ maxWidth: "32vw" }} />
            {statsDiv}
        </div>
        <div style={{ overflow: "auto", height: "92vh" }}>
            <table className="table table-compact" style={{ fontSize: "9pt" }}>
                <thead>
                    <tr>
                        <td>Status  </td>
                        <td>#</td>
                        <td>Time    </td>
                        <td>Trace   </td>
                        <td>Errs    </td>
                        <td>V#      </td>
                        <td>G#      </td>
                        <td>EA      </td>
                        <td>EK      </td>
                        <td>ED      </td>
                        <td>Mat     </td>
                        <td>Max Dist</td>
                    </tr>
                </thead>
                <tbody>
                    { traceRows.map( (tr, i) => {
                        // if( tr.errSum === 0 ) {
                        //     return <></>;
                        // }
                        const highlight = ind === i ? "text-white bg-primary" : "";
                        return <tr key={tr.traceId}
                                   id={tr.traceId}
                                   className={highlight}
                                   style={{ cursor: "pointer" }}
                                   onClick={ () => setInd( i ) }>
                            <td>{tr.icon}</td>
                            <td>{i}</td>
                            <td>{tr.time}</td>
                            <td>{tr.traceId}</td>
                            <td>{tr.errSum}</td>
                            <td>{tr.visionVehicleCount}</td>
                            <td>{tr.groundVehicleCount}</td>
                            <td>{tr.errAdds}</td>
                            <td>{tr.errKeeps}</td>
                            <td>{tr.errDels}</td>
                            <td>{tr.matched}</td>
                            <td>{tr.maxDist}</td>
                        </tr>;
                    } ) }
                </tbody>
            </table>
        </div>
    </div>;
}